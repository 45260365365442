//@Flow
import { BehaviorSubject, PartialObserver, Subject, Subscription } from "rxjs";
import Project, { CreateProjectRequest,  ProjectStates as  ProjectStatesString } from "../models/Project/Project";
import ProjectService from "../service/ProjectService/ProjectService";
import { AuthBloc, AuthContext, AuthStates, AuthStatesTypes } from "./authBloc";
import type { UpdateProjectRequest } from "../models/Project/Project";
import { TaskEvent, TaskEventTypesEnum } from "./taskBloc";
import ProjectGroup, {
  CreateProjectGroupRequest, UpdateProjectGroupRequest
} from "../models/ProjectGroup/ProjectGroup";
import ProjectGroupService from "../service/ProjectGroupService/ProjectGroupService";
import history from "../../src/history";

export const ProjectStates = Object.freeze({
  UNINITIALIZED: Symbol("UNINITIALIZED"),
  INITIALIZING: Symbol("INITIALIZING"),
  INITIALIZED: Symbol("INITIALIZED"),
  REFRESHED: Symbol("REFRESHED"),
});

export type ProjectStatesTypes = $Keys<typeof ProjectStates>;

export const ProjectEventTypesEnum = Object.freeze({
  CREATE: Symbol("CREATE"),
  CREATE_PROJECT_GROUP: Symbol("CREATE_PROJECT_GROUP"),
  UPDATE_PROJECT_GROUP: Symbol("UPDATE_PROJECT_GROUP"),
  REMOVE_PROJECT_GROUP: Symbol("REMOVE_PROJECT_GROUP"),
  EXPORT_PROJECT_GROUP: Symbol("EXPORT_PROJECT_GROUP"),
  EDIT_PROJECT_GROUP: Symbol("EDIT_PROJECT_GROUP"),
  ADD_VENDOR_TO_PROJECT: Symbol("ADD_VENDOR_TO_PROJECT"),
  REMOVE_VENDOR_FROM_PROJECT: Symbol("REMOVE_VENDOR_FROM_PROJECT"),
  REFRESH: Symbol("REFRESH"),
  UPDATE: Symbol("UPDATE"),
  REMOVE: Symbol("REMOVE"),
  ADD_CUSTOM_MILESTONE: Symbol("ADD_CUSTOM_MILESTONE"),
  ADDED_CUSTOM_MILESTONE: Symbol("ADDED_CUSTOM_MILESTONE"),
  UPDATE_CUSTOM_MILESTONE_DATE: Symbol("UPDATE_CUSTOM_MILESTONE_DATE"),
  CLONE_PROJECT: Symbol("CLONE_PROJECT"),
  CLONE_PROJECT_EFFECT: Symbol("CLONE_PROJECT_EFFECT"),
});

export type ProjectEventTypes = $Keys<typeof ProjectEventTypesEnum>;

export class ProjectEvent {
  type: ProjectEventTypes;

  constructor(type: ProjectEventTypes) {
    this.type = type;
  }
}

export class CreateProjectEvent extends ProjectEvent {
  request: CreateProjectRequest;

  constructor(request: CreateProjectRequest) {
    super(ProjectEventTypesEnum.CREATE);
    this.request = request;
  }
}

export class RefreshProjectsEvent extends ProjectEvent {
  projectStates: ?boolean;
  isProjectGroup: ?boolean;
  constructor( projectStates: ?boolean, isProjectGroup: ?boolean) {
    super(ProjectEventTypesEnum.REFRESH);
    this.projectStates = projectStates
    this.isProjectGroup = isProjectGroup
  }
}

export class CloneProjectEvent extends ProjectEvent {
  projectId: string;
  constructor(projectId: string) {
    super(ProjectEventTypesEnum.CLONE_PROJECT);
    this.projectId = projectId;
  }
}


export class RefreshProjectsGroupEvent extends ProjectEvent {
  projectGroupStates: ?boolean;
  constructor( projectGroupStates: ?boolean) {
    super(ProjectEventTypesEnum.REFRESH);
    this.projectGroupStates = projectGroupStates
  }
}

export class CreateProjectGroupEvent extends ProjectEvent {
  request: CreateProjectGroupRequest;

  constructor(request: CreateProjectGroupEvent) {
    super(ProjectEventTypesEnum.CREATE_PROJECT_GROUP);
    this.request = request;
  }
}

export class UpdateProjectGroupEvent extends ProjectEvent {
  request: UpdateProjectGroupRequest;

  constructor(request: UpdateProjectGroupRequest) {
    super(ProjectEventTypesEnum.UPDATE_PROJECT_GROUP);
    this.request = request;
  }
}

export class RemoveProjectGroupEvent extends ProjectEvent {
  projectGroupId: string;
  forceRefresh: ?boolean;
  constructor(projectGroupId: string, forceRefresh: ?boolean) {
    super(ProjectEventTypesEnum.REMOVE_PROJECT_GROUP);
    this.projectGroupId = projectGroupId;
    this.forceRefresh = forceRefresh;
  }
}

export class ExportProjectGroupEvent extends ProjectEvent {
  projectGroupId: string;
  // forceRefresh: ?boolean;
  constructor(projectGroupId: string, forceRefresh: ?boolean) {
    super(ProjectEventTypesEnum.EXPORT_PROJECT_GROUP);
    this.projectGroupId = projectGroupId;
    // this.forceRefresh = forceRefresh;
  }
}

export class EditProjectGroupEvent extends ProjectEvent {
  projectGroupId: string;
  constructor(projectGroupId: string) {
    super(ProjectEventTypesEnum.EDIT_PROJECT_GROUP);
    this.projectGroupId = projectGroupId;
  }
}

export class UpdateProjectEvent extends ProjectEvent {
  request: UpdateProjectRequest;
  project: Project;
  forceRefresh: ?boolean;

  constructor(
    project: Project,
    request: UpdateProjectRequest,
    forceRefresh: ?boolean
  ) {
    super(ProjectEventTypesEnum.UPDATE);
    this.request = request;
    this.project = project;
    this.forceRefresh = forceRefresh;
  }
}

export class UpdateCustomMilestoneEvent extends ProjectEvent {
  request: any;
  projectId: any;
  constructor(
    request: any,
    projectId: any,
  ) {
    super(ProjectEventTypesEnum.ADD_CUSTOM_MILESTONE);
    this.request = request;
    this.projectId = projectId;
  }
}

export class CustomMilestoneUpdateDateEvent extends ProjectEvent {
  request: any;
  projectId: any;
  constructor(
    request: any,
    projectId: any,
  ) {
    super(ProjectEventTypesEnum.UPDATE_CUSTOM_MILESTONE_DATE);
    this.request = request;
    this.projectId = projectId;
  }
}

export class updatedCustomMilestoneOutEvent extends ProjectEvent {

  milestones: Array<any>;

  constructor(milestones: Array<any>) {
    super(ProjectEventTypesEnum.ADDED_CUSTOM_MILESTONE);
    this.milestones = milestones;
  }
}

export class RemoveProjectEvent extends ProjectEvent {
  projectId: string;
  forceRefresh: ?boolean;
  constructor(projectId: string, forceRefresh: ?boolean) {
    super(ProjectEventTypesEnum.REMOVE);
    this.projectId = projectId;
    this.forceRefresh = forceRefresh;
  }
}

export class AddVendorEvent extends ProjectEvent {
  projectId: string;
  vendorToAddId: string;

  constructor(projectId: string, vendorToAddId: string) {
    super(ProjectEventTypesEnum.ADD_VENDOR_TO_PROJECT);
    this.vendorToAddId = vendorToAddId;
    this.projectId = projectId;
  }
}

export class RemoveVendorEvent extends ProjectEvent {
  projectId: string;
  vendorToRemoveId: string;

  constructor(projectId: string, vendorToRemoveId: string) {
    super(ProjectEventTypesEnum.REMOVE_VENDOR_FROM_PROJECT);
    this.vendorToRemoveId = vendorToRemoveId;
    this.projectId = projectId;
  }
}

export class ProjectBloc {
  _outProjectContext: BehaviorSubject = new BehaviorSubject();

  subscribeToProjectContext(observer?: PartialObserver<ProjectContext>) {
    return this._outProjectContext.asObservable().subscribe(observer);
  }

  _eventController: Subject = new Subject();

  sendEvent(event: ProjectEvent) {
    return this._eventController.next(event);
  }

  _projectService: ProjectService;
  _projectGroupService: ProjectGroupService;

  _authBloc: AuthBloc;
  _authBlocSubscription: ?Subscription;

  constructor(
    projectService: ProjectService,
    projectGroupService: ProjectGroupService,
    authBloc: AuthBloc
  ) {
    this._outProjectContext.next(
      new ProjectContext(null, ProjectStates.UNINITIALIZED)
    );
    this._projectService = projectService;
    this._projectGroupService = projectGroupService;
    this._authBloc = authBloc;
    this._authBlocSubscription = this._authBloc.subscribeToAuthContext(
      this.buildAuthContextChangeHandler()
    );
  }

  buildAuthContextChangeHandler = () => {
    return {
      next: async (authContext: AuthContext) => {
        switch (authContext.State) {
          case AuthStates.AUTHENTICATED: {
            this.initialize();
            break;
          }
          default: {
            break;
          }
        }
      },
      error(err) {
        throw err;
      }
    };
  };

  async initialize() {
    this._outProjectContext.next(
      new ProjectContext(null, null, ProjectStates.INITIALIZING)
    );
    let state = JSON.stringify([ProjectStatesString.OPEN]);
    if(window.location.pathname == "/app/manage-projects"){
      var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + '?state=' + `${ProjectStatesString.OPEN}`;    
      window.history.pushState({ path: refresh }, "", refresh);
    }
    const projects = await this._projectService.getAllProjects(state);
    let stateGroup = JSON.stringify([]);
    const projectGroups = await this._projectGroupService.getAllProjectGroups(stateGroup);
    this._outProjectContext.next(
      new ProjectContext(projects, projectGroups, ProjectStates.INITIALIZED)
    );
    this._eventController.subscribe(this.buildEventHandler());
  }

  buildEventHandler = () => {
    return {
      next: async (event: ProjectEvent) => {
        switch (event.type) {
          case ProjectEventTypesEnum.REFRESH: {
            let projectGroups;
            let projects;
            if(event?.isProjectGroup) {
              projectGroups = await this._projectGroupService.getAllProjectGroups((event: RefreshProjectsEvent).projectStates);
              let state = JSON.stringify([ProjectStatesString.OPEN]);
              projects = await this._projectService.getAllProjects(state);

            } else {
              projects = await this._projectService.getAllProjects((event: RefreshProjectsEvent).projectStates);
              let stateGroup = JSON.stringify([]);
              projectGroups = await this._projectGroupService.getAllProjectGroups(stateGroup);
            }
            this._outProjectContext.next(
              new ProjectContext(
                projects,
                projectGroups,
                ProjectStates.REFRESHED
              )
            );
            break;
          }
          case ProjectEventTypesEnum.CREATE: {
            const newProject = await this._projectService.createProject(
              (event: CreateProjectEvent).request
            );
            if (newProject) {
              const currentProjectContext = this._outProjectContext.getValue();
              currentProjectContext.projects.push(newProject);
              this._outProjectContext.next(currentProjectContext);
              this.sendEvent(new RefreshProjectsEvent(JSON.stringify(["OPEN"])));
            }
            break;
          }
          case ProjectEventTypesEnum.CREATE_PROJECT_GROUP: {
            const newProjectGroup = await this._projectGroupService.createProjectGroup(
              (event: CreateProjectGroupEvent).request
            );

            this.sendEvent(new RefreshProjectsEvent());
            // const currentProjectContext = this._outProjectContext.getValue();
            // currentProjectContext.projectGroups.push(newProjectGroup);
            // this._outProjectContext.next(currentProjectContext);
            break;
          }
          case ProjectEventTypesEnum.CLONE_PROJECT: {
            const clonedProj = await this._projectGroupService.cloneProject(
              (event: UpdateProjectGroupEvent).projectId);
              if(clonedProj && clonedProj.id) {
                let state = JSON.stringify([ProjectStatesString.OPEN]);
                let stateGroup = JSON.stringify([]);
                const projectGroups = await this._projectGroupService.getAllProjectGroups(stateGroup);
                const projects = await this._projectService.getAllProjects(state);
                this._outProjectContext.next(
                  new ProjectContext(projects, projectGroups, ProjectStates.INITIALIZED)
                );

                history.push(`/app/update-project/${clonedProj?.id}`);

                this._outProjectContext.next(
                  new ProjectContext(null, null, ProjectEventTypesEnum.CLONE_PROJECT_EFFECT, null, null, true)
                );
              } else {
                this._outProjectContext.next(
                  new ProjectContext(null, null, ProjectEventTypesEnum.CLONE_PROJECT_EFFECT, null, null, false)
                );
              }
            break;
          }
          case ProjectEventTypesEnum.UPDATE_PROJECT_GROUP: {
            const newProjectGroup = await this._projectGroupService.updateProjectGroup(
              (event: UpdateProjectGroupEvent).request);
            this.sendEvent(new RefreshProjectsEvent());

            // const currentProjectContext = this._outProjectContext.getValue();
            // currentProjectContext.projectGroups.push(newProjectGroup);
            // this._outProjectContext.next(currentProjectContext);
            break;
          }

          case ProjectEventTypesEnum.REMOVE_PROJECT_GROUP: {
            const removePorjectGroupResponse = await this._projectGroupService.removeProjectGroup(
              (event: RemoveProjectGroupEvent).projectGroupId
            );
            
            this.sendEvent(new RefreshProjectsEvent());
            return removePorjectGroupResponse;
            break;
          }
          case ProjectEventTypesEnum.EXPORT_PROJECT_GROUP: {
            const exportPorjectGroupResponse = await this._projectGroupService.exportProjectGroup(
              (event: ExportProjectGroupEvent).projectGroupId
            );
            const currentProjectContext = this._outProjectContext.getValue();
            currentProjectContext['selectedProjctGroup']= exportPorjectGroupResponse;
            this._outProjectContext.next(currentProjectContext);
            return exportPorjectGroupResponse;
            break;
          }

          case ProjectEventTypesEnum.EDIT_PROJECT_GROUP: {
            const editPorjectGroupResponse = await this._projectGroupService.editProjectGroup(
              (event: EditProjectGroupEvent).projectGroupId
            );
            const currentProjectContext = this._outProjectContext.getValue();
            currentProjectContext['editedProjectGroup']= editPorjectGroupResponse;
            this._outProjectContext.next(currentProjectContext);
            return editPorjectGroupResponse;
            break;
          }

          case ProjectEventTypesEnum.ADD_CUSTOM_MILESTONE: {
            const milestones = await this._projectService.updateCustomMilestone(
              (event: UpdateProjectGroupEvent).request, (event: UpdateProjectGroupEvent).projectId);
      this.sendEvent(new RefreshProjectsEvent());
      break;
    }
          case ProjectEventTypesEnum.UPDATE_CUSTOM_MILESTONE_DATE: {
      const milestones = await this._projectService.updateCustomMilestoneDate(
        (event: UpdateProjectGroupEvent).request, (event: UpdateProjectGroupEvent).projectId);
      this.sendEvent(new RefreshProjectsEvent());
      break;
    }
  
          case ProjectEventTypesEnum.ADD_VENDOR_TO_PROJECT: {
            await this._projectService.addVendorToProject(
              (event: AddVendorEvent).projectId,
              (event: AddVendorEvent).vendorToAddId
            );
            this.sendEvent(new RefreshProjectsEvent());
            break;
          }
          case ProjectEventTypesEnum.REMOVE_VENDOR_FROM_PROJECT: {
            await this._projectService.removeVendorFromProject(
              (event: RemoveVendorEvent).projectId,
              (event: RemoveVendorEvent).vendorToRemoveId
            );
            this.sendEvent(new RefreshProjectsEvent());
            break;
          }
          case ProjectEventTypesEnum.UPDATE: {
            const updatedProject = await this._projectService.updateProject(
              (event: UpdateProjectEvent).project,
              (event: UpdateProjectEvent).request
            );

            if (event.forceRefresh && updatedProject) {
              this.sendEvent(new RefreshProjectsEvent());
              break;
            }
            if (updatedProject) {
              const currentProjectContext = this._outProjectContext.getValue();
              const projectIndex = currentProjectContext.projects.findIndex(
                project => project.id === updatedProject.id
              );
              currentProjectContext.projects[projectIndex] = updatedProject;
              currentProjectContext.projects = Array.from(
                currentProjectContext.projects
              );
              this._outProjectContext.next(currentProjectContext);
            }

            break;
          }
          case ProjectEventTypesEnum.REMOVE: {
            const removePorjectResponse = await this._projectService.removeProject(
              (event: RemoveProjectEvent).projectId
            );

            this.sendEvent(new RefreshProjectsEvent());
            return removePorjectResponse;
            break;
          }
          default: {
            throw new Error("Unknown project event: " + event.constructor.name);
          }
        }
      },
      error(err) {
        throw err;
      }
    };
  };

  dispose() {
    this._outProjectContext.complete();
    this._eventController.complete();
    this._authBlocSubscription.unsubscribe();
  }
}

export class ProjectContext {
  projects: Array<Project>;
  projectGroups: Array<ProjectGroup>;
  state: ProjectStatesTypes;
  selectedProjctGroup:any;
  editedProjectGroup:any;
  isCloneSuccess:any;
  constructor(
    projects: Array<Project>,
    projectGroups: Array<ProjectGroup>,
    state: ProjectStatesTypes,
    selectedProjctGroup:any,
    editedProjectGroup:any,
    isCloneSuccess?:any,
  ) {
    this.projects = projects;
    this.projectGroups = projectGroups;
    this.state = state;
    this.selectedProjctGroup = selectedProjctGroup;
    this.editedProjectGroup = editedProjectGroup;
    this.isCloneSuccess = isCloneSuccess;
  }
}
