//@flow

import React, {useContext, useEffect, useState} from "react";
import type {Subscription} from "rxjs";
import BlocRegistry from "../../blocs/registry";
import type {HomeownerApplicationOutEventTypes} from "../../blocs/projectAttachmentBloc";
import ProjectFilesGrid from "../ProjectFilesGrid/ProjectFilesGrid";
import {
    AttachmentOutEventTypesEnum as AttachmentOutEvent, CreateAttachmentForProjectEvent,
    FetchAttachmentsForProjectEvent
} from "../../blocs/projectAttachmentBloc";
import {AttachmentTypeEnum, CreateAttachmentRequest} from "../../models/Attachment/Attachment";
import Project from "../../models/Project/Project";

type Props = {
    project: Project,
    currentUserRole: any
}

export default function ProjectFilesManager(props: Props) {

    const {attachmentBloc} = useContext(BlocRegistry);

    const {project, currentUserRole} = props;

    const [lastBlocEvent, setLastBlocEvent] = useState(null);
    const [projectFiles, setPojectFiles] = useState(null);

    useEffect(() => {
        const subscription: Subscription = attachmentBloc.subscribeToAttachmentContext({
            next(event: HomeownerApplicationOutEventTypes) {
                setLastBlocEvent(event);
            },
            error(err: Error) {
                throw err;
            }
        });
        return () => {
            subscription.unsubscribe();
        }
    }, [attachmentBloc]);

    useEffect(() => {
        if (!lastBlocEvent) return;
        switch (lastBlocEvent.type) {
            case AttachmentOutEvent.FETCHED: {
                setPojectFiles(lastBlocEvent.attachments);
                break;
            }
            case AttachmentOutEvent.DELETED:
            case AttachmentOutEvent.CREATED: {
                attachmentBloc.sendEvent(new FetchAttachmentsForProjectEvent(project.id, AttachmentTypeEnum.file));
                break;
            }
            default: {
                console.error("Unknown homeowner application out event type: " + lastBlocEvent.type)
            }
        }
    }, [lastBlocEvent]);

    useEffect(() => {
        if (!project) return;
        attachmentBloc.sendEvent(new FetchAttachmentsForProjectEvent(project.id, AttachmentTypeEnum.file));
    }, [project]);


    const handleUploadAttachment = (requests)=>{
        for(const request of requests){
            attachmentBloc.sendEvent(new CreateAttachmentForProjectEvent(AttachmentTypeEnum.file, request));
        }
    };

    const handleUploadAttachmentForImageFiles = (request)=>{
        attachmentBloc.sendEvent(new CreateAttachmentForProjectEvent(AttachmentTypeEnum.file, request));
    };

    const handleDeleteAttachment = (request)=>{
            attachmentBloc.sendEvent(request);
    };

    const handleAllAttachmentDownload = (request)=>{
        attachmentBloc.sendEvent(request);
    };

    return (<ProjectFilesGrid
        currentUserRole={currentUserRole}
        onAttachmentDelete={handleDeleteAttachment}
        onAllAttachmentDownload={handleAllAttachmentDownload}
        project={project}
        onAddFiles={handleUploadAttachment}
        onAddPhoto={handleUploadAttachmentForImageFiles}
        onFileTileClick={()=>{}}
        files={projectFiles && projectFiles.length > 0 ?  projectFiles.reverse() : projectFiles}
        currentUserRole={props.currentUserRole}
    />)
}
