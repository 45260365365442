//@flow

import React, {useContext, useEffect, useState} from "react";
import {Button, Spinner, Intent,} from "@blueprintjs/core";
import Flexbox from 'flexbox-react'
import ExportErForm from "../ExportErForm/ExportErForm";
import type {Subscription} from "rxjs";
import BlocRegistry from "../../blocs/registry";
import { ExportErOutEventTypesEnum,ExportErOutEventTypes, FetchExportErForProjectEvent, UpdateExportErForProjectEvent } from "../../blocs/exportErBloc";
import ExportEr from "../../models/ExportEr/ExportEr";
import { AppToaster } from "../Toaster/Toaster";

type Props = {
    projectId: string,
    project?:any,
    navigateToFiles:(projectId:?string)=>any,
    onErSubmitted:()=>any,
    onErReleased:()=>any
}

export default function ExportErManager(props: Props) {

    const {exportErBloc} = useContext(BlocRegistry);



    const {projectId,project, navigateToFiles,onErSubmitted,onErReleased} = props;

    const [lastBlocEvent, setLastBlocEvent] = useState(null);
    const [erReport, setErReport] = useState(null);

    useEffect(() => {
        const subscription: Subscription = exportErBloc.subscribeToExportErContext({
            next(event: ExportErOutEventTypes) {
                setLastBlocEvent(event);
            },
            error(err: Error) {
                throw err;
            }
        });
        return () => {
            subscription.unsubscribe();
        }
    }, [exportErBloc]);

    useEffect(() => {
        if (!lastBlocEvent) return;
        switch (lastBlocEvent.type) {
            case ExportErOutEventTypesEnum.FETCHED: {
                setErReport(lastBlocEvent.homeownerApplication);
                break;
            }
            case ExportErOutEventTypesEnum.UPDATED: {
                if (!projectId) return;
                AppToaster.show({
                    message: "ER Updated",
                    intent: Intent.PRIMARY,
                  });
                exportErBloc.sendEvent(new FetchExportErForProjectEvent(props.projectId));
                setErReport(lastBlocEvent.homeownerApplication);

                break;
            }
            default: {
                console.error("Unknown homeowner application out event type: " + lastBlocEvent.type)
            }
        }
    }, [lastBlocEvent]);

    useEffect(() => {
        if (!projectId) return;
        exportErBloc.sendEvent(new FetchExportErForProjectEvent(props.projectId));
    }, [projectId]);


    const updateErForm = (er:ExportEr) => {
        if (!projectId) return;
        exportErBloc.sendEvent(new UpdateExportErForProjectEvent(props.projectId,er));
    }


    let form = <Spinner/>

    

    if (erReport) {
        if (lastBlocEvent && lastBlocEvent.type === ExportErOutEventTypesEnum.FETCHED) {
           form =  ( <ExportErForm
           project={project}
           onErUpdate={updateErForm}
           application={erReport}
           navigateToFiles={navigateToFiles}
           onErSubmitted={onErSubmitted}
           onErReleased={onErReleased}
           currentUserRole={props.currentUserRole}
           />
           )
        } 
    }

    return (
        <>
      {form}
      </>
    )
}
