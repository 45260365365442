// @flow

import React, {useEffect, useContext} from 'react';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {AuthContext, AuthEvents, AuthStates} from "../../blocs/authBloc";
import history from "../../history";
import type {Subscription} from "rxjs";
import Flexbox from 'flexbox-react';
import BlocRegistry from '../../blocs/registry'


export default function LoginView() {
    const {authBloc}= useContext(BlocRegistry);

    const authEventHandler = {
        next(authContext: AuthContext) {
            switch (authContext.State) {
                case AuthStates.UNINITIALIZED: {
                    break;
                }
                case AuthStates.UNAUTHENTICATED: {
                    authBloc.sendEvent(AuthEvents.LOGIN);
                    break;
                }
                case AuthStates.AUTHENTICATING: {
                    break;
                }
                case AuthStates.AUTHENTICATED: {
                    history.replace(`/app`);
                    break;
                }
                default: {
                    throw new Error("Unknown auth state: " + authContext.state);
                }
            }
        },
        error(err: Error) {
            throw err;
        }
    };

    useEffect(() => {
        const subscription: Subscription = authBloc.subscribeToAuthContext(authEventHandler);
        return () => {
            subscription.unsubscribe();
        }
    }, [authBloc]);

    return (
        <Flexbox justifyContent={'center'} alignContent={'center'} alignItems={'center'} width={'100vw'}
                 height={'100vh'}>
            <LoadingMessage/>
        </Flexbox>
    );
}
