//@flow

import React from "react";
import CustomSuggest from "../CustomSuggest/CustomSuggest";
import type Vendor from "../../models/Vendor/Vendor";

type Props = {
    vendors: ?Array<Vendor>,
    inputName?: string,
    selectedItem?:string
}

export default function VendorSuggest(props: Props) {
    const selectedItem = findVendorById(props.selectedItem, props.vendors);
    return <CustomSuggest inputName={props.inputName} items={props.vendors} filterItems={filterVendors} inputValueRenderer={inputValueRenderer} inputSelectValueMapper={inputSelectValueMapper} itemToKeyMapper={itemToKeyMapper}  defaultSelectedItem={selectedItem}/>
}

function itemToKeyMapper(vendor: Vendor){
    return vendor.id;
}

function inputSelectValueMapper(vendor: Vendor){
    return vendor.id;
}

function inputValueRenderer(vendor: Vendor){
    return `${vendor.name} (${vendor.contactType})`;
}

function filterVendors(query, vendor, _index, exactMatch) {
    const normalizedVendorGroup = vendor.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedVendorGroup === normalizedQuery;
    } else {
        return `${normalizedVendorGroup}`.indexOf(normalizedQuery) >= 0;
    }
};

const findVendorById = (id: ?string, vendors) => {
    if (!vendors || !id) return null;
    return vendors.find(unit => {
      return unit.id === id;
    });
  };