import React from "react";
import {
    Cell,
    Column,
    ColumnHeaderCell,
} from "@blueprintjs/table";

export type ICellLookup = (rowIndex: number, columnIndex: number) => any;
export type ISortCallback = (columnIndex: number, comparator: (a: any, b: any) => number) => void;

export class AbstractSortableColumn {
    name: string;
    index: number;
    modelField: string;

    constructor(name: string, index: number, modelField: string) {
        this.name = name;
        this.index = index;
        this.modelField = modelField;
    }

    getColumn(getCellData: ICellLookup, sortColumn: ISortCallback) {
        const cellRenderer = (rowIndex: number, columnIndex: number) => (
            <Cell>{getCellData(rowIndex, columnIndex)}</Cell>
        );
        const menuRenderer = this.getRenderMenu.bind(this, sortColumn);
        const columnHeaderCellRenderer = () => <ColumnHeaderCell name={this.name} menuRenderer={menuRenderer}/>;
        return (
            <Column
                cellRenderer={cellRenderer}
                columnHeaderCellRenderer={columnHeaderCellRenderer}
                key={this.index}
                name={this.name}
            />
        );
    }

    getRenderMenu(){
        return ()=>{}
    }
}
