// @flow

import Task, {
  CreateTaskRequest,
  UpdateTaskRequest
} from "../../models/Task/Task";
import TaskApi from "../../api/TaskApi/TaskApi";

class TaskService {
  taskApi: TaskApi;

  constructor(taskApi: TaskApi) {
    this.taskApi = taskApi;
  }

  async getAllTasks(taskListId): Promise<Array<Task>> {
    return await this.taskApi.getAll(taskListId);
  }

  async createTask(createTaskRequest: CreateTaskRequest): Promise<Task> {
    return await this.taskApi.createTask(createTaskRequest);
  }

  async updateTask(updateTaskRequest: UpdateTaskRequest): Promise<Task> {
    return await this.taskApi.updateTask(updateTaskRequest);
  }

  async removeTaskFromTaskList(taskId: string): Promise<Task> {
    return await this.taskApi.removeTaskFromList(taskId);
  }

}

export default TaskService;
