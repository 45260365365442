//@flow

import React, { useState, useEffect } from "react";
import { useField } from "formik";
import styles from "../CreateProjectRequestBuilder/CreateProjectRequestBuilder.module.css";
import { Spinner, Tag, Intent, Button } from "@blueprintjs/core";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";
import DateSelect from "../DateSelect/DateSelect";
import useWindowDimensions from "../UseWindowDimensions/UseWindowDimenions";
import Flexbox from "flexbox-react";

type Props = {
  inputName: string,
  dateInputName: string,
  disabled?: boolean
};

export default function SignatureSelect(props: Props) {
  const [ref, setRef] = useState(null);
  const { height, width } = useWindowDimensions();
  const [done, setDone] = useState(false);

  const [itemField, itemMeta] = useField(props.inputName);
  const valueChangeHandler = itemField.onChange;
  const error =
    itemMeta.error && itemMeta.touched ? (
      <div className={styles.errorText}>{itemMeta.error}</div>
    ) : (
      <div />
    );

  const m = moment(itemField.value);

  useEffect(() => {
    if (!ref) return;
    ref.getSignaturePad().clear();
    if (itemField.value) {
      ref.fromDataURL(itemField.value, "image/png");
      setDone(true);
    } else {
      setDone(false);
    }
  }, [ref, itemField.value]);

  useEffect(() => {
    if (!ref) return;

    if (done || props.disabled) {
      ref.getSignaturePad().off();
    } else {
      ref.getSignaturePad().on();
    }
  }, [done, props.disabled, ref]);

  return (
    <Flexbox flexDirection={"column"} justifyContent={"center"}>
      <Flexbox flex={0} justifyContent={"center"}>
        <SignatureCanvas
          ref={ref => {
            setRef(ref);
          }}
          penColor="black"
          canvasProps={{
            width: 500,
            height: 200,
            className: "sigCanvas",
            style: { border: "1px solid #000000" }
          }}
        />
      </Flexbox>
      <Flexbox justifyContent={"center"}>
        <Flexbox flex={1} justifyContent={"center"}>
          {!props.disabled && (
            <Button
              text={"Clear"}
              icon={"cross"}
              onClick={() => {
                if (!ref) return;
                ref.clear();
                const event = {
                  target: {
                    name: itemField.name,
                    value: null
                  }
                };
                valueChangeHandler(event);
                setDone(false);
              }}
            />
          )}
        </Flexbox>
        <Flexbox flex={1} justifyContent={"center"}>
          {!done && !props.disabled && (
            <Button
              intent={Intent.PRIMARY}
              text={"Save"}
              icon={"endorsed"}
              onClick={() => {
                const event = {
                  target: {
                    name: itemField.name,
                    value: ref.toDataURL("image/png")
                  }
                };
                valueChangeHandler(event);
                setDone(true);
              }}
            />
          )}
        </Flexbox>
      </Flexbox>
      <DateSelect
        inputName={props.dateInputName}
        disabled={props.disabled || done}
      />
    </Flexbox>
  );
}
