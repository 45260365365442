// @flow

import type { AuthBloc } from "../../blocs/authBloc";
import type ProjectGroup from "../../models/ProjectGroup/ProjectGroup";
import { AppToaster } from "../../components/Toaster/Toaster";
import { Intent } from "@blueprintjs/core";
import { apiAxios } from "../../config/AxiosConfig";
import {
  CreateProjectGroupRequest,
  UpdateProjectGroupRequest,
} from "../../models/ProjectGroup/ProjectGroup";
import Project from "../../models/Project/Project";
import history from "../../history";

class ProjectGroupApi {
  authBloc: AuthBloc;

  constructor(authBloc: AuthBloc) {
    this.authBloc = authBloc;
  }

  async getAll(projectStates): Promise<Array<ProjectGroup>> {
    const tenantId = this.authBloc.getCurrentTenant();
    const response = await apiAxios.get(
      `/tenants/${tenantId}/project-groups?status=${projectStates ? projectStates:[]}`,
      this.buildHeaders()
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async cloneProject(
    projectId: any
  ): Promise<ProjectGroup> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.post(
        `/tenants/${tenantId}/projects/clone`,
        { "parentProjectId": projectId},
        this.buildHeaders()
      );

      if (response.status === 201) {
        // AppToaster.show({
        //   message: "Project Cloned Successfully",
        //   intent: Intent.PRIMARY,
        // });
        localStorage.setItem("isCloneInprogress", true);
        return response.data;
      } else {
        localStorage.setItem("isCloneInprogress", false);
      }
    } catch (error) {
      localStorage.setItem("isCloneInprogress", false);
      if(error.response.status === 500) {
        // AppToaster.show({
        //   message: "Something Went Wrong",
        //   intent: Intent.DANGER,
        // });
        return;
      } else if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER,
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async createProjectGroup(
    createProjectGroupRequest: CreateProjectGroupRequest
  ): Promise<ProjectGroup> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.post(
        `/tenants/${tenantId}/project-groups`,
        createProjectGroupRequest,
        this.buildHeaders()
      );

      if (response.status === 201) {
        AppToaster.show({
          message: "Project Group Created",
          intent: Intent.PRIMARY,
        });

        history.goBack();

        return response.data;
      } else {
      }
    } catch (error) {

      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER,
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async addProjectToGroup(
    projectGroupToAddTo: ProjectGroup,
    projectToAdd: Project
  ): Promise<ProjectGroup> {
    const tenantId = this.authBloc.getCurrentTenant();

    const response = await apiAxios.post(
      `/tenants/${tenantId}/project-groups/${projectGroupToAddTo.id}/projects`,
      projectToAdd,
      this.buildHeaders()
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async updateProjectGroup(
    updateProjectGroupRequest: UpdateProjectGroupRequest
  ): Promise<ProjectGroup> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      let requestURL;

      if(updateProjectGroupRequest.closeProjects){
        requestURL = `/tenants/${tenantId}/project-groups/${updateProjectGroupRequest.id}?closeProjects=${updateProjectGroupRequest.closeProjects && updateProjectGroupRequest.closeProjects}`
      }else{
        requestURL = `/tenants/${tenantId}/project-groups/${updateProjectGroupRequest.id}`
      }

      const response = await apiAxios.patch(
        requestURL,
        updateProjectGroupRequest,
        this.buildHeaders()
      );

      if (response.status === 204) {
        AppToaster.show({
          message: "Project Group Updated",
          intent: Intent.PRIMARY,
        });

        if(updateProjectGroupRequest.closeProjects){
        }else{
          history.goBack();
        }
        

        return response.data;
      } else {
       
      }
    } catch (error) {
    
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER,
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async removeProjectGroup(projectGroupId: string): Promise<ProjectGroup> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.delete(
        `/tenants/${tenantId}/project-groups/${projectGroupId}`,
        this.buildHeaders()
      );

      if (response.status === 200) {
        AppToaster.show({
          message: "Project Group Deleted",
          intent: Intent.PRIMARY,
        });
        return response.data;
      } else {
       
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER,
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async exportProjectGroup(projectGroupId: string): Promise<ProjectGroup> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();
      const response = await apiAxios.get(
        `/tenants/${tenantId}/project-groups/${projectGroupId}/projects/export-data`,
        this.buildHeaders()
      );
      if (response.status === 200) {
        return response.data;
      } else {
       
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async editProjectGroup(projectGroupId: string): Promise<ProjectGroup> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();
      const response = await apiAxios.get(
        `/tenants/${tenantId}/project-groups/${projectGroupId}/default-tasks`,
        this.buildHeaders()
      );
      if (response.status === 200) {
        return response.data;
      } else {
      
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }


  buildHeaders = () => {
    const accessToken = this.authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };
}

export default ProjectGroupApi;
