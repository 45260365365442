// @flow

import React, { useEffect, useState } from "react";
import { Table, SelectionModes, Column } from "@blueprintjs/table";
import ProjectGroup, { UpdateProjectGroupRequest, ProjectGroupStatusTypeEnum } from "../../models/ProjectGroup/ProjectGroup";
import { TextSortableColumn } from "../../components/Table/TextSortableColumn/TextSortableColumn";
import { Utils } from "@blueprintjs/table/lib/esnext/common/utils";
import {
  Button,
  Menu,
  MenuItem,
  Intent,
  Classes,
  Popover,
  InputGroup,
  Position,
  Icon,
  Spinner,
} from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import history from "../../history";
import DeleteConfirmationPopup from "../ConfirmationAlert/ConfirmationAlert";
import { AppToaster } from "../Toaster/Toaster";
import { Fragment } from "react";
import { GetPaginatedData, handleGroupsSearch } from "../../config/Utils";
import { Responsive, WidthProvider } from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
  projectGroups: Array<ProjectGroup>,
  onProjectGroupDetailsButtonClick: (projectGroupId: string) => any,
  onEditProjectGroupButtonClick: () => any,
  onDeleteProjectGroupButtonClick: (projectGroupId: string) => any,
  onExportProjectGroupButtonClick: (projectGroupId: string) => any,
  onEditProjectGroupMenuClick: (projectGroupId: string) => any,
  onCloseProjectGroupMenuClick: (projectGroupId: string) => any,
  isComplainceAuditor: ?Boolean,
  isMarketingRole: ?Boolean
};

export default function ProjectGroupGroupsTable(props: Props) {
  const { projectGroups, onEditProjectGroupMenuClick, onCloseProjectGroupMenuClick, isComplainceAuditor, isMarketingRole } = props;
  const originalProjectGroups = props.projectGroups;

  const [searchedProjGroup, setSearchedProjGroup] = useState(projectGroups);
  const [columns, setColumns] = useState([
    new TextSortableColumn("Controls", 0, "controls", () => {
      return 0;
    }),
    new TextSortableColumn("Name", 1, "name"),
    new TextSortableColumn("In Process", 2, "inProcess"),
    new TextSortableColumn("Completed", 3, "completed"),
    new TextSortableColumn("Total", 4, "total"),
    new TextSortableColumn("Budget", 5, "budget"),
    new TextSortableColumn("Actual", 6, "actual"),
    new TextSortableColumn("Status", 7, "status")
  ]);

  const [sortedIndexMap, setSortedIndexMap] = useState([]);
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [searching, setSearching] = useState(false);

  const setCurrentProjectGroups = (initialPage = false) => {
    const filteredProjectGroups = searching ? searchedProjGroup : projectGroups
    setSearchedProjGroup(filteredProjectGroups);
  };

  useEffect(() => {
    setSearchedProjGroup(projectGroups);
  }, [projectGroups]);

  const onEditProjectGroup = async(projectGroupId:string) => {

    onEditProjectGroupMenuClick(projectGroupId);

    setTimeout(()=>{
      history.push(`/app/update-project-group/${projectGroupId}`);
    },300)
  }

  const onCloseProjectGroup = async(projectGroupData:any) => {

    if(projectGroupData){
      const editProjectObj = {
        id:projectGroupData.id,
        name:projectGroupData.name.trim(),
        status:ProjectGroupStatusTypeEnum.CLOSED,
        closeProjects:true
      }
     props.onEditProjectGroupButtonClick(editProjectObj);
    }
    else{
      AppToaster.show({
        message: "Try Again!",
        intent: Intent.DANGER
      });
    }
  
    
  }


  const nameRenderer = (rowIndex: number) => {
    return searchedProjGroup[rowIndex].name;
  };

  const inProcessRenderer = (rowIndex:number) => {
    return searchedProjGroup[rowIndex].inProcess ;
  }

  const completedRenderer = (rowIndex:number) => {
    return searchedProjGroup[rowIndex].completed;
  }

  const totalRenderer = (rowIndex:number) => {
    return searchedProjGroup[rowIndex].total;
  }

  const budgetRenderer = (rowIndex:number) => {
    return `$ ${searchedProjGroup[rowIndex].budget}`;
  }

  const actualRenderer = (rowIndex:number) => {
    return `$ ${searchedProjGroup[rowIndex].actual}`;
  }

  const statusRenderer = (rowIndex:number) => {
    return searchedProjGroup[rowIndex].status;
  }

  const generateLayout = (buttons: Array<any>) => {
    return {
      lg: buttons.map((button, index) => {
        return {
          x: index % 5,
          y: Math.floor(index / 5),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      md: buttons.map((button, index) => {
        return {
          x: index % 4,
          y: Math.floor(index / 4),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      sm: buttons.map((button, index) => {
        return {
          x: index % 2,
          y: Math.floor(index / 2),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      xs: buttons.map((button, index) => {
        return {
          x: index % 1,
          y: Math.floor(index / 1),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      xss: buttons.map((button, index) => {
        return {
          x: 0,
          y: index,
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
    };
  };

  const createButton = (
    index: number,
    icons: Array<FontAwesomeIcon>,
    label: string,
    onClick?: () => void,
    disabled?: boolean
  ) => {
    let isInteractive = true;
    if (onClick === undefined || disabled) isInteractive = false;

    return <div key={index}>{icons}</div>;
  };
  const searchFilterButtons = [
    createButton(
      0,
      [
        <Flexbox flex={1} flexDirection={"column"}>
          <Flexbox flex={1}>
            <b>
              <label style={{ fontSize: "14px" }} htmlFor={"search"}>
                {"Search"}
              </label>
            </b>
          </Flexbox>
          <Flexbox flex={1}>
            {" "}
            <InputGroup
              placeholder="Search..."
              leftIcon={<Icon icon="search" intent={Intent.PRIMARY} />}
              onChange={(e) => {
                setSearching(e.target.value === "" ? false : true);
                const serachedProj1 = handleGroupsSearch(
                  e,
                  // firstProjectsList,
                  originalProjectGroups
                );
                setSearchedProjGroup(serachedProj1);
                // setCurrentProjects(true);
              }}
            />
          </Flexbox>
        </Flexbox>,
      ],
      "",
      () => {}
    ),
  ];

  const controlsRenderer = (rowIndex: number) => {
    return (
      <>
        <Menu className={Classes.ELEVATION_2}>
          <MenuItem
            text="Export Report"
            icon={"import"}
            intent={Intent.SUCCESS}
            onClick = {() => searchedProjGroup[rowIndex]?.name && props.onExportProjectGroupButtonClick(searchedProjGroup[rowIndex].id, searchedProjGroup[rowIndex].name)}
          />
          {!isComplainceAuditor && !isMarketingRole &&
            <Fragment>
              <MenuItem
                text="Edit"
                icon={"edit"}
                intent={Intent.PRIMARY}
                onClick={() => {
                  // setCurrentGroup(projectGroups[rowIndex].id);
                  // setCreateGroupPopoverOpen(true);
                  // setNewGroupName(projectGroups[rowIndex].name)
                  onEditProjectGroup(searchedProjGroup[rowIndex].id);
                }}
              />

              <MenuItem
                text="Delete"
                icon={"trash"}
                intent={Intent.DANGER}
                onClick={() =>
                  props.onDeleteProjectGroupButtonClick(
                    searchedProjGroup[rowIndex].id
                  )
                }
              />

              <MenuItem
                text="Close Group"
                icon={"delete"}
                intent={Intent.WARNING}
                onClick={() => {
                  setCurrentGroup(searchedProjGroup[rowIndex]);
                  setConfirmationPopup(true);
                }}
              />
            </Fragment>
          }
        </Menu>
      </>
    );
  };

  const cellRenderers = {
    name: nameRenderer,
    controls: controlsRenderer,
    inProcess:inProcessRenderer,
    completed:completedRenderer,
    total:totalRenderer,
    budget:budgetRenderer,
    actual:actualRenderer,
    status:statusRenderer
  };

  const getCellData = (rowIndex: number, columnIndex: number) => {
    const sortedRowIndex = sortedIndexMap[rowIndex];
    if (sortedRowIndex != null) {
      rowIndex = sortedRowIndex;
    }
    return cellRenderers[columns[columnIndex].modelField](rowIndex);
  };

  const sortColumn = (
    columnIndex: number,
    comparator: (a: any, b: any) => number
  ) => {
    const sortedIndexMap = Utils.times(searchedProjGroup?.length, (i: number) => i);
    sortedIndexMap.sort((a: number, b: number) => {
      let fieldA;
      let fieldB;

      fieldA = searchedProjGroup[a][columns[columnIndex].modelField];
      fieldB = searchedProjGroup[b][columns[columnIndex].modelField];

      return comparator(fieldA, fieldB, a, b);
    });
    setSortedIndexMap(sortedIndexMap);
  };

  const renderedColumns = columns.map(column => {
    const field = column.modelField;
    return column.getColumn(getCellData, sortColumn);
  });

  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      cancelButtonText="No"
      confirmButtonText="Yes"
      icon="delete"
      type={Intent.WARNING}
      onCancel={() => setConfirmationPopup(false)}
      onConfirm={async () => {
        // await onDeleteProjectGroupButtonClick(projectGroupId);
        onCloseProjectGroup(currentGroup);
        setConfirmationPopup(false);
      }}
      message={
        <div>
          <h3>Total Projects: {currentGroup && currentGroup.total}</h3>
          <h5>Are you sure you want to Close this Project Group?</h5>
          </div>
      }
    />
  );

  let searchFilterList = <Spinner />;
  const layouts = generateLayout(searchFilterButtons);

  if (searchedProjGroup) {
    searchFilterList = (
      <ResponsiveGridLayout
        layouts={layouts}
        rowHeight={80}
        margin={[10, 10]}
        breakpoints={{ lg: 1500, md: 1280, sm: 738, xs: 480, xxs: 0 }}
        cols={{ lg: 5, md: 4, sm: 4, xs: 1, xxs: 1 }}
      >
        {searchFilterButtons}
      </ResponsiveGridLayout>
    );
  }

  return (
    <>
    {searchFilterList}
    <Table
      defaultRowHeight={134}
      selectionModes={SelectionModes.COLUMNS_AND_CELLS}
      numRows={searchedProjGroup?.length}
    >
      {renderedColumns}
    </Table>
    {confirmPopup}
    </>
  );
}
