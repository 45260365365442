//@flow

import styles from './CreateAssignedTaskRequestBuilder.module.css';
import React, {useState} from "react";
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import {Button, Spinner} from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import Task, {QuantityTypeEnum} from "../../models/Task/Task";
import TaskSuggest from "../TaskSuggest/TaskSuggest";
import {CreateAssignedTaskRequest} from "../../models/AssignedTask/AssignedTask";


type Props = {
    onNewAssignedTaskCreate: (assignedTaskToCreate: CreateAssignedTaskRequest) => Promise<any>,
    onNewAssignedTaskCreated: ()=>any,
    tasks: ?Array<Task>,
    projectId: string
}

const uuidV4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

const formStates = {
    INPUT: 'INPUT',
    SUBMITTING: 'SUBMITTING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
};

export default function CreateAssignedTaskRequestBuilder(props: Props) {

    const {tasks,projectId} = props;

    const [state, setState] = useState(formStates.INPUT);

    return (
        <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
            <Formik
                initialValues={{
                    taskId: '',
                }}
                validationSchema={Yup.object({
                    taskId: Yup.string()
                        .matches(uuidV4, {message: 'Must be a valid UUID V4'})
                        .required('Required')
                })}
                onSubmit={async (values, {resetForm}) => {
                    if (!tasks) return;
                    const referenceTask = tasks.find(task => task.id === values.taskId);
                    if (!referenceTask) return;

                    let quantity;

                    switch (referenceTask.quantityType) {
                        case QuantityTypeEnum.FLOAT: {
                            quantity = '0.0';
                            break;
                        }
                        default: {
                            console.error("Unknown quantity type: " + referenceTask.quantityType);
                            return;
                        }
                    }

                    const createTaskRequest = new CreateAssignedTaskRequest(
                        referenceTask.id,
                        referenceTask.taskGroupId,
                        referenceTask.title,
                        referenceTask.description,
                        referenceTask.unit,
                        quantity,
                        referenceTask.quantityType,
                        referenceTask.price,
                        referenceTask.priceType,
                        projectId
                    );

                    setState(formStates.SUBMITTING);
                    await props.onNewAssignedTaskCreate(createTaskRequest);
                    setState(formStates.SUCCESS);
                    props.onNewAssignedTaskCreated();
                    setState(formStates.INPUT);
                    resetForm({taskId: ''});
                }}>
                {formik => {
                    return <Form>
                        <Flexbox alignItems={'center'} flexDirection={'row'} className={styles.inputSection}>
                            <TaskSuggest tasks={tasks} inputName={'taskId'}/>
                                <Button type="submit" minimal
                                        disabled={state !== formStates.INPUT || !formik.values.taskId} loading={state === formStates.SUBMITTING}
                                        icon={state === formStates.INPUT ? 'plus' : null}>
                                    {state === formStates.SUBMITTING ? <Spinner size={1}/> : null}
                                </Button>
                        </Flexbox>
                    </Form>
                }}
            </Formik>
        </div>)
}
