import { Alert, Intent } from "@blueprintjs/core";
import React from "react";
type Props = {
  isOpen: boolean,
  confirmButtonText: string,
  icon: string,
  cancelButtonText: string,
  type: Intent,
  onConfirm: () => any,
  onCancel: () => any,
  message: string
};

export default function PageRedirectConfirmPopup(props: Props) {
  const {
    isOpen,
    confirmButtonText,
    icon,
    cancelButtonText,
    type,
    onConfirm,
    onCancel,
    message
  } = props;
  return (
    <Alert
      isOpen={isOpen}
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
      icon={icon}
      intent={type}
      onCancel={() => onCancel()}
      onConfirm={id => onConfirm(id)}
    >
      <p>{message}</p>
    </Alert>
  );
}
