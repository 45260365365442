// @flow


import ExportErApi from "../../api/ExportErApi/ExportErApi";
import ExportEr from "../../models/ExportEr/ExportEr";

class ExportErService {

    exportErApi: ExportErApi;

    constructor(exportErApi: ExportErApi) {
        this.exportErApi = exportErApi;
    }

    async getExportErForProject(projectId: string): Promise<?ExportEr> {
        return await this.exportErApi.getExportErForProject(projectId);
    }
   
    async updateExportErForProject(projectId: string, er: ExportEr): Promise<ExportEr> {
        await this.exportErApi.updateExportErProject(projectId, er);

        return er;
    }
}

export default ExportErService;
