//@flow

import axios, {AxiosInstance} from 'axios'

const apiVersion = 'v1';

const service_url: string = (() => {
    const service_id = process.env.REACT_APP_SERVICE_URL;
    if (!service_id) throw new Error("Missing service id");
    return service_id;
})();

const base_url: string = (() => {
    const baseUrl = process.env.REACT_APP_KEYCLOAK_SERVICE_URL;
    if (!baseUrl) throw new Error("Missing keycloak service url");
    return baseUrl;
})();

const realm: string = (() => {
    const realm = process.env.REACT_APP_KEYCLOAK_REALM;
    if (!realm) throw new Error("Missing keycloak realm");
    return realm;
})();

export const apiAxios: AxiosInstance = ((): AxiosInstance => {
    const axiosInstance = axios.create({baseURL: `${service_url}/${apiVersion}`});

    axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/json';
    axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
    axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

/*    axiosInstance.interceptors.request.use(async (config) => {
        let isAuthenticated = await AuthService.isAuthenticated();
        if(!isAuthenticated) throw new Error("Not yet authenticated.");
        let accessToken = AuthService.getAccessTokenWithPermissions();
        if (!accessToken) throw new Error("Not yet authenticated with permissions.");
        config.headers.Authorization = "Bearer " + accessToken;
        return config;
    });*/

    return axiosInstance;
})();

export const adminKeycloakAxios: AxiosInstance = ((): AxiosInstance => {
    const axiosInstance = axios.create({baseURL: `https://${base_url}/auth/admin/realms/${realm}`});

    axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/json';
    axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

/*
    axiosInstance.interceptors.request.use(async (config) => {
        let isAuthenticated = await AuthService.isAuthenticated();
        if(!isAuthenticated) throw new Error("Not yet authenticated.");
        let accessToken = AuthService.getAccessToken();
        if (!accessToken) throw new Error("Not yet authenticated with permissions.");
        config.headers.Authorization = "Bearer " + accessToken;
        return config;
    });
*/

    return axiosInstance;
})();

