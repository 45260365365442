// @flow

import type {AuthBloc} from "../../blocs/authBloc";
import {apiAxios} from '../../config/AxiosConfig'
import type Attachment, {AttachmentTypes} from "../../models/Attachment/Attachment";
import {GetInvoiceResponse, CreateInvoiceRequest, PostInvoiceResponse, UpdateInvoiceRequest} from "../../models/Invoice/Invoice";
import { AppToaster } from '../../components/Toaster/Toaster';
import { Intent } from '@blueprintjs/core';


class InvoiceApi {

    authBloc : AuthBloc;

    constructor(authBloc: AuthBloc) {
        this.authBloc = authBloc;
    }

    async getInvoicesForProject(projectId: string, attachmentType: AttachmentTypes,) : Promise<Array<GetInvoiceResponse>>{
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.get(`/tenants/${tenantId}/projects/${projectId}/${attachmentType}`, this.buildHeaders());

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    }

    async createInvoice (attachmentType: AttachmentTypes, createInvoiceRequest: CreateInvoiceRequest) : Promise<PostInvoiceResponse> {
        const tenantId = this.authBloc.getCurrentTenant();

        let payload;
        if(createInvoiceRequest.attachment){
            payload = createInvoiceRequest.attachment.payload;
            delete createInvoiceRequest.attachment.payload;
        }
      

        const response = await apiAxios.post(`/tenants/${tenantId}/projects/${createInvoiceRequest.projectId}/${attachmentType}`, createInvoiceRequest, this.buildHeaders());

        if(createInvoiceRequest.attachment)
        createInvoiceRequest.attachment.payload=payload;
        
        if (response.status === 201) {
            AppToaster.show({
                message: "Invoice Created",
                intent: Intent.PRIMARY,
              });
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    async deleteInvoice (projectId: string, attachmentType: AttachmentTypes, invoiceId: string) : Promise<void> {
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.delete(`/tenants/${tenantId}/projects/${projectId}/${attachmentType}/${invoiceId}`, this.buildHeaders());

        if (response.status === 200) {
            AppToaster.show({
                message: "Invoice Deleted",
                intent: Intent.PRIMARY,
              });
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    async updateInvoice (attachmentType: AttachmentTypes, updateInvoiceRequest: UpdateInvoiceRequest) : Promise<PostInvoiceResponse> {
        const tenantId = this.authBloc.getCurrentTenant();

        let payload;
        if(updateInvoiceRequest.attachment){
            payload = updateInvoiceRequest.attachment.payload;
            delete updateInvoiceRequest.attachment.payload;
        }

        const response = await apiAxios.patch(`/tenants/${tenantId}/projects/${updateInvoiceRequest.projectId}/${attachmentType}/${updateInvoiceRequest.invoiceId}`, updateInvoiceRequest, this.buildHeaders());

        if(updateInvoiceRequest.attachment)
        updateInvoiceRequest.attachment.payload=payload;
        
        if (response.status === 201) {
            AppToaster.show({
                message: "Invoice Updated",
                intent: Intent.PRIMARY,
              });
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };


  
    buildHeaders = ()=> {
        const accessToken = this.authBloc.getCurrentToken();
        const auth = "Bearer " + accessToken;
        return {headers:{Authorization:auth}};
    }
}

export default InvoiceApi;
