// @flow

import type { AuthBloc } from "../../blocs/authBloc";
import { apiAxios } from "../../config/AxiosConfig";
import { AppToaster } from "../../components/Toaster/Toaster";
import { Intent, Spinner } from "@blueprintjs/core";

class NotesApi {
  authBloc: AuthBloc;

  constructor(authBloc: AuthBloc) {
    this.authBloc = authBloc;
  }

  async getAll(metaData: any): Promise<Array> {
    const tenantId = this.authBloc.getCurrentTenant();

    const response = await apiAxios.get(
      `tenants/${tenantId}/projects/${metaData?.projectId}/notes?offset=${metaData?.offSet}&limit=${metaData?.rowsPerPage}&q=${metaData.q}`,
      this.buildHeaders()
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async createNote(createNoteRequest): Promise<any> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.post(`/tenants/${tenantId}/notes`, createNoteRequest, this.buildHeaders());

      if (response.status === 201) {
        AppToaster.show({
          message: "Note Created",
          intent: Intent.PRIMARY
        });
        return response.data;
      } else {
        throw new Error(response.status);
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  };

  async addAttachments(createNoteRequest): Promise<any> {
    const tenantId = this.authBloc.getCurrentTenant();

    const payload = createNoteRequest?.payload;
    delete createNoteRequest?.payload;

    const response = await apiAxios.post(`/tenants/${tenantId}/notes/attachments`, createNoteRequest, this.buildHeaders());
    createNoteRequest.payload = payload;
    if (response?.status === 201) {
      return response?.data;
    } else {
      throw new Error(response?.status);
    }
  };

  async updateNote(updateNoteRequest, nodeId): Promise<any> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.put(`/tenants/${tenantId}/notes/${nodeId}`, updateNoteRequest, this.buildHeaders());

      if (response.status === 201) {
        AppToaster.show({
          message: "Note Updated",
          intent: Intent.PRIMARY
        });
        return response.data;
      } else {
        throw new Error(response.status);
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  };

  async exportNotes(exportNoteRequest): Promise<any> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.post(`/tenants/${tenantId}/notes/download`, exportNoteRequest, this.buildHeadersCustom());

      if (response.status === 200) {
        const projectAddress = localStorage.getItem("projectAddress");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', projectAddress);
        document.body.appendChild(link);
        link.click();
        AppToaster.show({
          message: "Notes Exported Successfully",
          intent: Intent.PRIMARY
        });
        return;
      } else {
        throw new Error(response?.status);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        AppToaster.show({
          message: 'No notes found for the given date range',
          intent: Intent.DANGER
        });
      } else {
        AppToaster.show({
          message: error?.response?.statusText || 'Something went wrong',
          intent: Intent.PRIMARY
        });
        // new Error(error);
      }
    }
  };

  async deleteNote(nodeId): Promise<any> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.delete(`/tenants/${tenantId}/notes/${nodeId}`, this.buildHeaders());

      if (response.status === 201) {
        AppToaster.show({
          message: "Note Deleted",
          intent: Intent.PRIMARY
        });
        return response.data;
      } else {
        throw new Error(response.status);
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  };
  buildHeaders = () => {
    const accessToken = this.authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };

  buildHeadersCustom = () => {
    const accessToken = this.authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { responseType: 'arraybuffer', headers: {
      ContentType: 'application/json',
      Accept: 'application/pdf',
      Authorization: auth } };
  };
}

export default NotesApi;
