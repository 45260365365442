// @flow

// import {apiAxios} from "../../axiosConfig";

import type {AuthBloc} from "../../blocs/authBloc";
import type User from "../../models/User/User";
import {apiAxios} from '../../config/AxiosConfig'


class UserApi {

    authBloc : AuthBloc;

    constructor(authBloc: AuthBloc) {
        this.authBloc = authBloc;
    }

    async getProjectMangers() : Promise<Array<User>> {
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.get(`/tenants/${tenantId}/groups/project_managers`, this.buildHeaders());

        if (response.status !== 200) {
            throw new Error(response.status);
        }

        return response.data;
    }
    async getProjectAdmins() : Promise<Array<User>> {
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.get(`/tenants/${tenantId}/groups/project_admins`, this.buildHeaders());

        if (response.status !== 200) {
            throw new Error(response.status);
        }

        return response.data;
    }

    async getTenantAdmins() : Promise<Array<User>> {
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.get(`/tenants/${tenantId}/groups/tenant_admins`, this.buildHeaders());

        if (response.status !== 200) {
            throw new Error(response.status);
        }

        return response.data;
    }

    buildHeaders = ()=> {
        const accessToken = this.authBloc.getCurrentToken();
        const auth = "Bearer " + accessToken;
        return {headers:{Authorization:auth}};
    }
}

export default UserApi;
