import React, { Component } from 'react';
import Flexbox from "flexbox-react";

class UserProjectsListView extends Component {
    render() {
      const {userProjects} = this.props;
        return (
          <Flexbox justifyContent={"flex-start"} flexDirection={"column"} style={{ padding: 15 }}>
            <Flexbox alignItems={"flex-start"} flexDirection={"column"}>
            {userProjects.map(project=>{
              return(<p style={{ color: "black", fontSize: 16, }}>Project Name 1</p>)
            })}
            </Flexbox>
          </Flexbox>
        );
    }
}

export default UserProjectsListView;