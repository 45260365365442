//@flow

import React, {useContext, useEffect, useState} from "react";
import type {Subscription} from "rxjs";
import BlocRegistry from "../../blocs/registry";
import type {HomeownerApplicationOutEventTypes} from "../../blocs/projectAttachmentBloc";
import ProjectPhotosGrid from "../ProjectPhotosGrid/ProjectPhotosGrid";
import {
    AttachmentOutEventTypesEnum as AttachmentOutEvent, CreateAttachmentForProjectEvent, DeleteAttachmentForProjectEvent,
    FetchAttachmentsForProjectEvent
} from "../../blocs/projectAttachmentBloc";
import {AttachmentTypeEnum, CreateAttachmentRequest, GetAttachmentResponse} from "../../models/Attachment/Attachment";
import Project from "../../models/Project/Project";
import ProjectPhotosGridBoxes from "../ProjectPhotosGrid/ProjectPhotosGridBoxes";

type Props = {
    project: ?Project,
    projectId: string,
    onSetProjectCoverPhoto: (request: GetAttachmentResponse)=>any,
}

export default function ProjectPhotosManager(props: Props) {

    const {attachmentBloc} = useContext(BlocRegistry);

    const {projectId,onSetProjectCoverPhoto,project} = props;

    const [lastBlocEvent, setLastBlocEvent] = useState(null);
    const [projectPhotos, setPojectPhotos] = useState(null);
    const [gridView, setGridView] = useState(false);
    useEffect(() => {
        const subscription: Subscription = attachmentBloc.subscribeToAttachmentContext({
            next(event: HomeownerApplicationOutEventTypes) {
                setLastBlocEvent(event);
            },
            error(err: Error) {
                throw err;
            }
        });
        return () => {
            subscription.unsubscribe();
        }
    }, [attachmentBloc]);

    useEffect(() => {
        if (!lastBlocEvent) return;
        switch (lastBlocEvent.type) {
            case AttachmentOutEvent.FETCHED: {
                setPojectPhotos(lastBlocEvent.attachments);
                break;
            }
            case AttachmentOutEvent.DELETED:
            case AttachmentOutEvent.CREATED: {
                attachmentBloc.sendEvent(new FetchAttachmentsForProjectEvent(props.projectId, AttachmentTypeEnum.image));
                break;
            }
            default: {
                console.error("Unknown homeowner application out event type: " + lastBlocEvent.type)
            }
        }
    }, [lastBlocEvent]);

    useEffect(() => {
        if (!projectId) return;
        attachmentBloc.sendEvent(new FetchAttachmentsForProjectEvent(props.projectId, AttachmentTypeEnum.image));
    }, [projectId]);


    const handleUploadAttachment = (request)=>{
        attachmentBloc.sendEvent(new CreateAttachmentForProjectEvent(AttachmentTypeEnum.image, request));
    };

    const handleDeleteAttachment = (request)=>{
        attachmentBloc.sendEvent(request);
    };
    if(gridView) {
        
    return (
      <ProjectPhotosGridBoxes
        onSetProjectCoverPhoto={onSetProjectCoverPhoto}
        onDeletePhoto={handleDeleteAttachment}
        projectId={projectId}
        onAddPhoto={handleUploadAttachment}
        onPhotoTileClick={() => {}}
        photos={projectPhotos}
        setGridView={setGridView}
        project={project}
        currentUserRole={props.currentUserRole}
      />
    );
    }
    return (<ProjectPhotosGrid
        onSetProjectCoverPhoto={onSetProjectCoverPhoto}
onDeletePhoto={handleDeleteAttachment}
        projectId={projectId}
        onAddPhoto={handleUploadAttachment}
        onPhotoTileClick={()=>{}}
        setGridView={setGridView}
        photos={projectPhotos}
        project={project}
        currentUserRole={props.currentUserRole}
    />)
}
