//@flow

import React, {useState, useEffect, useContext} from "react";
import Flexbox from "flexbox-react";
import {Button, ButtonGroup, Card, Collapse, Divider, NumericInput, Pre, Spinner, Tag, Intent} from "@blueprintjs/core";
import TaskGroup from "../../models/TaskGroup/TaskGroup";
import Task from "../../models/Task/Task";
import type {Subscription} from "rxjs";
import BlocRegistry from "../../blocs/registry";
import {
    AssignedTaskOutEventTypesEnum,
    CreateAssignedTaskForProjectEvent, DeleteAssignedTaskForProjectEvent,
    FetchTasksForProjectEvent, UpdateAssignedTaskForProjectEvent
} from "../../blocs/assignedTaskBloc";
import AssignedTask, {
    CreateAssignedTaskRequest,
    UpdateAssignedTaskRequest
} from "../../models/AssignedTask/AssignedTask";
import styles from './ProjectTaskPicker.module.css'
import {Fab, Action} from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import type {HomeownerApplicationOutEventTypes} from "../../blocs/projectAttachmentBloc";

import {faPlus, faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons'
import CreateAssignedTaskRequestBuilder from "../CreateAssignedTaskRequestBuilder/CreateAssignedTaskRequestBuilder";
import type {AssignedTaskOutEventTypes} from "../../blocs/assignedTaskBloc";
import AssignedTaskQuantityEditor from "../AssignedTaskQuantityEditor/AssignedTaskQuantityEditor";
import { AuthContext, AuthRoles, AuthStates } from "../../blocs/authBloc";
import { AttachmentBloc, FetchAttachmentsForProjectEvent, AttachmentOutEvent } from "../../blocs/projectAttachmentBloc";
import { AttachmentTypeEnum } from "../../models/Attachment/Attachment";
import Project from "../../models/Project/Project";

type Props = {
    tasks: ?Array<Task>,
    taskGroups: ?Array<TaskGroup>,
    projectId: string,
    assignedTasks: ?Array<AssignedTask>,
    onNewAssignedTaskCreate: (assignedTaskToCreate: CreateAssignedTaskRequest) => Promise<any>,
    onAssignedTaskUpdate: (assignedTask: AssignedTask,
                           updateAssignedTaskRequest: UpdateAssignedTaskRequest) => Promise<any>,
    onNewAssignedTaskCreated: ()=>any,
    onAssignedTaskUpdated: ()=>any,
    onAssignedTaskRemove: (assignedTask: string) => Promise<any>,
    currentUserRole: string,
    project: ?Project,
}

export default function ProjectTaskPicker(props: Props) {
    const result = props.assignedTasks?.filter(obj => obj.hasOwnProperty('taskList'));

   
    let { assignedTasks } = props;
    const {taskGroups, tasks, projectId,
              onNewAssignedTaskCreate, onNewAssignedTaskCreated, onAssignedTaskUpdate,
              onAssignedTaskUpdated, onAssignedTaskRemove,currentUserRole} = props;
    const [openSections, setOpenSections] = useState(new Map());

    const setAllSections = (open: ?boolean = false) => {
        if (taskGroups && taskGroups?.length > 0) {
            const stateMap = taskGroups?.reduce((map, taskGroup) => {
                map.set(taskGroup.id, open);
                return map;
            }, new Map());
            setOpenSections(stateMap);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        }, []);

    if (!taskGroups || !tasks || !assignedTasks) return <Spinner/>;

    const taskGroupsIdsToTasksMap: Map<string, Array<Task>> = new Map();
    const taskGroupsIdsToAssignedTasksMap: Map<string, Array<AssignedTask>> = new Map();

    taskGroups.forEach(taskGroup => {
        if (!taskGroupsIdsToTasksMap.has(taskGroup.id)) taskGroupsIdsToTasksMap.set(taskGroup.id, []);
        if (!taskGroupsIdsToAssignedTasksMap.has(taskGroup.id)) taskGroupsIdsToAssignedTasksMap.set(taskGroup.id, []);
    });

    assignedTasks = props.assignedTasks.filter(obj => !obj.hasOwnProperty('taskList'));

    assignedTasks.forEach(assignedTask => {
        const assignedTasks = taskGroupsIdsToAssignedTasksMap?.get(assignedTask.taskGroupId);
        if (assignedTasks) {
            assignedTasks.push(assignedTask);
            taskGroupsIdsToAssignedTasksMap.set(assignedTask.taskGroupId, assignedTasks);
        }
    });

    const tasksIdsToExclude = assignedTasks?.reduce((list, task) => {
        if (task.referenceTaskId) list.push(task.referenceTaskId);
        return list;
    }, []);

    tasks.forEach(task => {
        const tasksById = taskGroupsIdsToTasksMap?.get(task.taskGroupId);
        if (tasksById && !tasksIdsToExclude.includes(task.id)) {
            tasksById.push(task);
            taskGroupsIdsToTasksMap.set(task.taskGroupId, tasksById);
        }
    });

    const buttons = taskGroups.map(taskGroup => {
        const tasks = taskGroupsIdsToTasksMap?.get(taskGroup.id);
        const assignedTasks = taskGroupsIdsToAssignedTasksMap?.get(taskGroup.id);
        if ((tasks && tasks.length > 0) || (assignedTasks && assignedTasks.length > 0)) return <PickerWindow
            taskGroup={taskGroup}
            tasks={tasks}
            assignedTasks={assignedTasks.sort(function (a, b) {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })}
            projectId={projectId}
            isOpen={openSections.get(taskGroup.id)}
            onOpen={() => {
                openSections.set(taskGroup.id, true);
                setOpenSections(new Map(openSections))
            }}
            onClose={() => {
                openSections.set(taskGroup.id, false);
                setOpenSections(new Map(openSections))
            }}
            onAssignedTaskRemove={onAssignedTaskRemove}
            onNewAssignedTaskCreate={onNewAssignedTaskCreate}
            onNewAssignedTaskCreated={onNewAssignedTaskCreated}
            onAssignedTaskUpdate={onAssignedTaskUpdate}
            onAssignedTaskUpdated={onAssignedTaskUpdated}
            currentUserRole={currentUserRole}/>;
        return null;
    });
let disabled = (tasks[0]?.is_master!=null) ? tasks[0]?.is_master :false
    return (
        <>
         <div>
             <h3 justifyContent={"center"} alignItems={"center"} style={{ marginRight: 5}}  className={`${styles.header}`}> 
             Reference Task List - {result[0]?.taskList?.list_name}
            </h3>
        </div>
        {
            (disabled)?
            <Flexbox
            style={{color:"red",textAlign:"center"}}
            paddingTop={"250px"}
            flex={1}
            alignSelf={'center'}
            justifyContent={'center'} 
            >
              Please create new Task List from Task & Pricing
            </Flexbox>
            :
       
        <Flexbox height={'100%'} width={'100%'} flexDirection={'column'} paddingBottom={'100px'}>
            <Fab
                event={'click'}
                icon={<FontAwesomeIcon icon={faPlus} size={'2x'}/>}
            >
                 
                <Action
                    text="Collapse All"
                    onClick={() => {
                        setAllSections(false);
                    }}
                >
                    <Flexbox flexDirection={'column'}>
                        <FontAwesomeIcon icon={faChevronDown}/>
                        <FontAwesomeIcon icon={faChevronUp}/>
                    </Flexbox>
                </Action>
                <Action
                    text="Expand All"
                    onClick={() => {
                        setAllSections(true);
                    }}
                >
                    <Flexbox flexDirection={'column'}>
                        <FontAwesomeIcon icon={faChevronUp}/>
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </Flexbox>
                </Action>

            </Fab>
          
            {buttons}
                <Flexbox 
                flexDirection={"row"}
                flex={1} 
                flexWrap={"wrap"} 
                alignItems={'center'} 
                justifyContent={'flex-start'}
                >
                    <Tag intent={Intent.SUCCESS}  large  className={styles.estimationCard} >
                    Project Est. Cost: ${props.totalEstimation ? props.totalEstimation:'0'} 
                    </Tag>
                 </Flexbox>  
        </Flexbox>
}
        </>
    )
}

type PickerWindowProps = {
    taskGroup: TaskGroup,
    tasks: Array<Task>,
    assignedTasks: Array<AssignedTask>,
    isOpen: boolean,
    onClose: ()=>any,
    onOpen: ()=>any,
    onNewAssignedTaskCreate: (assignedTaskToCreate: CreateAssignedTaskRequest) => Promise<any>,
    onAssignedTaskUpdate: (assignedTask: AssignedTask,
                           updateAssignedTaskRequest: UpdateAssignedTaskRequest) => Promise<any>,
    onNewAssignedTaskCreated: ()=>any,
    onAssignedTaskUpdated: ()=>any,
    onAssignedTaskRemove: (assignedTask: string) => Promise<any>,
    projectId: string,
    currentUserRole: String,
    project: ?Project,
}


function PickerWindow(props: PickerWindowProps) {
  let { assignedTasks } = props;
    const {
              currentUserRole,taskGroup, tasks, isOpen, onNewAssignedTaskCreate, onNewAssignedTaskCreated,
              projectId, onAssignedTaskUpdate, onAssignedTaskUpdated, onClose, onOpen, onAssignedTaskRemove,project
          } = props;

    const [projectPhotos, setPojectPhotos] = useState(null);
    const {attachmentBloc} = useContext(BlocRegistry);
   
    useEffect(() => {
        const subscription: Subscription = attachmentBloc.subscribeToAttachmentContext({
            next(event: HomeownerApplicationOutEventTypes) {
                setPojectPhotos(event.attachments);
            },
            error(err: Error) {
                throw err;
            }
        });
        return () => {
            subscription.unsubscribe();
        }
    }, [attachmentBloc]);

    useEffect(() => {
        if (!projectId) return;
        attachmentBloc.sendEvent(new FetchAttachmentsForProjectEvent(props.projectId, AttachmentTypeEnum.image));
    }, [projectId]);

     const addTask = () =>{
            if(currentUserRole === AuthRoles.ADMIN || currentUserRole === AuthRoles.PROJECT_ADMIN || currentUserRole === AuthRoles.ACCOUNT_MANAGER || currentUserRole === AuthRoles.PROJECT_MANAGER){
                return (
                    <CreateAssignedTaskRequestBuilder
                        projectId={projectId}
                        onNewAssignedTaskCreate={onNewAssignedTaskCreate}
                        onNewAssignedTaskCreated={onNewAssignedTaskCreated}
                        tasks={tasks}/>
                )
            }
          }


    return (
        <Card className={`${styles.pickerCard} bp3-ui-text`} elevation={isOpen ? 3 : 1}>
           <Button minimal fill id={taskGroup.id} text={
                <Flexbox justifyContent={"center"}  alignItems={'center'}>
                    <h3 className={styles.pickerCardTitle} style={{marginRight: 5}}>Assigned Task: {assignedTasks.length} | </h3>
                    <h3 className={styles.pickerCardTitle}>{taskGroup.title}</h3>
                    <Divider/>
                    {isOpen ? <FontAwesomeIcon icon={faChevronDown}/> : < FontAwesomeIcon icon={faChevronUp}/>}
                </Flexbox>
            } onClick={() => {
                if (isOpen) onClose();
                else onOpen();
            }}/>
            <Collapse isOpen={isOpen} className={isOpen ? `${styles.assignedTasksContainer}` : ''}>
                <Flexbox marginBottom={'10px'} width={'100%'}>
                    {addTask()}
                </Flexbox>
                <div className={styles.assignedTasksList}>
                    <Flexbox justfyContent={'flex-start'} flexDirection={'column'}>
                        <h4 className={styles.pickerCardTitle}>Assigned Tasks</h4>
                        <Divider className={styles.fullWidthDivider}/>
                        {assignedTasks.length === 0 ?
                            'None' :
                            assignedTasks.map((task: AssignedTask) => <AssignedTaskQuantityEditor
                                onAssignedTaskRemove={onAssignedTaskRemove}
                                assignedTask={task}
                                onUpdateAssignedTask={onAssignedTaskUpdate}
                                onAssignedTaskUpdated={onAssignedTaskUpdated}
                                currentUserRole={currentUserRole}
                                photos={projectPhotos}
                                project={project}
                                />)}
                    </Flexbox>
                </div>
            </Collapse>
        </Card>
    )
}
