//@flow

import User from "../User/User";

export default class Team {
    projectManagers: Array<User>;
    tenantAdmins: Array<User>;
    projectAdmins:Array<User>;
    constructor(projectManagers: Array<User>, tenantAdmins: Array<User>,projectAdmins:Array<User>) {
        this.projectManagers = projectManagers;
        this.tenantAdmins = tenantAdmins;
        this.projectAdmins=projectAdmins;
    }
}
