// @flow

import type { AuthBloc } from "../../blocs/authBloc";
import type Vendor from "../../models/Vendor/Vendor";
import { apiAxios } from "../../config/AxiosConfig";
import {
  CreateVendorRequest,
  UpdateVendorRequest,
  VendorAttachment
} from "../../models/Vendor/Vendor";
import { AppToaster } from "../../components/Toaster/Toaster";
import { Intent } from "@blueprintjs/core";
import history from "../../history";

class VendorApi {
  authBloc: AuthBloc;

  constructor(authBloc: AuthBloc) {
    this.authBloc = authBloc;
  }

  async getAll(): Promise<Array<Vendor>> {
    const tenantId = this.authBloc.getCurrentTenant();

    const response = await apiAxios.get(
      `/tenants/${tenantId}/vendors`,
      this.buildHeaders()
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async createVendor(
    createVendorRequest: CreateVendorRequest
  ): Promise<Vendor> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.post(
        `/tenants/${tenantId}/vendors`,
        createVendorRequest,
        this.buildHeaders()
      );

      if (response.status === 201) {
        AppToaster.show({
          message: "Contact Created",
          intent: Intent.PRIMARY
        });

        history.goBack();

        return response.data;
      }
      // else if (response.status === 409)
      // {
      //   AppToaster.show({
      //     message: "Vendor Already Exist",
      //     intent: Intent.DANGER
      //   });
      // }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async updateVendor(
    updateVendorRequest: UpdateVendorRequest
  ): Promise<Vendor> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();
      const response = await apiAxios.patch(
        `/tenants/${tenantId}/vendors/${updateVendorRequest.id}`,
        updateVendorRequest,
        this.buildHeaders()
      );

      if (response.status === 204) {
        AppToaster.show({
          message: "Contact Updated",
          intent: Intent.PRIMARY
        });

        history.goBack();

        return response.data;
      } else {
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async removeVendorFromList(vendorId: string): Promise<Vendor> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.delete(
        `/tenants/${tenantId}/vendors/${vendorId}`,
        this.buildHeaders()
      );

      if (response.status === 200) {
        AppToaster.show({
          message: "Contact Deleted",
          intent: Intent.PRIMARY
        });
        return response.data;
      } else {
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }
  async vendorAttachments(attachments: VendorAttachment): Promise<Vendor> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.post(
        `/tenants/${tenantId}/vendors/attachments`,
        attachments,
        this.buildHeaders()
      );
        return response.data;
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }
  buildHeaders = () => {
    const accessToken = this.authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };
}

export default VendorApi;
