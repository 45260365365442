// @flow

import React, { useState, useEffect } from "react";
import styles from "./ManageProjectGroupsView.module.css";
import { Column, Table, TableLoadingOption } from "@blueprintjs/table";
import { Spinner } from "@blueprintjs/core";
import ProjectGroup, {
  CreateProjectGroupRequest,
  UpdateProjectGroupRequest,
} from "../../models/ProjectGroup/ProjectGroup";
import ProjectGroupTable from "../../components/ProjectGroupsTable/ProjectGroupsTable";
import Flexbox from "flexbox-react";
import CreateProjectGroupPopoverButton from "../../components/CreateProjectGroupPopoverButton/CreateTaskGroupPopoverButton";
import DeleteConfirmationPopup from "../../components/ConfirmationAlert/ConfirmationAlert";
import { Intent, Overlay, Button, Checkbox } from "@blueprintjs/core";
import ProjectSheetExporter from "../../components/ProjectSheetExporter/ProjectSheetExporter";
import _ from "lodash";
import { AuthRoles } from "../../blocs/authBloc";
import Project, { ProjectStates } from "../../models/Project/Project";

type Props = {
  projectGroups: ?Array<ProjectGroup>,
  onAddProjectGroupButtonClick: (
    projectGroupToCreate: CreateProjectGroupRequest
  ) => any,
  onEditProjectGroupButtonClick: (
    projectGroupToEdit: UpdateProjectGroupRequest
  ) => any,
  onDeleteProjectGroupButtonClick: (projectgroupId: string) => any,
  projects: ?Array<Project>,
  onExportProjectGroupButtonClick: (projectgroupId: string) => any,
  onExportSheetComplete: () => any,
  onCreateProjectGroupButtonClick: () => any,
  onEditProjectGroupMenuClick: (projectgroupId: string) => any,
  onProjectGroupFilterChange: () => any,
};

export default function ManageProjectGroupsView(props: Props) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let paramsString =  params.state;
  let statesData = [];
  if(paramsString){
    statesData = paramsString.split(",");
  }
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [projectGroupId, setProjectGroupId] = useState(null);
  const [isOpen, setOpen] = useState(statesData.includes(ProjectStates.OPEN) ? true : false);
  const [isClosed, setClosed] = useState(statesData.includes(ProjectStates.CLOSED) ? true : false);
  const [isRejected, setRejected] = useState(statesData.includes(ProjectStates.REJECTED) ? true : false);
  const [filterDisabled, setFilterDisabled] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [projectGroupName, setProjectGroupName] = useState(false);
  const [projectGroups, setProjectGroups] = useState([])
  const {
    onDeleteProjectGroupButtonClick,
    projects,
    onExportProjectGroupButtonClick,
    projectExportData,
    onExportSheetComplete,
    onCreateProjectGroupButtonClick,
    onEditProjectGroupMenuClick,
    currentUserRole,
    onProjectGroupFilterChange
  } = props;

  useEffect(() => {
    var currentUserData = _.find(props.users, {
      id: props?.currentUser?.sub,
    });
    if (currentUserRole == AuthRoles.COMPLIANCE_AUDITOR) {
      const projectGroupsData = _.chain(props.projectGroups)
        .keyBy("id") // or .indexBy() if using lodash 3.x
        .at(currentUserData?.attributes?.projectGroupIds)
        .value();
      if (projectGroupsData[0] !== undefined) {
        setProjectGroups(projectGroupsData ? projectGroupsData : []);
      }
    } else {
      setProjectGroups(props.projectGroups);
    }
  }, [props.projectGroups, props.currentUser, props.users]);

  let list = (
    <Table
      numRows={10}
      loadingOptions={[
        TableLoadingOption.CELLS,
        TableLoadingOption.ROW_HEADERS,
      ]}
    >
      <Column name="Name" />
    </Table>
  );



  if (projectGroups) {
    if (projectGroups.length === 0)
      list = (
        <div
          className={`bp3-ui-text bp3-text-disabled ${styles.noProjectGroupsText}`}
        >
          no project groups
        </div>
      );
    else {
      list = (
        <ProjectGroupTable
          isComplainceAuditor={currentUserRole == AuthRoles.COMPLIANCE_AUDITOR ? true : false}
          isMarketingRole={currentUserRole == AuthRoles.MARKETING_MANAGER ? true : false}
          projectGroups={projectGroups}
          onEditProjectGroupButtonClick={props.onEditProjectGroupButtonClick}
          onDeleteProjectGroupButtonClick={async (projectGroupId) => {
            setConfirmationPopup(true);
            setProjectGroupId(projectGroupId);
          }}
          onExportProjectGroupButtonClick={async (projectGroupId, projectName) => {
            const ms = await onExportProjectGroupButtonClick(projectGroupId);
            setExporting(true);
            setProjectGroupId(projectGroupId);
            setProjectGroupName(projectName);
          }}
          onEditProjectGroupMenuClick={onEditProjectGroupMenuClick}
        />
      );
    }
  }

  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      icon="trash"
      type={Intent.DANGER}
      onCancel={() => setConfirmationPopup(false)}
      onConfirm={async () => {
        await onDeleteProjectGroupButtonClick(projectGroupId);
        setConfirmationPopup(false);
      }}
      message="Are you sure you want to delete this Project Group?"
    />
  );

  const projectExporter = (
    <Overlay
      isOpen={exporting}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      enforceFocus
      usePortal
      autoFocus
    >
      <Flexbox marginTop={"40vh"} width={"100%"}>
        <Flexbox flex={1} />
        <Flexbox minWidth={"500px"} flex={1} justifyContent={"center"}>
          {exporting ? (
            <ProjectSheetExporter
              onExportComplete={() => {
                setExporting(false);
                onExportSheetComplete();
              }}
              projects={projectExportData}
              projectExportData={projectExportData}
              projectGroupName={projectGroupName}
            />
          ) : null}
        </Flexbox>
        <Flexbox flex={1} />
      </Flexbox>
    </Overlay>
  );

  let selectedFilter = [];

  useEffect(() => {
    if (isOpen) selectedFilter.push(ProjectStates.OPEN);

    if (isClosed) selectedFilter.push(ProjectStates.CLOSED);

    if (isRejected) selectedFilter.push(ProjectStates.REJECTED);

    var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + '?state=' + `${selectedFilter.toString()}`;    
    window.history.pushState({ path: refresh }, "", refresh);

    onProjectGroupFilterChange(selectedFilter);

    setFilterDisabled(true);

    setTimeout(() => {
    setFilterDisabled(false)
    },3000)

  }, [isOpen, isClosed, isRejected]);

  let filter;

    filter = (
      <>
        <Flexbox
          flex={1}
          flexDirection={"row"}
          width={"20%"}
          // padding={"10px"}
          marginTop={"10px"}
          // marginBottom={"-40px"}

        >
          <Flexbox flex={1} style={{marginRight: 10}}>
            <Checkbox 
              large
              labelElement={<strong>Open</strong>}
              checked={isOpen}
              onChange={() => {
                setOpen(!isOpen);
              }}
            />
          </Flexbox>
          <Flexbox flex={1} style={{marginRight: 10}}>
            <Checkbox
              large
              labelElement={<strong>Closed</strong>}
              checked={isClosed}
              onChange={() => {
                setClosed(!isClosed);
              }}
            />
          </Flexbox>
          <Flexbox flex={1} style={{marginRight: 10}}>
            <Checkbox
              large
              labelElement={<strong>Rejected</strong>}
              checked={isRejected}
              onChange={() => {
                setRejected(!isRejected);
              }}
            />
          </Flexbox>
        </Flexbox>
      </>
    );

  return (
    <>
    {filterDisabled ? <Spinner/>:
      <div className={styles.manageProjectsGroupView} id="mainHeader">
        <h5>Project Groups</h5>
        {
          currentUserRole !== AuthRoles.COMPLIANCE_AUDITOR && currentUserRole !== AuthRoles.MARKETING_MANAGER &&
          <Flexbox justifyContent={"flex-start"}>
            <Button
              text={"Add Project Group"}
              icon={"plus"}
              loading={!projectGroups}
              onClick={onCreateProjectGroupButtonClick}
              minimal
            />
            {/* <CreateProjectGroupPopoverButton
            onNewProjectGroupCreate={props.onAddProjectGroupButtonClick}
            projectGroups={projectGroups}
          /> */}
          </Flexbox>
        }
        {filter}
        {list}
        {confirmPopup}
        {projectExporter}
      </div>
      }
      </>
  );
}
