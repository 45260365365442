// @flow

import type {PriceTypeEnumTypes, QuantityTypeEnumTypes} from "../Task/Task";

export default class AssignedTask {
    id: string;
    referenceTaskId: string;
    projectId: string;
    taskGroupId: string;
    title: string;
    description: string;
    unit: string;
    quantity: string;
    quantityType: QuantityTypeEnumTypes;
    price: string;
    priceType: PriceTypeEnumTypes;
    tenantId: string;
    taskEstimate:string;
    taskAttachments:String;
    
    constructor(id: string,
                referenceTaskId: string,
                projectId: string,
                taskGroupId: string,
                title: string,
                description: string,
                unit: string,
                quantity: string,
                quantityType: QuantityTypeEnumTypes,
                price: string,
                priceType: PriceTypeEnumTypes,
                tenantId: string,
                taskEstimate:string,
                taskAttachments:String
    ) {
        this.id = id;
        this.referenceTaskId = referenceTaskId;
        this.projectId = projectId;
        this.taskGroupId = taskGroupId;
        this.title = title;
        this.description = description;
        this.unit = unit;
        this.quantity = quantity;
        this.quantityType = quantityType;
        this.price = price;
        this.priceType = priceType;
        this.tenantId = tenantId;
        this.taskEstimate = taskEstimate
        this.taskAttachments = taskAttachments
    }
}


export class CreateAssignedTaskRequest {
    referenceTaskId: ?string;
    taskGroupId: string;
    title: string;
    description: string;
    unit: string;
    quantity: string;
    quantityType: QuantityTypeEnumTypes;
    price: string;
    priceType: PriceTypeEnumTypes;
    projectId: string;
    taskAttachments:String;
    constructor(referenceTaskId: ?string,
                taskGroupId: string,
                title: string,
                description: string,
                unit: string,
                quantity: string,
                quantityType: QuantityTypeEnumTypes,
                price: string,
                priceType: PriceTypeEnumTypes, projectId: string,
                taskAttachments:String
                ) {
        this.referenceTaskId = referenceTaskId;
        this.taskGroupId = taskGroupId;
        this.title = title;
        this.description = description;
        this.unit = unit;
        this.quantity = quantity;
        this.quantityType = quantityType;
        this.price = price;
        this.priceType = priceType;
        this.projectId = projectId;
        this.taskAttachments = taskAttachments;
    }
}

export class UpdateAssignedTaskRequest {
    title: ?string;
    description: ?string;
    unit: ?string;
    quantity: ?string;
    quantityType: ?QuantityTypeEnumTypes;
    price: ?string;
    priceType: ?PriceTypeEnumTypes;
    taskAttachments:?string;

    constructor(update: {
        title?: string,
        description?: string,
        unit?: string,
        quantity?: string,
        quantityType?: QuantityTypeEnumTypes,
        price?: string,
        priceType?: PriceTypeEnumTypes,
        taskAttachments?:string
    }) {
        if (update.title) this.title = update.title;
        if (update.description) this.description = update.description;
        if (update.unit) this.unit = update.unit;
        if (update.quantity) this.quantity = update.quantity;
        if (update.quantityType) this.quantityType = update.quantityType;
        if (update.price) this.price = update.price;
        if (update.priceType) this.priceType = update.priceType;
        if (update.taskAttachments) this.taskAttachments = update.taskAttachments;
    }
}

export function applyAssignedTaskUpdate(assignedTask: AssignedTask, update: UpdateAssignedTaskRequest) {
    const copy = new AssignedTask(
        assignedTask.id,
        assignedTask.referenceTaskId,
        assignedTask.projectId,
        assignedTask.taskGroupId,
        assignedTask.title,
        assignedTask.description,
        assignedTask.unit,
        assignedTask.quantity,
        assignedTask.quantityType,
        assignedTask.price,
        assignedTask.priceType,
        assignedTask.tenantId,
        assignedTask.taskAttachments,
    );

    if (update.title) copy.title = update.title;
    if (update.description) copy.description = update.description;
    if (update.unit) copy.unit = update.unit;
    if (update.quantity) copy.quantity = update.quantity;
    if (update.quantityType) copy.quantityType = update.quantityType;
    if (update.price) copy.price = update.price;
    if (update.priceType) copy.priceType = update.priceType;
    if (update.taskAttachments) copy.taskAttachments = update.taskAttachments;

    return copy;
};
