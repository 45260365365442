// @flow

import AssignedTask, {UpdateAssignedTaskRequest} from "../AssignedTask/AssignedTask";
import {GetAttachmentResponse} from "../Attachment/Attachment";
import moment from "moment";
import ProjectGroup from "../ProjectGroup/ProjectGroup";
import type {UpdateAddressRequest} from "../Project/Project";
import {Address} from "../Project/Project";
import type { AttachmentTypes } from "../Attachment/Attachment";


export default class Vendor {
    id: string;
    name:string;
    email:?string;
    tenantId: string;
    phoneNumber: string;
    address: Address;
    vendorAttachments:VendorAttachment;

    constructor(id: string,
                name: string,
                email: string,
                tenantId: string,
                phoneNumber: string,
                address: Address,
                vendorAttachments:VendorAttachment) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.tenantId = tenantId;
        this.phoneNumber = phoneNumber;
        this.address = address;
        this.vendorAttachments = vendorAttachments;
    }
}
export  class VendorAttachment{
  id: string;
  attachments:CreateVendorAttachment ;

  constructor(id: string,
              attachments: CreateVendorAttachment,) {
      this.id = id;
      this.attachments = attachments;
  }
}
export  class CreateVendorAttachment{
  title: ?string;
  fileType: string;
  contentType: ?string;
  payload:  string | Buffer;

  constructor(title: string,  fileType: string, contentType: ?string, payload:  string | Buffer) {
      this.title = title;
      this.fileType = fileType;
      if(contentType) this.contentType = contentType;
      this.payload = payload;
  }
}
export class CreateVendorRequest {
    name:string;
    lastName:string;
    email:?string;
    phoneNumber: string;
    phoneNumberExtension: string;
    address: Address;
    contactType: string;
    address: Address;
    contactType: string;
    cellNumber: String;
    cellNumberExtension: String;
    mainNumber: String;
    mainNumberExtension: String;
    faxNumber: String;
    faxNumberExtension: String;
    designation: string;
    description: ?String;
    licenseExpiryDate: string;
    insuranceExpiryDate: String;

    constructor(name: string,
                email: string,
                phoneNumber: string,
                phoneNumberExtension: String,
                address: Address, 
                contactType: string, 
                mainNumber: String, 
                mainNumberExtension: String, 
                cellNumber: String, 
                cellNumberExtension: String, 
                faxNumber: String, 
                faxNumberExtension: string, 
                designation: string,
                description: String,
                licenseExpiryDate:String,
                insuranceExpiryDate: string,
            ) {
        this.name = name;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.phoneNumberExtension= phoneNumberExtension;
        this.address = address;
        this.contactType = contactType;
        this.cellNumber = cellNumber;
        this.cellNumberExtension = cellNumberExtension;
        this.mainNumber = mainNumber;
        this.mainNumberExtension= mainNumberExtension;
        this.faxNumber = faxNumber;
        this.faxNumberExtension = faxNumberExtension;
        this.designation = designation;
        if(description) this.description = description;
        this.licenseExpiryDate = licenseExpiryDate;
        this.insuranceExpiryDate= insuranceExpiryDate;
    }

}



export class UpdateVendorRequest{
    id:string
    name:?string;
    email:?string;
    phoneNumber: string;
    phoneNumberExtension: String;
    address: Address;
    contactType: string;
    cellNumber: String;
    cellNumberExtension: String;
    mainNumber: String;
    mainNumberExtension: String;
    faxNumber: String;
    faxNumberExtension: String;
    designation: ?String;
    description: ?String;
    licenseExpiryDate: ?string;
    insuranceExpiryDate: String;
    deleteAttachments: ?string[];

    constructor(id:string,name: string,
        email: string,
        phoneNumber: string,
        phoneNumberExtension: String,
        address: Address, contactType: string, 
        mainNumber: String, 
        mainNumberExtension: String, 
        cellNumber: String, 
        cellNumberExtension: String, 
        faxNumber: String, 
        faxNumberExtension: string,  
        designation: String,
        description: String,
        licenseExpiryDate: ?string,
        insuranceExpiryDate: String,
        deleteAttachments: ?string[],

    ) {
        this.id = id;
        if(name) this.name = name;
        if(email) this.email = email;
        if(phoneNumber) this.phoneNumber = phoneNumber;
        if(phoneNumberExtension) this.phoneNumberExtension = phoneNumberExtension;
        if(address) this.address = address;
        if(contactType) this.contactType = contactType;
        if(cellNumber) this.cellNumber = cellNumber;
        if(cellNumberExtension) this.cellNumberExtension = cellNumberExtension;
        if(mainNumber)this.mainNumber = mainNumber;
        if(mainNumberExtension) this.mainNumberExtension = mainNumberExtension;
        if(faxNumber) this.faxNumber = faxNumber;
        if(faxNumberExtension) this.faxNumberExtension = faxNumberExtension;
        if(designation) this.designation = designation;
        if(description) this.description = description;
        if(licenseExpiryDate) this.licenseExpiryDate = licenseExpiryDate;
        if(insuranceExpiryDate) this.insuranceExpiryDate = insuranceExpiryDate;
        this.deleteAttachments = deleteAttachments;
    }
}



