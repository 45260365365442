// @flow

import React, { useState } from "react";
import styles from "./ManageVendorsView.module.css";
import { Column, Table, TableLoadingOption } from "@blueprintjs/table";
import Vendor from "../../models/Vendor/Vendor";
import VendorTable from "../../components/VendorTable/VendorTable";
import Flexbox from "flexbox-react";
import { Button, Icon, InputGroup, Intent } from "@blueprintjs/core";
import DeleteConfirmationPopup from "../../components/ConfirmationAlert/ConfirmationAlert";
import { handleContactSearch } from "../../config/Utils";
import { useEffect } from "react";
import { AppToaster } from '../../components/Toaster/Toaster';
import axios from "axios";
import mime from 'mime-types';
import FileSaver from "file-saver";

type Props = {
  vendors: ?Array<Vendor>,
  onAddVendorButtonClick: () => any,
  onVendorRemoveFromList: (vendorId: string) => any
};

export default function ManageVendorsView(props: Props) {
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [vendorId, setVendorId] = useState(null);
  const { vendors, onVendorRemoveFromList } = props;
  const [currentVendors, setCurrentVendors] = useState(vendors);
  const [vendorsProjects, setVendorsProjects] = useState([]);
  const [searcing, setSearching] = useState(false);
  
  const filterProjectsForVendors = () => {
    let projects = props.projects;
    if(projects && projects.length > 0){
      let projectsWhereVendor = [];
      projects.forEach(project => {
        project.vendors.map(vendor => {
          let projectsAssociated = [];
          if(projectsWhereVendor[vendor.id]){
             projectsAssociated = [...projectsWhereVendor[vendor.id].projects, project]
          } else {
            projectsAssociated.push(project)
          }
          projectsWhereVendor[vendor.id] = {projects: projectsAssociated}
        })
      });
      setVendorsProjects(projectsWhereVendor)
    }
  }

  useEffect(()=>{
    filterProjectsForVendors();
  }, [props.projects])

  useEffect(()=>{
    setCurrentVendors(vendors);
  }, [vendors])
  const handleCertificateDownload = async(request: Vendor)=>{    
    if (request.vendorAttachments.length == 0) {
      AppToaster.show({
        message: "File Not uploaded for this Contact",
        intent: Intent.DANGER,
      });
      return;
    } else {
      request.vendorAttachments.map(async (doc)=>{

     
      const encodedString = (await axios.get(doc.getUrl)).data;
      const base = encodedString.split(",")[1];
      const type = encodedString.split(";")[0].split("/")[1];
      const fileType = doc.fileType;
      const extension = mime.extension(fileType);

      const byteString = atob(base);

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      let file= [];
      
        let filetitle = doc.title; 
        const nameWithoutExtension = filetitle.split('.').slice(0, -1).join('.');
        let filename = `vendor_${request.name}_${nameWithoutExtension}.${extension}`;
        file =  new File([ab], filename, { type: type });
        FileSaver.saveAs(file);
    })
    }
  }
  let list = (
    <Table
      numRows={10}
      loadingOptions={[
        TableLoadingOption.CELLS,
        TableLoadingOption.ROW_HEADERS
      ]}
    >
      <Column name="Name" />
      <Column name="Address" />
      <Column name="Email" />
      <Column name="Phone" />
      <Column name="Contact Type" />
      <Column name="Projects" />
    </Table>
  );

  if (currentVendors) {
    if (currentVendors.length === 0)
      list = (
        <div
          className={`bp3-ui-text bp3-text-disabled ${styles.noProjectsText}`}
        >
          No Contacts
        </div>
      );
    else {
      list = (
        <VendorTable
          navigateToViewProject={props.navigateToViewProject}
          associatedProjects = {vendorsProjects}
          vendors={currentVendors}
          onVendorRemoveFromList={async vendorId => {
            setConfirmationPopup(true);
            setVendorId(vendorId);
          }}
          onCertificateDownload={handleCertificateDownload}
        />
      );
    }
  }

  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      icon="trash"
      type={Intent.DANGER}
      onCancel={() => setConfirmationPopup(false)}
      onConfirm={async () => {
        await onVendorRemoveFromList(vendorId);
        setConfirmationPopup(false);
      }}
      message="Are you sure you want to delete this vendor?"
    />
  );

  const handleContactsFilter = (selectedContactType) => {
    if(selectedContactType != "select"){
      let filteredContacts =  vendors.filter((vendor) => {
        return vendor.contactType == selectedContactType;
      })
      return filteredContacts
    }
    return vendors;
  }

  return (
    <div className={styles.manageVendorsView} id="mainHeader">
      <h5>Contacts</h5>
      <Flexbox justifyContent={"flex-start"}>
        <Button
          text={"Add Contact"}
          icon={"plus"}
          loading={!currentVendors}
          onClick={props.onAddVendorButtonClick}
          minimal
        />
      </Flexbox>
      <Flexbox flex={1} flexDirection={"row"}>
        <Flexbox flex={1} flexDirection={"column"}>
        <Flexbox flex={1} />
        <Flexbox flex={1} style={{ marginBottom: 12 }}>
          <InputGroup
            placeholder="Search..."
            leftIcon={<Icon icon="search" intent={Intent.PRIMARY} />}
            onChange={(e) => {
              setSearching(e.target.value === "" ? false : true);
              
              const serachedContacts = handleContactSearch(
                e,
                vendors
              );
              setCurrentVendors(serachedContacts);
            }}
          />
          </Flexbox>
        </Flexbox>
        <Flexbox flex={1} flexDirection={"column"}>
          <Flexbox flex={1}>
            <b>
              <label style={{ fontSize: "14px" }} htmlFor={"group"}>
                {"Contact Type"}
              </label>
            </b>
          </Flexbox>
          <Flexbox flex={1} style={{ marginBottom: 12 }}>
            <div class="bp3-select">
              <select
                defaultValue="select"
                onChange={(e) => {
                  const filteredContacts = handleContactsFilter(e.target.value);
                  setCurrentVendors(filteredContacts);
                }}
              >
                <option value="select">-Select-</option>
                <option value="Vendor">Vendor</option>
                <option value="Donor">Donor</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </Flexbox>
        </Flexbox>
      </Flexbox>
      {list}
      {confirmPopup}
    </div>
  );
}
