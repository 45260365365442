// @flow

export default class TaskGroup {
    id: string;
    tenantId: string;
    title: string;
    taskListId:string

    constructor(id: string, tenantId: string, title: string,taskListId:string) {
        this.id = id;
        this.tenantId = tenantId;
        this.title = title;
        this.taskListId = taskListId
    }
}

export class CreateTaskGroupRequest {
    title: string;
    task_list_name_id :String;

    constructor(title: string,task_list_name_id :string) {
        this.title = title;
        this.task_list_name_id =task_list_name_id;
    }
}
