//@Flow
import {PartialObserver, Subject} from 'rxjs'
// import HomeownerApplication, {CreateHomeownerApplicationRequest, UpdateHomeownerApplicationRequest} from "../models/HomeownerApplication/HomeownerApplication";
import ExportErService from "../service/ExportErService/ExportErService";
import React from "react";
import ExportEr from '../models/ExportEr/ExportEr';

export const ExportErEventTypesEnum = Object.freeze({
    UPDATE_EXPORT_ER: Symbol("UPDATE EXPORT_ER"),
    FETCH_EXPORT_ER_FOR_PROJECT: Symbol("FETCH EXPORT_ER FOR PROJECT"),
});

export type ExportErEventTypes = $Keys<typeof ExportErEventTypesEnum>;

export class ExportErEvent {
    type: ExportErEventTypes;

    constructor(type: ExportErEventTypes) {
        this.type = type;
    }
}

export class FetchExportErForProjectEvent extends ExportErEvent {

    projectId: string;

    constructor(projectId: string) {
        super(ExportErEventTypesEnum.FETCH_EXPORT_ER_FOR_PROJECT);
        this.projectId = projectId;
    }
}

export class UpdateExportErForProjectEvent extends ExportErEvent {

    er: ExportEr;
    projectId: string;

    constructor(projectId: string, er: ExportEr) {
        super(ExportErEventTypesEnum.UPDATE_EXPORT_ER);
        this.projectId = projectId;
        this.er = er;
    }
}

export class ExportErBloc {
    _outExportErStream: Subject = new Subject();

    subscribeToExportErContext(observer?: PartialObserver<ExportErOutEvent>) {
        return this._outExportErStream.subscribe(observer);
    }

    _eventController: Subject = new Subject();

    sendEvent(event: ExportErEvent) {
        return this._eventController.next(event)
    }

    _exportErService: ExportErService;

    constructor(exportErService: ExportErService) {
        this._exportErService = exportErService;
        this._eventController.subscribe(this.buildEventHandler())
    }

    buildEventHandler = () => {
        return {
            next: async (event: ExportErEvent) => {
                switch (event.type) {
                    case ExportErEventTypesEnum.FETCH_EXPORT_ER_FOR_PROJECT: {
                        const projectId = (event: FetchExportErForProjectEvent).projectId;
                        const homeownerApplication = await this._exportErService.getExportErForProject(projectId);
                        this._outExportErStream.next(new FetchExportErForProjectOutEvent(projectId, homeownerApplication));
                        break;
                    }
                    case ExportErEventTypesEnum.UPDATE_EXPORT_ER: {
                        const {projectId, er} = (event: UpdateExportErForProjectEvent);
                        const updateEr = await this._exportErService.updateExportErForProject(projectId, er);
                        this._outExportErStream.next(new UpdateExportErForProjectOutEvent(projectId, updateEr));
                        break;
                    }
                    default: {
                        throw new Error("Unknown homeownerApplication event: " + event.type);
                    }
                }
            },
            error(err) {
                throw err
            },
        };
    };

    dispose() {
        this._outExportErStream.complete();
        this._eventController.complete();
    }
}


export const ExportErOutEventTypesEnum = Object.freeze({
    FETCHED: Symbol("FETCH"),
    UPDATED: Symbol("UPDATED"),
});

export type ExportErOutEventTypes = $Keys<typeof ExportErOutEventTypesEnum>;

export class ExportErOutEvent {
    type: ExportErOutEventTypes;

    constructor(type: ExportErOutEventTypes) {
        this.type = type;
    }
}

export class FetchExportErForProjectOutEvent extends ExportErOutEvent {

    projectId: string;
    homeownerApplication: ?HomeownerApplication;

    constructor(projectId: string, homeownerApplication: ?HomeownerApplication) {
        super(ExportErOutEventTypesEnum.FETCHED);
        this.homeownerApplication = homeownerApplication;
        this.projectId = projectId;
    }
}


export class UpdateExportErForProjectOutEvent extends ExportErOutEvent {

    projectId: string;
    updateEr: HomeownerApplication;

    constructor(projectId: string, updateEr: Array<ExportEr>) {
        super(ExportErOutEventTypesEnum.UPDATED);
        this.updateEr = updateEr;
        this.projectId = projectId;
    }
}
