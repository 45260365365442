//@flow

import React from "react";
import { MenuItem, Spinner } from "@blueprintjs/core";
import { MultiSelect, Suggest } from "@blueprintjs/select";
import { useField } from "formik";
import styles from "../CreateProjectRequestBuilder/CreateProjectRequestBuilder.module.css";

type Props<T> = {
  items: ?Array<T>,
  inputName?: string,
  filterItems: (
    query: string,
    T,
    _index: number,
    exactMatch: boolean
  ) => boolean,
  inputValueRenderer: T => string,
  inputSelectValueMapper: (Array<T>, T) => string,
  itemToKeyMapper: T => string,
  itemsEqual?: string
};

export default function CustomMultiSuggest<T>(props: Props<T>) {
  const { inputValueRenderer } = props;

  const [itemField, itemMeta] = useField(props.inputName);
  const valueChangeHandler = itemField.onChange;
  const error =
    itemMeta.error && itemMeta.touched ? (
      <div className={styles.errorText}>{itemMeta.error}</div>
    ) : (
      <div />
    );

  const items = props.items
    ? props.items.filter(
        item =>
          itemField.value.findIndex(fieldItem => fieldItem.id === item.id) ===
          -1
      )
    : [];

  const handleTagRemove = (_tag: string, index: number) => {
    let array = itemField.value;
    array.splice(index, 1);
    const event = {
      target: {
        name: itemField.name,
        value: array
      }
    };
    valueChangeHandler(event);
  };

  if (!props.items) return <Spinner />;
  else {
    return (
      <div>
        <MultiSelect
          {...props}
          fill
          allowCreate={false}
          inputValueRenderer={inputValueRenderer}
          itemRenderer={(item, { handleClick, modifiers, query }) => {
            return (
              <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={props.itemToKeyMapper(item)}
                onClick={handleClick}
                text={highlightText(inputValueRenderer(item), query)}
              />
            );
          }}
          noResults={<MenuItem disabled={true} text="No results." />}
          itemsEqual={props.itemsEqual}
          items={items}
          itemPredicate={props.filterItems}
          selectedItems={itemField.value}
          tagRenderer={inputValueRenderer}
          tagInputProps={{ onRemove: handleTagRemove }}
          onItemSelect={item => {
            let array = itemField.value;
            if (!Array.isArray(array)) array = [];

            const event = {
              target: {
                name: itemField.name,
                value: props.inputSelectValueMapper(array, item)
              }
            };
            valueChangeHandler(event);
          }}
          onItemDeSelect={item => {
            let array = itemField.value;
            if (!Array.isArray(array)) array = [];

            const event = {
              target: {
                name: itemField.name,
                value: props.inputSelectValueMapper(array, item)
              }
            };
            valueChangeHandler(event);
          }}
        />
      </div>
    );
  }
}

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}
