// @flow

import React, {useState, useEffect, useContext} from 'react';
import styles from "./Forbidden.module.css"
import {Button, Callout, Card, Intent, Overlay} from "@blueprintjs/core";
import Flexbox from 'flexbox-react';
import BlocRegistry from "../../blocs/registry";
import {AuthEvents, AuthStates} from "../../blocs/authBloc";
import history from "../../history";
import type {Subscription} from "rxjs";

export default function Forbidden() {
    const {authBloc} = useContext(BlocRegistry);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(true)
        }, 150)
    }, []);


    useEffect(() => {

        const subscription: Subscription = authBloc.subscribeToAuthContext((authContext)=>{switch(authContext.State){
            case AuthStates.UNINITIALIZED:{
                break;
            }
            case AuthStates.UNAUTHENTICATED:{
                history.replace('/login');
                break;
            }
            case AuthStates.AUTHENTICATING:{
                break;
            }
            case AuthStates.AUTHENTICATED:{
                history.replace(`/app`);
                break;
            }
            default: {
            }
        }});
        return () => {
            subscription.unsubscribe();
        }
    }, [authBloc]);


    return (
        <Flexbox className={styles.forbiddenScreen}>
            <Overlay isOpen={isOpen} usePortal={true} autoFocus enforceFocus canEscapeKeyClose={false}
                     canOutsideClickClose={false} hasBackdrop>
                <Flexbox marginTop={'40vh'} width={'100%'}>
                    <Flexbox flex={1}/>
                    <Flexbox minWidth={'500px'} flex={1} justifyContent={'center'}>
                        <Card className={`styles.forbiddenOverlayContent`} elevation={4}>
                            <Callout title={'Thanks for signing up!'} intent={Intent.PRIMARY}>
                                <Flexbox flexDirection={'column'}>
                                    <span>It looks like you haven't been on-boarded yet.</span>
                                    <span>Please contact your Rehab Tracker admin to get started.</span>
                                </Flexbox>
                                <Flexbox flexDirection={'column'}>
                                    <Button text={'Logout'} intent={Intent.PRIMARY} onClick={() => {
                                        authBloc.sendEvent(AuthEvents.LOGOUT);
                                    }}/>
                                </Flexbox>
                            </Callout>
                        </Card>
                    </Flexbox>
                    <Flexbox flex={1}/>
                </Flexbox>
            </Overlay>
        </Flexbox>
    );
}
