//@flow

import React from "react";
import {useField} from "formik";
import styles from "../CreateProjectRequestBuilder/CreateProjectRequestBuilder.module.css";
import {Spinner, Tag, Intent} from "@blueprintjs/core";
import {DatePicker} from "@blueprintjs/datetime";
import moment from "moment";

type Props = {
    inputName: string,
    disabled?: boolean,
}

export default function DateSelect(props: Props) {
    const [itemField, itemMeta] = useField(props.inputName);
    const valueChangeHandler = itemField.onChange;
    const error = itemMeta.error && itemMeta.touched ?
        <div className={styles.errorText}>{itemMeta.error}</div> : <div/>;

    const m = moment(itemField.value);

    return (
        <div>
            {m.isValid() ? <Tag intent={Intent.PRIMARY}>{m.format('dddd, LL')}</Tag> :
                <Tag minimal={true}>no date</Tag>
            }
            {!props.disabled && <DatePicker
                showActionsBar={true}
                disabled={props.disabled}
                key={props.inputName}
                onChange={(date) => {
                    const event = {
                        target: {
                            name: itemField.name,
                            value: date,
                        }
                    };
                    valueChangeHandler(event)
                }}>
            </DatePicker>}
        </div>);
}
