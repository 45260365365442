//@flow

import React from "react";
import {useField} from "formik";
import styles from "../CreateProjectRequestBuilder/CreateProjectRequestBuilder.module.css";
import {Button, MenuItem, Spinner} from "@blueprintjs/core";
import {Select} from "@blueprintjs/select";

type Props = {
    items: Array<string>,
    inputName: string,
    disabled?: boolean,
}

export default function TextSelect(props: Props) {
    const [itemField, itemMeta] = useField(props.inputName);
    const valueChangeHandler = itemField.onChange;
    const error = itemMeta.error && itemMeta.touched ?
        <div className={styles.errorText}>{itemMeta.error}</div> : <div/>;

    if (!props.items) return <Spinner/>;
    else {
        return (
            <Select
                disabled={props.disabled}
                key={props.inputName}
                filterable={false}
                items={props.items}
                activeItem={itemField.value}
                itemRenderer={(item, {handleClick, modifiers}) => {
                    return <MenuItem
                        active={modifiers.active}
                        disabled={modifiers.disabled}
                        key={item}
                        onClick={handleClick}
                        text={item}
                    />
                }}
                onItemSelect={(item) => {
                    if(props.onChange){
                        props.onChange(item);
                    }
                    const event = {
                        target: {
                            name: itemField.name,
                            value: item,
                        }
                    };
                    valueChangeHandler(event)
                }}>
                <Button key={props.inputName+'button'} text={itemField.value ? itemField.value : 'Select'} rightIcon={props.disabled ? null : "double-caret-vertical"}/>
            </Select>);
    }
}
