// CustomToggleSwitch.js
import React, { useState } from "react";
import "./CustomToggleSwitch.css"; // Import a CSS file for styling

const CustomToggleSwitch = ({ onToggle, defaultChecked }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked || false);

  const handleToggle = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onToggle(newValue);
  };

  return (
    <div
      className={`custom-toggle-switch ${isChecked ? "checked" : ""}`}
      onClick={handleToggle}
    >
      <div className="toggle-slider"></div>
      <div className="toggle-labels">
        <span className="yes-label">Yes</span>
        <span className="no-label">No</span>
      </div>
    </div>
  );
};

export default CustomToggleSwitch;