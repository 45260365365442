//@flow

import React, {useState,useContext,useEffect,useCallback} from "react";
import Flexbox from "flexbox-react";
import {Button, Card, Divider, MenuItem, NumericInput, Spinner, Intent, Popover, Keys, Overlay, Tag} from "@blueprintjs/core";
import styles from './AssignedTaskQuantityEditor.module.css'
import 'react-tiny-fab/dist/styles.css';
import type AssignedTask from "../../models/AssignedTask/AssignedTask";
// import BlocRegistry from "../../blocs/registry";
// import Gallery from "react-photo-gallery"
import * as Yup from "yup";
 import { Responsive, WidthProvider } from "react-grid-layout";
import {QuantityTypeEnum} from "../../models/Task/Task";
import {Form, Formik, useField} from "formik";
import {UpdateAssignedTaskRequest} from "../../models/AssignedTask/AssignedTask";
// import { AuthContext, AuthRoles, AuthStates } from "../../blocs/authBloc";
// import ProjectPhotosGridBoxes from "../ProjectPhotosGrid/ProjectPhotosGridBoxes";
// import { AttachmentBloc, FetchAttachmentsForProjectEvent, CreateAttachmentForProjectEvent } from "../../blocs/projectAttachmentBloc";
// import { AttachmentTypeEnum, GetAttachmentResponse } from "../../models/Attachment/Attachment";
// import { UserContext } from "../../blocs/userBloc";
import { ImageTile } from "./ImageTileTask";
import { AuthRoles } from "../../blocs/authBloc";

const ResponsiveGridLayout = WidthProvider(Responsive);
const generateLayout = (buttons: Array<any>) => {
    return {
        lg: buttons.map((button, index) => {
            return {
                x: index % 5,
                y: Math.floor(index / 5),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        md: buttons.map((button, index) => {
            return {
                x: index % 4,
                y: Math.floor(index / 4),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        sm: buttons.map((button, index) => {
            return {
                x: index % 2,
                y: Math.floor(index / 2),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        xs: buttons.map((button, index) => {
            return {
                x: index % 1,
                y: Math.floor(index / 1),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        xss: buttons.map((button, index) => {
            return {
                x: 0,
                y: index,
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        })
    };
};
const formStates = {
    INPUT: 'INPUT',
    SUBMITTING: 'SUBMITTING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
};

const roleCheck = (e) =>{
    if(e === AuthRoles.ACCOUNT_MANAGER ||e === AuthRoles.ADMIN ||e === AuthRoles.PROJECT_ADMIN|| e === AuthRoles.PROJECT_MANAGER )
    return true
}
export default function AssignedTaskQuantityEditor(props: Props) {

    const {assignedTask,currentUserRole,projectId,project,photos} = props;
    const [state, setState] = useState(formStates.INPUT);
    const [gridView, setGridView] = useState(false);
    const [attachGridView, setAttachGridView] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
   

let list = <Spinner />;
let photoId=[]
const onPhotoAttachTask = (id,mechanism) =>{
    if(mechanism!=="pop"){
    photoId.push(id)
    }else{
         for( let i = 0; i < photoId.length; i++){ 
            if ( photoId[i] === id) { 
                photoId.splice(i, 1); 
            }
        }
    }
}

    if(photos){
        let taskAttachmentsArray = assignedTask.taskAttachments ? assignedTask.taskAttachments.split(',') : [];
         photoId=[...taskAttachmentsArray]
        const  photoTiles = photos.map((photoAttachment, index) => {
            return (
                <Flexbox key={index }  height={'100%'} width={'100%'}>
                     <ImageTile index={index } attachmentResponse={photoAttachment} 
                     src={photoAttachment.getUrl}
                     id={photoAttachment.id}
                     photoIds={photoId}
                     onPhotoAttachTask={onPhotoAttachTask}
                        project={project}
                        currentUserRole={props.currentUserRole}
                        />
                </Flexbox>)
        });
    const layouts = generateLayout(photoTiles);
    list = (
        <ResponsiveGridLayout layouts={layouts}
            rowHeight={300}
            margin={[10, 10]}
            breakpoints={{ lg: 1500, md: 1280, sm: 738, xs: 480, xxs: 0 }}
            cols={{ lg: 5, md: 4, sm: 2, xs: 1, xxs: 1 }}>
            {photoTiles}
        </ResponsiveGridLayout>);
      
    }
    if(gridView) {
        const onPhotoSave = () =>{
           const updateAssignedTaskRequest = new UpdateAssignedTaskRequest(
               {taskAttachments: photoId.toString(),
                quantity:assignedTask.quantity
            }
           );
            props.onUpdateAssignedTask(assignedTask, updateAssignedTaskRequest);
            setGridView(false)
        }
       
    return (
        <Overlay
          isOpen={gridView}
          canEscapeKeyClose={true}
          onClose={() => {
            setGridView(false);

          }}

        >
             <Flexbox flexGrow={1}
          flexDirection={'column'} paddingLeft={'10px'} paddingRight={'10px'} width={'100%'} height={'100%'}
            className={styles.fullscreenImageContainer}
          >
            <div className={styles.closeButton}>
              <Button icon={"cross"} onClick={() =>  setGridView(false)}/>
            </div>
            <Flexbox justifyContent={'center'} alignContent={'center'} width={'100%'} style={{marginTop: 42}}>
                <div className={`bp3-text-large ${styles.listContainer}`}>
                    {list}
                </div>
            </Flexbox>
            <Flexbox justifyContent={'center'} alignContent={'center'} width={'100%'}>
            <Button icon={'tick'} text={"Save"} onClick={() => {
                                     onPhotoSave()
                                    }} />
            </Flexbox>
     
      </Flexbox>
      </Overlay>
    );
    }
let listAttachPhoto = <Spinner />;

    if(attachGridView) {
        if(assignedTask.taskAttachments){
            let taskAttachmentsArray = assignedTask.taskAttachments.split(',');
           let attachPhoto = photos.filter(g => taskAttachmentsArray.includes(g.id))
            if(attachPhoto.length<1){
                listAttachPhoto= <Flexbox justifyContent={"center"} alignItems="center" height={'100%'} style={{marginTop: 100}}>No photo Attached</Flexbox>
            }
           if(attachPhoto){

            const  attachPhotoTiles = attachPhoto.map((photoAttachment, index) => {
                  return (
                      <Flexbox key={index }  height={'100%'} width={'100%'}>
                           <ImageTile index={index } attachmentResponse={photoAttachment} 
                           src={photoAttachment.getUrl}
                           id={photoAttachment.id}
                          // onPhotoAttachTask={onPhotoAttachTask}
                              project={project}
                              currentUserRole={props.currentUserRole}
                              disabledSelect={true}
                              />
                      </Flexbox>)
              });
          const layouts = generateLayout(attachPhotoTiles);
          listAttachPhoto = (
              <ResponsiveGridLayout layouts={layouts}
                  rowHeight={300}
                  margin={[10, 10]}
                  breakpoints={{ lg: 1500, md: 1280, sm: 738, xs: 480, xxs: 0 }}
                  cols={{ lg: 5, md: 4, sm: 2, xs: 1, xxs: 1 }}>
                  {attachPhotoTiles}
              </ResponsiveGridLayout>);
            
          }
        }else{
            listAttachPhoto=<Flexbox justifyContent={"center"} alignItems="center" height={'100%'} style={{marginTop: 100}}>No photo Attached</Flexbox>
        }
     

        return (
            <Overlay
              isOpen={attachGridView}
              canEscapeKeyClose={true}
              onClose={() => {
                setAttachGridView(false);
              }}
    
            >
                 <Flexbox flexGrow={1}
              flexDirection={'column'} paddingLeft={'10px'} paddingRight={'10px'} width={'100%'} height={'100%'}
                className={styles.fullscreenImageContainer}
              >
                <div className={styles.closeButton}>
                  <Button icon={"cross"} onClick={() =>  setAttachGridView(false)}/>
                </div>
                <Flexbox justifyContent={'center'} alignContent={'center'} width={'100%'} style={{marginTop: 42}}>
                    <div className={`bp3-text-large ${styles.listContainer}`}>
                        {listAttachPhoto}
                    </div>
                </Flexbox>
               
         
          </Flexbox>
          </Overlay>
        );
    }
    return (
        <Card>
            <Formik
                key={assignedTask.id}
                initialValues={{
                    quantity: assignedTask.quantity,
                }}
                validationSchema={Yup.object({
                    quantity: Yup.number().positive()
                        .required('Required')
                })}
                onSubmit={async (values, {resetForm}) => {
                    let quantity;

                    switch (assignedTask.quantityType) {
                        case QuantityTypeEnum.FLOAT: {
                            quantity = parseFloat(values.quantity).toString();
                            break;
                        }
                        default: {
                            console.error("Unknown quantity type: " + assignedTask.quantityType);
                            return;
                        }
                    }

                    const updateAssignedTaskRequest = new UpdateAssignedTaskRequest(
                        {quantity: quantity}
                    );

                    setState(formStates.SUBMITTING);
                    await props.onUpdateAssignedTask(assignedTask, updateAssignedTaskRequest);
                    setState(formStates.SUCCESS);
                    props.onAssignedTaskUpdated();
                    setState(formStates.INPUT);
                }}>
                {formik => {
                    return <Form>
                        <Flexbox width={'100%'} flexDirection={'column'}>
                            <Flexbox flexDirection={'row'}>
                                <Flexbox flex={1}>
                                    <Flexbox flex={2} alignItems={'center'} justifyContent={'flex-start'}
                                             paddingLeft={'10px'}>
                                        <span className={styles.taskText}>{assignedTask.title}</span>
                                       
                                    </Flexbox>
                                    <Divider/>
                                    <Flexbox flex={2} alignItems={'center'} justifyContent={'center'}
                                             paddingLeft={'10px'}>
                                        <h3 style={{color:'green'}}>Est. Cost: ${assignedTask.taskEstimate} </h3>
                                    </Flexbox>
                                    <Divider/>
                                    <Flexbox flex={1} alignItems={'center'} justifyContent={'center'}>
                                        <span className={styles.taskText}>{assignedTask.unit}</span>
                                    </Flexbox>
                                    <Divider/>
                                </Flexbox>
                                <Flexbox flex={1} justifyContent={'flex-end'}>
                                    <CustomNumberField inputName={'quantity'}
                                                       intent={formik.values.quantity.toString() !== assignedTask.quantity ?
                                                           Intent.WARNING :
                                                           undefined}/>
                                </Flexbox>
                            </Flexbox>
                            <Flexbox marginTop={'10px'}  justifyContent={'space-around'}>
                                <Flexbox flex={2}/>
                                <Flexbox flex={1}>
                                  <Flexbox flex={1} alignItems={'center'} >
                                      <Button type="submit" icon={'add'} minimal
                                       onClick={()=>setGridView(true)}
                                                text={'Add Photos'}/>
                                  </Flexbox>
                                  <Flexbox flex={1} alignItems={'center'}>
                                  <Button type="submit" minimal icon={'eye-open'}
                                                text={'View Attached Photos'}
                                                onClick={()=>setAttachGridView(true)}
                                                />
                                  </Flexbox>
                                </Flexbox>
                                <Flexbox flex={1} >
                                    <Flexbox flex={1} alignItems={'center'} justifyContent={'flex-end'}>
                                        <DeleteTaskButton onAssignedTaskRemove={props.onAssignedTaskRemove}
                                                          assignedTaskId={assignedTask.id} currentUserRole={currentUserRole}/>
                                    </Flexbox>
                                    <Flexbox flex={1} alignItems={'center'} justifyContent={'flex-end'}>
                                        <Button type="submit" minimal
                                                text={'Update'}
                                                disabled={state !== formStates.INPUT || formik.values.quantity.toString() === assignedTask.quantity
                                                   || roleCheck(currentUserRole) !== true }
                                                loading={state === formStates.SUBMITTING}
                                                icon={state === formStates.INPUT ? 'upload' : null}>
                                            {state === formStates.SUBMITTING ? <Spinner size={1}/> : null}
                                        </Button>
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                    </Form>
                }}
            </Formik>
        </Card>
    )
}


type CustomNumberFieldProps<T> = {
    inputName?: string,
    intent?: string,
}

function CustomNumberField<T>(props: CustomNumberFieldProps<T>) {

    const {intent} = props;

    const [itemField, itemMeta] = useField(props.inputName);
    const valueChangeHandler = itemField.onChange;
    const error = itemMeta.error && itemMeta.touched ?
        <div className={styles.errorText}>{itemMeta.error}</div> : <div/>;

    const evaluateSimpleMathExpression = (value: string | number): string => {
        // leave empty strings empty
        if (typeof value === 'number') {
            value = value.toString()
        }

        if (!value) {
            return value;
        }

        // parse all terms from the expression. we allow simple addition and
        // subtraction only, so we'll split on the + and - characters and then
        // validate that each term is a number.
        const terms = value.split(/[+\-]/);

        // ex. "1 + 2 - 3 * 4" will parse on the + and - signs into
        // ["1 ", " 2 ", " 3 * 4"]. after trimming whitespace from each term
        // and coercing them to numbers, the third term will become NaN,
        // indicating that there was some illegal character present in it.
        const trimmedTerms = terms.map((term: string) => term.trim());
        const numericTerms = trimmedTerms.map((term: string) => +term);
        const illegalTerms = numericTerms.filter(isNaN);

        if (illegalTerms.length > 0) {
            return "";
        }

        // evaluate the expression now that we know it's valid
        let total = 0;

        // the regex below will match decimal numbers--optionally preceded by
        // +/- followed by any number of spaces—-including each of the
        // following:
        // ".1"
        // "  1"
        // "1.1"
        // "+ 1"
        // "-   1.1"
        const matches = value.match(/[+\-]*\s*(\.\d+|\d+(\.\d+)?)/gi) || [];
        for (const match of matches) {
            const compactedMatch = match.replace(/\s/g, "");
            total += parseFloat(compactedMatch);
        }
        const roundedTotal = roundValue(total);
        return roundedTotal.toString();
    };

    const nanStringToEmptyString = (value: string) => {
        // our evaluation logic isn't perfect, so use this as a final
        // sanitization step if the result was not a number.
        return value === "NaN" ? "" : value;
    };

    const roundValue = (value: number, precision: number = 1) => {
        // round to at most two decimal places
        return Math.round(value * 10 ** precision) / 10 ** precision;
    };

    const handleBlur = (e) => {
        handleConfirm(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === Keys.ENTER) {
            handleConfirm(e.target.value);
        }
    };

    const handleConfirm = (value: string) => {
        let result = evaluateSimpleMathExpression(value);
        result = nanStringToEmptyString(result);

        const event = {
            target: {
                name: itemField.name,
                value: result,
            }
        };
        valueChangeHandler(event)
    };

    const handleValueChange = (value, string) => {
        const event = {
            target: {
                name: itemField.name,
                value: string,
            }
        };
        valueChangeHandler(event)
    };


    return (
        <div>
            <NumericInput
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onValueChange={handleValueChange}
                intent={intent}
                large
                fill
                className={styles.numericInput}
                value={itemField.value}
            />
            {error}
        </div>
    )
}

type DeleteTaskButtonProps = {
    onAssignedTaskRemove: (assignedTask: string) => Promise<any>,
    assignedTaskId: string,
}

function DeleteTaskButton(props: DeleteTaskButtonProps) {

    const {onAssignedTaskRemove, assignedTaskId,currentUserRole} = props;

    const [isOpen, setIsOpen] = useState(false);

    return (<Popover isOpen={isOpen} onClose={() => {
        setIsOpen(false)
    }}>
        <Button icon={'delete'} text={"Remove"} minimal
            disabled={roleCheck(currentUserRole) !== true}
            onClick={() => {
                setIsOpen(true)
            }} />
        <Flexbox justifyContent={'center'}>
            <Flexbox margin={'5px'}>
                <Button icon={'cross'} text={"Cancel"} minimal
                        onClick={() => {
                            setIsOpen(false)
                        }}/>
            </Flexbox>
            <Flexbox margin={'5px'}>
                <Button icon={'confirm'} text={"Confirm"} minimal
                        onClick={() => {
                            onAssignedTaskRemove(assignedTaskId);
                            setIsOpen(false)
                        }}
                        intent={Intent.WARNING}/>
            </Flexbox>
        </Flexbox>
    </Popover>)
}
