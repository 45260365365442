// @flow

import React from 'react';
import {match} from "react-router-dom";
import styles from "./ComplianceDashboardView.module.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome, faPlusCircle, faTools, faUsers} from '@fortawesome/free-solid-svg-icons'
import {Button} from "@blueprintjs/core";
import AssignedProjectsList from "../../components/AssignedProjectsList/AssignedProjectsList";
import type Project from "../../models/Project/Project";
import DashboardView from "../DashboardView/DashboardView";

type Props = {
  //  coverPhotos: Map<string, string>,
    match: match,
    onNewProjectButtonPress?: ()=>void,
    projects: Array<Project>,
    onProjectTileClick: (projectId: string)=>void,
    onManageProjectsButtonPress?: ()=>void,
    onTeamButtonPress?: ()=>void,
    // onVendorButtonPress?: ()=>void,
    onProjectGroupsButtonPress?: ()=>void,
    onManageTasksButtonPress?: ()=>void
}

const createButton = (index: number, buttonStyle, icons: Array<FontAwesomeIcon>, label: string, onClick?: ()=>void) => {
    return (
        <div key={`${index}`} className={buttonStyle}>
            <Button onClick={onClick} fill minimal className={`${styles.gridButton}`} icon={
                <span className={`fa-layers fa-fw ${styles.faIcon}`}>
                        {icons}
                        </span>
            }>
                <div className={`bp3-ui-text ${styles.buttonLabel}`}>
                    {label}
                </div>
            </Button>
        </div>
    )
};

export default function ComplianceDashboardView(props: Props) {
    const buttons = [
        createButton(0,
            styles.projectsButton,
            [<FontAwesomeIcon icon={faHome} color="#ffffff" transform="grow-60"/>,
                <FontAwesomeIcon icon={faTools} color="#00afd7" transform="grow-5"/>],
            'Projects',
            props.onManageProjectsButtonPress),
      
        createButton(1,
                styles.newProjectsButton,
                [<FontAwesomeIcon icon={faHome} color="#ffffff" transform="grow-60"/>,
                    <FontAwesomeIcon icon={faPlusCircle} color="#43b02a" transform="grow-5"/>],
                'Project Groups',
            props.onProjectGroupsButtonPress),
        createButton(2,
            styles.teamsButton,
            [<FontAwesomeIcon icon={faUsers} color="#ffffff" transform="grow-50" />],
            'Team',
            props.onTeamButtonPress),
    ];


    return (
        <DashboardView buttons={buttons}>
            <AssignedProjectsList
              //  coverPhotos={props.coverPhotos}
              //  onProjectTileClick={props.onProjectTileClick}
                projects={props.projects}
            />
        </DashboardView>
    )
}
