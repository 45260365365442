//@Flow
import React, {useState, useEffect} from "react";
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
import Flexbox from 'flexbox-react'
import {Button, Overlay, Spinner} from "@blueprintjs/core";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-html5-camera-photo/build/css/index.css';
import styles from './FullscreenCamera.module.css'

type Props = {
    open: boolean,
    onClose: ()=>any,
    onAcceptImage: (dataUri: string)=>any
}


export default function FullscreenCamera(props) {

    const {open, onClose, onAcceptImage} = props;

    const [dataUri, setDataUri] = useState('');

    useEffect(() => {
        if (!open) setDataUri('')
    }, [open]);

    function handleTakePhotoAnimationDone(dataUri) {
        setDataUri(dataUri);
    }

    let content = (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  marginBottom: '50px',
                }}
              >
                <Camera
                  onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                  imageType={IMAGE_TYPES.JPG}
                  isImageMirror={false}
                  isFullscreen={true}
                  imageCompression={0.5}
                />
              </div>
            );

    if (dataUri) content = <ImagePreview
        onAcceptImage={onAcceptImage}
        dataUri={dataUri}
        onRejectImage={() => {
            setDataUri(null)
        }}
    />;

    return (
        <Overlay isOpen={open} onClose={onClose} canOutsideClickClose={false} canEscapeKeyClose={true}>
            <Flexbox width={'100vw'} height={'100vh'} justifyContent={'center'} alignItems={'center'}>
                <div className={styles.closeButton}>
                    <Button icon={'cross'} onClick={onClose}/>
                </div>
                {content}
            </Flexbox>
        </Overlay>
    );
}

type PreviewProps = {
    onAcceptImage: (dataUri: string)=>{},
    onRejectImage: ()=>{}
}

export function ImagePreview(props: PreviewProps) {

    const {dataUri, onRejectImage, onAcceptImage} = props;
    const [crop, setCrop] = useState({ aspect: null});
    const [image, setImage] = useState( null);
    const [result, setResult] = useState( null);
    const [cropImage, setcCropImage] = useState( true);
    const [flag, setFlag] = useState(false)
   
    function getCroppedImg() {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
      
        // New lines to be added
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
      
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
      
        // As Base64 string
        const base64Image = canvas.toDataURL("image/jpeg");
        setResult(base64Image) ;
        setcCropImage(false)
    }
    const onComplete=(crop)=>{
     crop.height !== 0 && crop.width !==0 ?setFlag(true):setFlag(false)

    }
    const imageData = result || dataUri
    return (
        <Flexbox width={'100vw'} height={'100vh'} justifyContent={'center'} alignItems={'center'}
                 flexDirection={'column'} className={styles.previewContainer}>
            <Flexbox>
                {
                    cropImage&&
                <ReactCrop className="mr-10" src={dataUri} onComplete={onComplete} onImageLoaded={setImage} crop={crop} onChange={newCrop => setCrop(newCrop)} />
                }
                             
                {
                
                 result&&
                    <img alt="cropped_Image"  src={result}/>
                }
            </Flexbox>
            <Flexbox flexDirection={'row'} className={styles.previewControls} width={'100%'} padding={'20px'}
                     justifyContent={'center'}>
                <Flexbox flex={1} justifyContent={'center'}>
                    <Button minimal text={'Cancel'} rightIcon={'reset'} large onClick={onRejectImage}/>
                </Flexbox>
                {
                    result &&
                    <Flexbox flex={1} justifyContent={'center'}>
                <Button minimal text={'undo'} rightIcon={'undo'} large 
                        onClick={()=>
                            { 
                            setcCropImage(true)
                            setResult(null)
                            }
                        }/>

                </Flexbox>
                }
                
                <Flexbox flex={1} justifyContent={'center'}>
                {
                        flag&&
                        <Button minimal text={'Crop'} rightIcon={cropImage?'tick':'widget'} large onClick={
                            getCroppedImg
                            }/>
        
                    }
                </Flexbox>
                <Flexbox flex={1} justifyContent={'center'}>
                    <Button minimal text={'Accept'} rightIcon={'tick'} large onClick={()=>{onAcceptImage(imageData)}}/>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
};

