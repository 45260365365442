import styles from "./UserManagement.module.css";

import React, { useContext, useState } from "react";
import Flexbox from "flexbox-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk, faPhone, faInbox, faUser, faMobile } from "@fortawesome/free-solid-svg-icons";
import { Card } from "@blueprintjs/core";
import UserProjectsListView from "./UserProjectsListView";
import BlocRegistry from "../../blocs/registry";
import { useEffect } from "react";
import { apiAxios } from "../../config/AxiosConfig";
import AssignedProjectsList from "../AssignedProjectsList/AssignedProjectsList";

const UserDetailsView = ({userId, projects}) => {
  const [userDetails, setUserDetails ] = useState({});
  const [userProjects, setUserProjects ] = useState([]);
  const [loading, setLoading ] = useState(false);

  const { authBloc } = useContext(
    BlocRegistry
  );

  const buildHeaders = () => {
    const accessToken = authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };

  const getUserInformation = async () => {
    try {
      const tenantId = authBloc.getCurrentTenant();
      setLoading(true);
      const response = await apiAxios.get(
        `/tenants/${tenantId}/user/${userId}`,
        buildHeaders()
      );
      setLoading(false);
      setUserDetails(response.data);

      return response;
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(projects && projects.length > 0){
      let filteredProjects =  projects.filter((project) => {
        return project.assignedUserId === userId;
      })
      setUserProjects(filteredProjects)
    }
    getUserInformation()
  }, [])

  const {email, firstName, lastName, attributes} = userDetails;
  const { officePhoneNumber, phoneNumber} = attributes ? attributes : {};
  return (
    <Flexbox alignItems={"center"} flexDirection={"column"}>
      <div style={{ width: "90vw" }}>
        <Card elevation={1} className={styles.card}>
        <Flexbox className={"bp3-text-muted"} alignItems={"center"}>
              <FontAwesomeIcon
                icon={faUser}
                color="#385988"
                style={{ margin: 10 }}
              />
             {`${firstName} ${lastName}`}
            </Flexbox>
          <Flexbox flexDirection={"row"}>
            <Flexbox flexDirection={"column"}>
              <Flexbox className={"bp3-text-muted"} alignItems={"center"}>
                <FontAwesomeIcon
                  icon={faMobile}
                  color="#385988"
                  style={{ margin: 10 }}
                />
                <a className={styles.addressText} href={`tel:${phoneNumber ? phoneNumber[0]: ""}`}>
                {phoneNumber ? phoneNumber[0]: ""}
                </a>
              </Flexbox>
              <Flexbox className={"bp3-text-muted"} alignItems={"center"}>
                <FontAwesomeIcon
                  icon={faPhone}
                  color="#385988"
                  style={{ margin: 10 }}
                />
                <a className={styles.addressText} href={`tel:${officePhoneNumber ? officePhoneNumber[0]: ""}`}>
                  {officePhoneNumber ? officePhoneNumber[0]: ""}
                </a>
              </Flexbox>
              <Flexbox className={"bp3-text-muted"} alignItems={"center"}>
                <FontAwesomeIcon
                  icon={faInbox}
                  color="#385988"
                  style={{ margin: 10 }}
                />
                <a className={styles.addressText} href={`mailto:${email}`}>
                  {email}
                </a>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Card>
        <Card elevation={1} className={styles.card}>
          <h3 style={{ color: "black" }}>Current Projects</h3>
          <AssignedProjectsList projects={userProjects} hideTitle={true}/>
        </Card>
      </div>
    </Flexbox>
  );
}

export default UserDetailsView;
