//@flow

import styles from './ProjectStatusBadge.module.css';
import React from "react";
import type { ProjectStatusesTypes } from "../../models/Project/Project";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquare,
    faCircle,
    faCheck,
    faTimes,
    faFileUpload,
    faFileSignature,
    faFile,
    faRulerCombined,
    faHandshake,
    faHome,
    faTools,
    faFileInvoice,
    faFileInvoiceDollar,
    faDollarSign,
    faFileContract,
    faThumbsUp,
    faListCheck,
    faFlag,
    faCalendarCheck
} from '@fortawesome/free-solid-svg-icons'
import Flexbox from "flexbox-react";
import { ProjectStatuses } from "../../models/Project/Project";
import { Position, Tooltip } from '@blueprintjs/core'
import hfhi_logo from './../../HFHI_logo.svg';

type Props = { status: ProjectStatusesTypes, dynamicSize?: boolean }

export default function ProjectStatusBadge(props: Props) {

    const { status, dynamicSize } = props;

    let badge;

    switch (status) {
        case ProjectStatuses.CREATED: {
            badge =
                <Tooltip content="New" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#00afd7'} transform="grow-60" />
                        <FontAwesomeIcon icon={faSquare} color={'white'} transform="grow-40" />
                        <span className={`${styles.faText} ${dynamicSize ? styles.faDynamicText : ''}`}>NEW</span>
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.APP_STARTED: {
            badge =
                <Tooltip content="Homeowner Application In Progress" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#00afd7'} transform="grow-60" />
                        <FontAwesomeIcon icon={faFileSignature} color={'white'} transform="grow-32 right-8" />
                    </Flexbox>
                </Tooltip>
                ;
            break;
        }
        case ProjectStatuses.APP_SUBMITTED: {
            badge =
                <Tooltip content="Homeowner Application Submitted For Review" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#00afd7'} transform="grow-60" />
                        <FontAwesomeIcon icon={faFileUpload} color={'white'} transform="grow-30" />
                    </Flexbox>

                </Tooltip>;
            break;
        }
        case ProjectStatuses.APP_DENIED: {
            badge =
                <Tooltip content="Homeowner Application Denied" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'red'} transform="grow-60" />
                        <FontAwesomeIcon icon={faFile} color={'white'} transform="grow-35" />
                        <FontAwesomeIcon icon={faTimes} color={'red'} transform="grow-15 down-5" />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.APP_APPROVED: {
            badge =
                <Tooltip content="Homeowner Application Approved" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#00afd7'} transform="grow-60" />
                        <FontAwesomeIcon icon={faFile} color={'white'} transform="grow-35" />
                        <FontAwesomeIcon icon={faCheck} color={'#00afd7'} transform="grow-15 down-5" />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.APP_FINISHED: {
            badge =
                <Tooltip content="Homeowner Application Finished" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#00afd7'} transform="grow-60" />
                        <FontAwesomeIcon icon={faFile} color={'white'} transform="grow-35" />
                        <FontAwesomeIcon icon={faCheck} color={'#00afd7'} transform="grow-15 down-5" />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.ER_RELEASED:
        case ProjectStatuses.ER_SUBMITTED:
        case ProjectStatuses.RISK_ASSESSMENT_SUBMITTED:
        case ProjectStatuses.SURVEY_COMPLETED: {
            badge =
                <Tooltip content="Survey Completed" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#ff6b25'} transform="grow-60" />
                        <FontAwesomeIcon icon={faRulerCombined} color={'white'} transform="grow-30" />
                        <FontAwesomeIcon icon={faCheck} color={'white'} transform="up-5 right-5" />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.SELECTION_COMMITTEE_APPROVED: {
            badge =
                <Tooltip content="Selection Committee Approved" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#ff6b25'} transform="grow-60" />
                        <FontAwesomeIcon icon={faThumbsUp} color={'white'} transform="grow-15 down-1" />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.INITIAL_ASSESSMENT: {
            badge =
                <Tooltip content="Initial Assessment Completed" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#ff6b25'} transform="grow-60" />
                        <FontAwesomeIcon icon={faListCheck} color={'white'} transform="grow-15 down-1" />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.HFHI_REPORTED: {
            badge =
                <Tooltip content="HFHI Reported" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} olor={'white'} transform="grow-60" />
                        <img className={styles.hfhi_logo} src={hfhi_logo} />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.VENDORS_SELECTED: {
            badge =
                <Tooltip content="Vendor(s) Selected" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#ff6b25'} transform="grow-60" />
                        <FontAwesomeIcon icon={faFlag} color={'white'} transform="grow-30" />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.WORK_STARTED: {
            badge =
                <Tooltip content="Work Started" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#ff6b25'} transform="grow-60" />
                        <FontAwesomeIcon icon={faHome} color={'white'} transform="grow-38" />
                        <FontAwesomeIcon icon={faTools} color={'#ff6b25'} />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.WORK_COMPLETED: {
            badge =
                <Tooltip content="Work Completed" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#43b02a'} transform="grow-60" />
                        <FontAwesomeIcon icon={faHome} color={'white'} transform="grow-35" />
                        <FontAwesomeIcon icon={faCheck} color={'#43b02a'} />
                    </Flexbox>
                </Tooltip>;
            break;
        }


        case ProjectStatuses.INVOICES_SUBMITTED: {
            badge =
                <Tooltip content="Invoices Submitted" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#00afd7'} transform="grow-60" />
                        <FontAwesomeIcon icon={faFileContract} color={'white'} transform="grow-35" />
                        {/* <FontAwesomeIcon icon={faCheck} color={'#43b02a'} /> */}
                    </Flexbox>
                </Tooltip>;
            break;
        }

        case ProjectStatuses.INVOICES_PAID: {
            badge =
                <Tooltip content="Invoices Paid" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#00afd7'} transform="grow-60" />
                        <FontAwesomeIcon icon={faDollarSign} color={'white'} transform="grow-35" />
                        {/* <FontAwesomeIcon icon={faCheck} color={'#43b02a'} /> */}
                    </Flexbox>
                </Tooltip>;
            break;
        }



        case ProjectStatuses.REIMBURSEMENT_SUBMITTED: {
            badge =
                <Tooltip content="Reimbursement Submitted" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#717D7E'} transform="grow-60" />
                        <FontAwesomeIcon icon={faFileInvoice} color={'white'} transform="grow-35" />
                        {/* <FontAwesomeIcon icon={faCheck} color={'#43b02a'} /> */}
                    </Flexbox>
                </Tooltip>;
            break;
        }

        case ProjectStatuses.REIMBURSEMENT_RECEIVED: {
            badge =
                <Tooltip content="Reimbursement Received" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#717D7E'} transform="grow-60" />
                        <FontAwesomeIcon icon={faFileInvoiceDollar} color={'white'} transform="grow-35" />
                        {/* <FontAwesomeIcon icon={faCheck} color={'#43b02a'} /> */}
                    </Flexbox>
                </Tooltip>;
            break;
        }

        case ProjectStatuses.COMPLETED: {
            badge =
                <Tooltip content="Completed" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#43b02a'} transform="grow-60" />
                        <FontAwesomeIcon icon={faCheck} color={'white'} transform="grow-35" />
                    </Flexbox>
                </Tooltip>;
            break;
        }
        case ProjectStatuses.NEW_MILESTONE1:
        case ProjectStatuses.NEW_MILESTONE2: {
            badge =
                <Tooltip content="Custom Milestone" position={Position.LEFT}>
                    <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                        justifyContent={'center'}>
                        <FontAwesomeIcon icon={faCircle} color={'#385988'} transform="grow-63" />
                        <FontAwesomeIcon icon={faCircle} color={'#ff6b25'} transform="grow-60" />
                        <FontAwesomeIcon icon={faCalendarCheck} color={'white'} transform="grow-35" />
                    </Flexbox>
                </Tooltip>;
            break;
        }

        default: {
            badge =
                <Flexbox className={`fa-layers fa-fw ${styles.faIconContainer}`} alignItems={'center'}
                    justifyContent={'center'}>
                    <span className={`${styles.faText} ${dynamicSize ? styles.faDynamicText : ''}`}>UNKOWN</span>
                </Flexbox>;
        }
    }

    return badge
}
