export const GlobalConstanst = {
  tableConfig: {
    page_size: 50,
    page_size_array: [50,100,500,1000],
  },
  FILE_SIZE_MAXIMUM: 1000000,
  FILE_SIZE_MEDIUM: 500000,
  FILE_SIZE_LOW: 100000,
  projectStatus: [
    "NONE",
    "CREATED",
    "APP_STARTED",
    "APP_SUBMITTED",
    "APP_FINISHED",
    "SURVEY_COMPLETED",
    "ER_SUBMITTED",
    "ER_RELEASED",
    "RISK_ASSESSMENT_SUBMITTED",
    "VENDORS_SELECTED",
    "WORK_STARTED",
    "WORK_COMPLETED",
    "WORK_INSPECTED",
    "FINAL_INSPECTION",
    "INVOICES_SUBMITTED",
    "INVOICES_PAID",
    "REIMBURSEMENT_SUBMITTED",
    "REIMBURSEMENT_RECEIVED",
    "COMPLETED",
    "SELECTION_COMMITTEE_APPROVED",
    "INITIAL_ASSESSMENT",
    "HFHI_REPORTED"
  ],
};

export const AVAILABLE_ROLES = {
  PROJECT_MANAGER: "project_manager",
  TENANT_ADMIN: "tenant_admin",
  ACCOUNTING_MANAGER: "account_manager",
  COMPLAINCE_AUDIATOR: "compliance_audiator",
  PROJECT_ADMIN: "project_admin",
  MARKETING_MANAGER: "marketing",
}

export const AVAILABLE_GROUPS = {
  PROJECT_MANAGERS: "project_managers",
  TENANT_ADMINS: "tenant_admins",
  ACCOUNTING_MANAGERS: "account_managers",
  COMPLAINCE_AUDIATORS: "compliance_audiators",
  PROJECT_ADMINS: "project_admins",
  MARKETING_MANAGERS: "marketing",
}

export const HOMEOWNER_APP_SECTIONS = {
  APP_INFORMATION: "showApplicantInformation",
  CLIENT_AS_SDA: "showDocumentClientAsSDA",
  COMPLIENCE_WITH_LSHR: "showCertifyComplianceWithLSHR",
  CONFLICT_OF_INTEREST: "showConflictOfInterestDisclosure",
  HOMEOWNER_AGREEMENT: "showHomeownerAgreement"
}
