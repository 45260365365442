import React, { useState, useEffect } from "react";
import Flexbox from "flexbox-react";
import {
  Card,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import styles from "./AssignedTaskQuantityEditor.module.css";
import "react-tiny-fab/dist/styles.css";
import { GetAttachmentResponse } from "../../models/Attachment/Attachment";
import { Icon } from "@blueprintjs/core";

import "react-image-picker/dist/index.css";

type ImageTileProps = {
  project: ?Project,
  attachmentResponse: GetAttachmentResponse,
  src: string,
  index: number,
  id: string,
  onPhotoAttachTask: any,
};

export function ImageTile(props: ImageTileProps) {
  const { src, index, attachmentResponse, id, disabledSelect, photoIds } = props;
  const [image, setImage] = useState(null);
  const [isOpen, setIsOpen] = useState(photoIds ? photoIds.includes(id) :  false);

  useEffect(() => {
    if (src) {
      const newImage = new Image();
      newImage.onload = () => {
        setImage(newImage);
      };
      newImage.onerror = () => {
        newImage.src = "/errorMessage.png";
      };
      newImage.src = src;
    }
  }, [src]);

  return (
    <Flexbox
      key={index}
      height={"100%"}
      width={"100%"}
      justifyContent={"center"}
      flexDirection={"column"}
      className={styles.noOverflow}
    >
      <Flexbox margin={"0 auto"} className={styles.noOverflow}>
        {image ? (
          <Card
            className={
              isOpen && image
                ? styles.attachmentTileSelect
                : styles.attachmentTile
            }
            interactive
            elevation={0}
            onClick={() => {
              if (image && !disabledSelect) {
                setIsOpen(!isOpen);
                !isOpen
                  ? props.onPhotoAttachTask(id, "push")
                  : props.onPhotoAttachTask(id, "pop");
              }
            }}
          >
            {isOpen && image && (
              <Flexbox padding={0} margin={0} justifyContent={"flex-end"}>
                <Icon
                  icon="confirm"
                  IconSize={2}
                  className={styles.iconColor}
                />
              </Flexbox>
            )}
            <Flexbox
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              width={"100%"}
            >
              <img src={image.src} />
              {attachmentResponse.title && (
                <Tag className={styles.titleTag}>
                  {attachmentResponse.title.replace("C_", " ").trim()}
                </Tag>
              )}
            </Flexbox>
          </Card>
        ) : (
          <Spinner />
        )}
      </Flexbox>
    </Flexbox>
  );
}
