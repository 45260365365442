// @flow

import React, { useState, useEffect, useContext } from "react";
import styles from "./ManageProjectsView.module.css";
import { Column, Table, TableLoadingOption } from "@blueprintjs/table";
import Flexbox from "flexbox-react";
import {
  Button,
  Intent,
  Alert,
  Overlay,
  Checkbox,
  Text,
  Spinner,
  Label,
  Switch,
} from "@blueprintjs/core";
import ProjectTable from "../../components/ProjectTable/ProjectTable";
import DeleteConfirmationPopup from "../../components/ConfirmationAlert/ConfirmationAlert";
import Project, { ProjectStates } from "../../models/Project/Project";
import User from "../../models/User/User";
import BlocRegistry from "../../blocs/registry";
import { RefreshProjectsEvent, CloneProjectEvent } from "../../blocs/projectBloc";
import ProjectGroup from "../../models/ProjectGroup/ProjectGroup";
import { AuthRoles } from "../../blocs/authBloc";
import _ from "lodash";
import history from "../../history";
import { AppToaster } from "../../components/Toaster/Toaster";

type Props = {
  projects: ?Array<Project>,
  projectManagers: ?Array<User>,
  onAddProjectButtonClick: () => any,
  onProjectDetailsButtonClick: (projectId: string) => any,
  onEditProjectButtonClick: (projectId: string) => any,
  onDeleteProjectButtonClick: (projectId: string) => any,
  onCloneProjectButtonClick: (projectId: string) => any,
  onProjectDeleted: (type: String, message: string) => any,
  projectGroups:?Array<ProjectGroup>,
  currentUserRole: String,
  resetProjectCloneFlag: () => any,
};

export default function ManageProjectsView(props: Props) {
  const { projectBloc } = useContext(BlocRegistry);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let paramsString =  params.state;
  let statesData = [];
  if(paramsString){
    statesData = paramsString.split(",");
  }
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [showCloneConfirmationPopup, setCloneConfirmationPopup] =
    useState(false);
  const [projectId, setProjectId] = useState(null);
  const [isOpen, setOpen] = useState(statesData.includes(ProjectStates.OPEN) ? true : false);
  const [isClosed, setClosed] = useState(statesData.includes(ProjectStates.CLOSED) ? true : false);
  const [isRejected, setRejected] = useState(statesData.includes(ProjectStates.REJECTED) ? true : false);
  const [filterDisabled, setFilterDisabled] = useState(false);
  const [projects, setProjects] = useState([])
  const [projectAddress, setProjectAddress] = useState("");
  const [confirmPressed, setConfirmPressed] = useState(false);
  const [progressBarSteps, setProgressBarSteps] = useState(0);

  const {
    projectManagers,
    onAddProjectButtonClick,
    onProjectDetailsButtonClick,
    onEditProjectButtonClick,
    onDeleteProjectButtonClick,
    onCloneProjectButtonClick,
    onProjectDeleted,
    projectGroups,
    currentUserRole,
    resetProjectCloneFlag
  } = props;
  useEffect(()=>{
    if (currentUserRole == AuthRoles.COMPLIANCE_AUDITOR) {
      var currentUserData = _.find(props.users, {
        id: props?.currentUser?.sub,
      });
      let assignedProjectGroupIds = currentUserData?.attributes?.projectGroupIds
      let projectsData = [] 
      assignedProjectGroupIds && assignedProjectGroupIds.forEach(projectGroupId => {
        let projectsAccordingToProjectGroups = _.filter(props.projects,
          {
            projectGroups: [{id: projectGroupId}]
          }
        );
        projectsData = [...projectsData, ...projectsAccordingToProjectGroups]
      })
      setProjects(projectsData)
    } else{
      setProjects(props.projects)
    }
  }, [props.projects, props.currentUser])

  useEffect(()=>{
    if (!props.isCloneDone) {
      setCloneConfirmationPopup(props?.isCloneDone);
      cloneConfirmPopup = null;
      setProgressBarSteps(0);
      AppToaster.show({
        message: "Something Went Wrong",
        intent: Intent.DANGER,
      });
      resetProjectCloneFlag();
    }
  }, [props.isCloneDone])

  let list = (
    <Table
      numRows={10}
      loadingOptions={[
        TableLoadingOption.CELLS,
        TableLoadingOption.ROW_HEADERS,
      ]}
    >
      <Column name="Homeowner" />
      <Column name="Home Address" />
      <Column name="Status" />
      <Column name="Assigned Project Manager" />
    </Table>
  );

  if (projects && projectManagers) {
    if (Project.length === 0 || projects.length === 0)
      list = (
        <div
          className={`bp3-ui-text bp3-text-disabled ${styles.noProjectsText}`}
        >
          Projects Not Found
        </div>
      );
    else {
      list = (
        <ProjectTable
          projects={projects}
          projectManagers={projectManagers}
          onProjectDetailsButtonClick={onProjectDetailsButtonClick}
          onEditProjectButtonClick={onEditProjectButtonClick}
          onDeleteProjectButtonClick={async (projectId) => {
            setConfirmationPopup(true);
            setProjectId(projectId);
          }}
          onCloneProjectButtonClick={async (projectId) => {
            setCloneConfirmationPopup(true);
            setProjectId(projectId);
            projects.map((project) => {
              if (project.id == projectId) {
                setProjectAddress(project.home.address.streetAddress);
              }
            });
          }}
          projectGroups={projectGroups}
          currentUserRole={currentUserRole}
        />
      );
    }
  }
  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      // setConfirmationPopup={setConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      icon="trash"
      type={Intent.DANGER}
      onCancel={() => setConfirmationPopup(false)}
      onConfirm={async () => {
        const deleteRespons = await onDeleteProjectButtonClick(projectId);
        setConfirmationPopup(false);
        setTimeout(() => {
          onProjectDeleted(Intent.PRIMARY, "Project Deleted");
        }, 500);
      }}
      message="Are you sure you want to delete project?"
    />
  );
  let cloneConfirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showCloneConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Clone"
      icon="duplicate"
      type={Intent.PRIMARY}
      onCancel={() => setCloneConfirmationPopup(false)}
      onConfirm={async () => {
        projectBloc.sendEvent(
          new CloneProjectEvent(projectId)
        );
        setConfirmPressed(true);
      }}
      message={`Are you sure you want to clone the project "${projectAddress}"?  `}
      message2="Homeowner, Attachments, Environment Review and Photos will get cloned."
      confirmPressed={confirmPressed}
      showProgressBar={true}
      progressBarSteps={progressBarSteps}
    />
  );

  let selectedFilter = [];

  useEffect(() => {
    if (isOpen) selectedFilter.push(ProjectStates.OPEN);

    if (isClosed) selectedFilter.push(ProjectStates.CLOSED);

    if (isRejected) selectedFilter.push(ProjectStates.REJECTED);

    var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + '?state=' + `${selectedFilter.toString()}`;    
    window.history.pushState({ path: refresh }, "", refresh);

    projectBloc.sendEvent(
      new RefreshProjectsEvent(JSON.stringify(selectedFilter))
    );

    setFilterDisabled(true);

    setTimeout(() => {
    setFilterDisabled(false)
    },3000)

  }, [isOpen, isClosed, isRejected]);

  let filter;

  if (projects && projectManagers) {
    filter = (
      <>
        <Flexbox
          flex={1}
          flexDirection={"row"}
          width={"20%"}
          // padding={"10px"}
          marginTop={"10px"}
          // marginBottom={"-40px"}

        >
          <Flexbox flex={1} style={{marginRight: 10}}>
            <Checkbox 
              large
              labelElement={<strong>Open</strong>}
              checked={isOpen}
              onChange={() => {
                setOpen(!isOpen);
              }}
            />
          </Flexbox>
          <Flexbox flex={1} style={{marginRight: 10}}>
            <Checkbox
              large
              labelElement={<strong>Closed</strong>}
              checked={isClosed}
              onChange={() => {
                setClosed(!isClosed);
              }}
            />
          </Flexbox>
          <Flexbox flex={1} style={{marginRight: 10}}>
            <Checkbox
              large
              labelElement={<strong>Rejected</strong>}
              checked={isRejected}
              onChange={() => {
                setRejected(!isRejected);
              }}
            />
          </Flexbox>
        </Flexbox>
      </>
    );
  }
  const addProjectBtton = () => {
    if (currentUserRole !== AuthRoles.COMPLIANCE_AUDITOR && currentUserRole !== AuthRoles.MARKETING_MANAGER) {
      return (
        <Button
          text={"Add Project"}
          icon={"plus"}
          loading={!projects}
          onClick={onAddProjectButtonClick}
          minimal
        />
      )
    }
  }
  return (
    <>
    {filterDisabled ? <Spinner/>:
      <div className={styles.manageTasksView} id="mainHeader">
      <h5>Projects</h5>
      <Flexbox justifyContent={"flex-start"}>
      {addProjectBtton()}
      </Flexbox>
      {filter}
      {list}
      {confirmPopup}
      {cloneConfirmPopup}
    </div>
    }
  
    </>
  );
}
