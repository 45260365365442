//@flow

import React, {useContext, useEffect, useState} from "react";
import HomeownerApplicationForm from "../HomeownerApplicationForm/HomeownerApplicationForm";
import type {Subscription} from "rxjs";
import BlocRegistry from "../../blocs/registry";
import {
    FetchHomeownerApplicationForProjectEvent,
    HomeownerApplicationOutEventTypesEnum, UpdateHomeownerApplicationForProjectEvent
} from "../../blocs/homeownerApplicationBloc";
import type {HomeownerApplicationOutEventTypes} from "../../blocs/homeownerApplicationBloc";
import {Button, Spinner, Intent} from "@blueprintjs/core";
import Flexbox from 'flexbox-react'

type Props = {
    projectId: string,
    project?:any,
    onHomeownerApplicationUpdated: ()=>any,
    onHomeownerApplicationReadyForReview: ()=>any,
    onHomeownerApplicationRejected: ()=>any,
    onHomeownerApplicationApproved: ()=>any,
    brand:string
}

export default function HomeownerApplicationManager(props: Props) {

    const {homeownerApplicationBloc} = useContext(BlocRegistry);

    const {projectId,project, onHomeownerApplicationUpdated, onHomeownerApplicationReadyForReview, onHomeownerApplicationRejected, onHomeownerApplicationApproved,brand} = props;

    const [lastBlocEvent, setLastBlocEvent] = useState(null);
    const [homeownerApplication, setHomeownerApplication] = useState(null);

    useEffect(() => {
        const subscription: Subscription = homeownerApplicationBloc.subscribeToHomeownerApplicationContext({
            next(event: HomeownerApplicationOutEventTypes) {
                setLastBlocEvent(event);
            },
            error(err: Error) {
                throw err;
            }
        });
        return () => {
            subscription.unsubscribe();
        }
    }, [homeownerApplicationBloc]);

    useEffect(() => {
        if (!lastBlocEvent) return;
        switch (lastBlocEvent.type) {
            case HomeownerApplicationOutEventTypesEnum.FETCHED: {
                setHomeownerApplication(lastBlocEvent.homeownerApplication);
                break;
            }
            case HomeownerApplicationOutEventTypesEnum.UPDATED: {
                setHomeownerApplication(lastBlocEvent.updatedHomeownerApplication);
                break;
            }
            default: {
                console.error("Unknown homeowner application out event type: " + lastBlocEvent.type)
            }
        }
    }, [lastBlocEvent]);

    useEffect(() => {
        if (!projectId) return;
        homeownerApplicationBloc.sendEvent(new FetchHomeownerApplicationForProjectEvent(props.projectId));
    }, [projectId]);


    if (!homeownerApplication) {
        if (lastBlocEvent && lastBlocEvent.type === HomeownerApplicationOutEventTypesEnum.FETCHED) {
            return <Flexbox flexGrow={1} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
                <Button intent={Intent.PRIMARY} large text={'Start Application'} onClick={() => {
                    homeownerApplicationBloc.sendEvent(
                        new UpdateHomeownerApplicationForProjectEvent(projectId,null,
                            {projectId: projectId, applicationData: {}, status: "CREATED"}))
                    onHomeownerApplicationUpdated();
                }}/></Flexbox>
        } else {
            return <Spinner/>
        }
    }
    return (<HomeownerApplicationForm
        project={project}
        onHomeownerApplicationUpdated={onHomeownerApplicationUpdated}
        onHomeownerApplicationReadyForReview={onHomeownerApplicationReadyForReview}
        onHomeownerApplicationApproved={onHomeownerApplicationApproved}
        onHomeownerApplicationRejected={onHomeownerApplicationRejected}
        onHomeownerApplicationUpdate={(homeownerApplication, logRefFile) => {
            homeownerApplicationBloc.sendEvent(new UpdateHomeownerApplicationForProjectEvent(props.projectId, logRefFile, 
                homeownerApplication));
        }}
        application={homeownerApplication}
        onHomeownerApplicationLogsAdded={()=>{
        homeownerApplicationBloc.sendEvent(new FetchHomeownerApplicationForProjectEvent(props.projectId));

        }}
        currentUserRole={props.currentUserRole}
        brand={brand}
    />)
}
