// @flow

import React from 'react';
import {match} from "react-router-dom";
import styles from "./AdminDashboardView.module.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome, faPlusCircle, faTools, faUsers, faBuilding} from '@fortawesome/free-solid-svg-icons'
import {Button} from "@blueprintjs/core";
import DashboardView from "../DashboardView/DashboardView";

type Props = {
    match: match,
    onNewProjectButtonPress?: ()=>void,
    onManageTasksButtonPress?: ()=>void,
    onManageProjectsButtonPress?: ()=>void,
    onTeamButtonPress?: ()=>void,
    onProjectGroupsButtonPress?: ()=>void,
    onVendorButtuonPress?: ()=>void,
    onBrandButtonPress?: ()=>void,
}

const createButton = (index: number, containerStyle, icons: Array<FontAwesomeIcon>, label: string, onClick?: ()=>void) => {

    const disabled = !onClick;
    let style = ``;
    if(disabled) style = 'bp3-disabled';

    return (
        <div key={`${index}`} className={containerStyle}>
            <Button disabled={disabled} onClick={onClick} fill minimal className={`${styles.gridButton} ${style}`} icon={
                <span className={`fa-layers fa-fw ${styles.faIcon}`}>
                        {icons}
                        </span>
            }>
                <div className={`bp3-ui-text ${styles.buttonLabel}`}>
                    {label}
                </div>
            </Button>
        </div>
    )
};

export default function AdminDashboardView(props: Props) {
    const buttons = [
      createButton(
        0,
        styles.projectsButton,
        [
          <FontAwesomeIcon icon={faHome} color="#ffffff" transform="grow-60" />,
          <FontAwesomeIcon icon={faTools} color="#00afd7" transform="grow-5" />,
        ],
        "Projects",
        props.onManageProjectsButtonPress
      ),
      createButton(
        1,
        styles.newProjectsButton,
        [
          <FontAwesomeIcon icon={faHome} color="#ffffff" transform="grow-60" />,
          <FontAwesomeIcon
            icon={faPlusCircle}
            color="#43b02a"
            transform="grow-5"
          />,
        ],
        "New Project",
        props.onNewProjectButtonPress
      ),
      createButton(
        2,
        styles.vendorsButton,
        [
          <FontAwesomeIcon
            icon={faTools}
            color="#ffffff"
            transform="grow-50"
          />,
        ],
        "Contacts",
        props.onVendorButtuonPress
      ),
      createButton(
        3,
        styles.teamsButton,
        [
          <FontAwesomeIcon
            icon={faUsers}
            color="#ffffff"
            transform="grow-50"
          />,
        ],
        "Team",
        props.onTeamButtonPress
      ),
      createButton(
        4,
        styles.tasksButton,
        [
          <FontAwesomeIcon icon={faHome} color="#ffffff" transform="grow-60" />,
          <FontAwesomeIcon icon={faTools} color="#43b02a" transform="grow-5" />,
        ],
        "Tasks & Pricing",
        props.onManageTasksButtonPress
      ),

      createButton(
        5,
        styles.projectGroupsButton,
        [
          <FontAwesomeIcon icon={faHome} color="#ffffff" transform="grow-60" />,
          <FontAwesomeIcon
            icon={faPlusCircle}
            color="#00afd7"
            transform="grow-5"
          />,
        ],
        "Project Groups",
        props.onProjectGroupsButtonPress
      ),
      createButton(
        6,
        styles.brandsButton,
        [
          <FontAwesomeIcon
            icon={faBuilding}
            color="#ffffff"
            transform="grow-60"
          />,
         
        ],
        "Brand Management",
        props.onBrandButtonPress
      ),
    ];

    return (
        <DashboardView buttons={buttons}>
        </DashboardView>
    )
}
