import { GlobalConstanst } from "./Constant";
import Project from "../models/Project/Project";
import Task from "../models/Task/Task";

export const GetPaginatedData = (
  dataArray = [],
  page_number = 1,
  page_size = GlobalConstanst.tableConfig.page_size
) => {
  if (page_size >= dataArray.length) return dataArray;
  return dataArray.slice(
    (page_number - 1) * page_size,
    page_number * page_size
  );
};

export const handleSearch = (
  query: string,
  originalProjects: Array<Project>
) => {
  let { value } = query.target;
  value = value.toLowerCase();

  if (value === "") {
    return originalProjects;
  }
  let searchResult = originalProjects.filter((project) => {
    const { address, homeowner } = project.home;

    let addressString = `${address.streetAddress}${address.streetAddressTwo ? `${address.streetAddressTwo}` : ""
    }${address.city} ${address.state}${address.zip}`;
    let homeownerString = `${homeowner.firstName} ${homeowner.lastName}`;

    let res =
      homeownerString.toLowerCase().includes(value) ||
      addressString.toLowerCase().includes(value);
    return res;
  });
  return searchResult;
};

export const handleGroupsSearch = (
  query: string,
  originalProjects: Array<Project>
) => {
  let { value } = query.target;
  value = value.toLowerCase();

  if (value === "") {
    return originalProjects;
  }
  let searchResult = originalProjects.filter((project) => {

    let addressString = `${project.grantName}${project.name}`;

    let res = addressString.toLowerCase().includes(value);
    return res;
  });
  return searchResult;
};

export const handleTaskSearch = (
  query: string,
  originalTasks: Array<Task>
) => {
  let { value } = query.target;
  value = value.toLowerCase();

  if (value === "") {
    return originalTasks;
  }
  let searchResult = originalTasks.filter((task) => {
    const { title, unit,price } = task;

    
    let titleString = `${title}`;
    let unitString = `${unit}`;
    let priceString = `${price}`;

    let res =
    titleString.toLowerCase().includes(value) ||
    unitString.toLowerCase().includes(value)|| priceString.toLowerCase().includes(value);
    return res;
  });
  return searchResult;
};

export const handleContactSearch = (
  query: string,
  originalContacts: Array<any>
) => {
  let { value } = query.target;
  value = value.toLowerCase();

  if (value === "") {
    return originalContacts;
  }
  let searchResult = originalContacts.filter((contact) => {
    const { name, email } = contact;
    let res =
      name.toLowerCase().includes(value) ||
      email.toLowerCase().includes(value);

    return res;
  });
  return searchResult;
};

export const handlePhotosSearch = (
  query: string,
  originalPhotos: Array<any>
) => {
  let { value } = query.target;
  value = value.toLowerCase();

  if (value === "") {
    return originalPhotos;
  }
  let searchResult = originalPhotos.filter((photo) => {
    const { tag, title, description } = photo;
    let res =
    tag?.toLowerCase().includes(value) ||
    title?.toLowerCase().includes(value)||
    description?.toLowerCase().includes(value);

    return res;
  });
  return searchResult;
};

export const formatNumberWithCommas = (number) => {
  // console.log("price formatting", number);
  if (typeof number !== "number" || isNaN(number)) {
    return number;
  }

  // Use toLocaleString en-US to add commas in US format
  return number.toLocaleString("en-US");
};
