// @flow

import type {AuthBloc} from "../../blocs/authBloc";
import {apiAxios} from '../../config/AxiosConfig'
import type Attachment, {AttachmentTypes} from "../../models/Attachment/Attachment";
import {
    AttachmentTypeEnum,
    CreateAttachmentRequest,
    GetAttachmentResponse,
    PostAttachmentResponse
} from "../../models/Attachment/Attachment";


class AttachmentStorageApi {

    async putAttachmentPayload(url: string,
                               createAttachmentRequest: CreateAttachmentRequest,
                               attachmentType: AttachmentTypes,): Promise<void> {
        if (attachmentType === AttachmentTypeEnum.image) {

            const file = createAttachmentRequest.payload;
            const type = file.split(';')[1].split(',')[0];
            const buffer = Buffer.from(file.replace(/^data:[a-zA-Z0\-\/]*\/\w+;base64,/, ""), 'base64');

            try {
                return await apiAxios.put(url, buffer, {
                    headers: {
                        'Content-Type': createAttachmentRequest.fileType,
                        'Content-Encoding': type
                    },
                });
            } catch (error) {}
        } else if (attachmentType === AttachmentTypeEnum.file) {
            let buffer, type;

            const file = createAttachmentRequest.payload;
            type = file.split(';')[1].split(',')[0];

            const headers: Object = {
                'Content-Type': createAttachmentRequest.fileType,
            };

            if (createAttachmentRequest.contentType) headers['Content-Encoding'] = createAttachmentRequest.contentType;

            try {
                return await apiAxios.put(url, file, {
                    headers: {
                        'Content-Type': createAttachmentRequest.fileType,
                        'Content-Encoding': type
                    },
                });
            } catch (error) {
                throw new error;
            }
        }

        else if (attachmentType === AttachmentTypeEnum.invoice) {
            let buffer, type;

            const file = createAttachmentRequest.attachment.payload;
            type = file.split(';')[1].split(',')[0];

            const headers: Object = {
                'Content-Type': createAttachmentRequest.fileType,
            };

            if (createAttachmentRequest.contentType) headers['Content-Encoding'] = createAttachmentRequest.contentType;

            try {
                return await apiAxios.put(url, file, {
                    headers: {
                        'Content-Type': createAttachmentRequest.fileType,
                        'Content-Encoding': type
                    },
                });
            } catch (error) {
                throw new error;
            }
        }   
        else if (attachmentType === AttachmentTypeEnum.vendor) {
            let buffer, type;

            const file = createAttachmentRequest.attachment.payload;
            type = file.split(';')[1].split(',')[0];
            const headers: Object = {
                'Content-Type': createAttachmentRequest.fileType,
            };

            if (createAttachmentRequest.contentType) headers['Content-Encoding'] = createAttachmentRequest.contentType;

            try {
                return await apiAxios.put(url, file, {
                    headers: {
                        'Content-Type': createAttachmentRequest.fileType,
                        'Content-Encoding': type
                    },
                });
            } catch (error) {
                throw new error;
            }
        }   
    }
}

export default AttachmentStorageApi;
