import React, { useState } from "react";
import { Table, SelectionModes, Column, Cell } from "@blueprintjs/table";
import {
    Icon,
    Menu,
    MenuItem,
    Intent,
    Classes,
    Button,
    InputGroup,
    H5,
    Spinner,
  } from "@blueprintjs/core";
  import Flexbox from "flexbox-react";
import { TextSortableColumn } from "../../components/Table/TextSortableColumn/TextSortableColumn";
import DeleteConfirmationPopup from "../../components/ConfirmationAlert/ConfirmationAlert";
import { AuthRole, AuthRoles } from "../../blocs/authBloc";

const TeamView = (props: Props) => {
  const [showConfirmationPopup, setConfirmationPopup] = useState(null);
  const [columns, setColumns] = useState([
    new TextSortableColumn("", 0, "controls", () => {
        return 0;
      }),
    new TextSortableColumn("Name", 1, "name", () => {
      return 0;
    }),
    new TextSortableColumn("Email", 2, "email"),
    new TextSortableColumn("Username", 3, "username", undefined, {
      truncate: false,
      wrapText: true
    }),
  ]);
  const [button, setButton] = useState();

  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup ? true : false}
      // setConfirmationPopup={setConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      icon="trash"
      type={Intent.DANGER}
      onCancel={() => setConfirmationPopup(null)}
      onConfirm={async () => {
        props.deleteUser(showConfirmationPopup);
        setConfirmationPopup(null);
      }}
      message="Are you sure you want to delete this user?"
    />
  );
      const renderRoleVise = (rowIndex) =>{
        if(props.currentUserRole === AuthRoles.ADMIN){
          return(<>
          <MenuItem
          text="Edit"
          icon={"edit"}
          intent={Intent.PRIMARY}
          onClick={() => {
            props.navigateToUserUpdate(props.users[rowIndex].id)
          }}
        />
        <MenuItem
          text="Delete"
          icon={"trash"}
          intent={Intent.DANGER}
          onClick={() => {
            setConfirmationPopup(props.users[rowIndex].id);
          }}
        />
          
          </>)
        }
      }
  const controlsRenderer = (rowIndex: number) => {
    return (
      <Menu className={Classes.ELEVATION_2}>
        <MenuItem
          text="Details"
          icon={"grid-view"}
          intent={Intent.PRIMARY}
          onClick={()=>{
            props.onUserDetailsButtonClick(props.users[rowIndex].id)
          }}
        />
        {renderRoleVise(rowIndex)}
      </Menu>
    );
  };

  const nameRenderer = (rowIndex: number) => {
    if (props.users[rowIndex]) {
      return `${props.users[rowIndex].firstName} ${props.users[rowIndex].lastName}`;
    } else {
      return "Not Found";
    }
  };

  const emailRenderer = (rowIndex: number) => {
    if (props.users[rowIndex]) {
      return props.users[rowIndex].email;
    } else {
      return "Not Found";
    }
  };


  const userNameRenderer = (rowIndex: number) => {
    if (props.users[rowIndex]) {
      return props.users[rowIndex].username;
    } else {
      return "Not Found";
    }
  };

  const cellRenderers = {
    name: nameRenderer,
    email: emailRenderer,
    username: userNameRenderer,
    controls: controlsRenderer,
  };

  const getCellData = (rowIndex: number, columnIndex: number) => {
    return cellRenderers[columns[columnIndex].modelField](rowIndex);
  };


  const renderedColumns = columns.map((column) => {
        return column.getColumn(getCellData, null);
  });

  const Tbutton = () =>{
    if(props.currentUserRole === AuthRoles.ADMIN){
      return(
        <Button
          text={"Add Team Member"}
          icon={"plus"}
          // loading={!projects}
          onClick={props.onAddUserButtonClick}
          minimal
        />
      )
    }
  }
    
  
  return (
    <div style={{ width: "100%" }}>
      {confirmPopup}
      <Flexbox justifyContent={"flex-start"} style={{padding : 15}}>
      {Tbutton()}
      </Flexbox>
      <Table
        defaultRowHeight={120}
        selectionModes={SelectionModes.COLUMNS_AND_CELLS}
        columnWidths={[,,,270,]}
        numRows={props.users.length}
      >
        {renderedColumns}
      </Table>
    </div>
  );
};

type Props = {
  users?: Array,
};

export default TeamView;
