//@Flow
import {PartialObserver, Subject} from 'rxjs'
import AssignedTask, {CreateAssignedTaskRequest, UpdateAssignedTaskRequest} from "../models/AssignedTask/AssignedTask";
import AssignedTaskService from "../service/AssignedTaskService/AssignedTaskService";
import React from "react";

export const AssignedTaskEventTypesEnum = Object.freeze({
    ASSIGN_TASK: Symbol("ASSIGN TASK"),
    UPDATE_TASK: Symbol("UPDATE TASK"),
    DELETE_TASK: Symbol("DELETE TASK"),
    FETCH_TASKS_FOR_PROJECT: Symbol("FETCH TASKS FOR PROJECT"),
});

export type AssignedTaskEventTypes = $Keys<typeof AssignedTaskEventTypesEnum>;

export class AssignedTaskEvent {
    type: AssignedTaskEventTypes;

    constructor(type: AssignedTaskEventTypes) {
        this.type = type;
    }
}

export class FetchTasksForProjectEvent extends AssignedTaskEvent {

    projectId: string;

    constructor(projectId: string) {
        super(AssignedTaskEventTypesEnum.FETCH_TASKS_FOR_PROJECT);
        this.projectId = projectId;
    }
}

export class CreateAssignedTaskForProjectEvent extends AssignedTaskEvent {

    request: CreateAssignedTaskRequest;

    constructor(createAssignedTaskRequest: CreateAssignedTaskRequest) {
        super(AssignedTaskEventTypesEnum.ASSIGN_TASK);
        this.request = createAssignedTaskRequest;
    }
}

export class UpdateAssignedTaskForProjectEvent extends AssignedTaskEvent {

    request: UpdateAssignedTaskRequest;
    projectId: string;
    assignedTask: AssignedTask;

    constructor(projectId: string, assignedTask: AssignedTask, updateAssignedTaskRequest: UpdateAssignedTaskRequest) {
        super(AssignedTaskEventTypesEnum.UPDATE_TASK);
        this.request = updateAssignedTaskRequest;
        this.projectId = projectId;
        this.assignedTask = assignedTask;
    }
}

export class DeleteAssignedTaskForProjectEvent extends AssignedTaskEvent {

    projectId: string;
    assignedTaskId: string;

    constructor(projectId: string, assignedTaskId: string) {
        super(AssignedTaskEventTypesEnum.DELETE_TASK);
        this.projectId = projectId;
        this.assignedTaskId = assignedTaskId;
    }
}

export class AssignedTaskBloc {
    _outAssignedTaskStream: Subject = new Subject();

    subscribeToAssignedTaskContext(observer?: PartialObserver<AssignedTaskOutEvent>) {
        return this._outAssignedTaskStream.subscribe(observer);
    }

    _eventController: Subject = new Subject();

    sendEvent(event: AssignedTaskEvent) {
        return this._eventController.next(event)
    }

    _assignedTaskService: AssignedTaskService;

    constructor(assignedTaskService: AssignedTaskService) {
        this._assignedTaskService = assignedTaskService;
        this._eventController.subscribe(this.buildEventHandler())
    }

    buildEventHandler = () => {
        return {
            next: async (event: AssignedTaskEvent) => {
                switch (event.type) {
                    case AssignedTaskEventTypesEnum.FETCH_TASKS_FOR_PROJECT: {
                        const projectId = (event: FetchTasksForProjectEvent).projectId;
                        const assignedTasks = await this._assignedTaskService.getAssignedTasksForProject(projectId);
                        this._outAssignedTaskStream.next(new FetchTasksForProjectOutEvent(projectId, assignedTasks));
                        break;
                    }
                    case AssignedTaskEventTypesEnum.ASSIGN_TASK: {
                        const projectId = (event: FetchTasksForProjectEvent).request.projectId;
                        const assignedTask = await this._assignedTaskService.createAssignedTask(projectId, (event: FetchTasksForProjectEvent).request);
                        this._outAssignedTaskStream.next(new AddTasksForProjectOutEvent(projectId, [assignedTask]));
                        break;
                    }
                    case AssignedTaskEventTypesEnum.UPDATE_TASK: {
                        const {projectId, assignedTask} = (event: UpdateAssignedTaskForProjectEvent);
                        const updatedAssignedTask = await this._assignedTaskService.updateAssignedTask(projectId, assignedTask, (event: UpdateAssignedTaskForProjectEvent).request);
                        this._outAssignedTaskStream.next(new UpdateTasksForProjectOutEvent(projectId, [updatedAssignedTask]));

                        
                        const assignedTasks = await this._assignedTaskService.getAssignedTasksForProject(projectId);
                        this._outAssignedTaskStream.next(new FetchTasksForProjectOutEvent(projectId, assignedTasks));
                        break;
                    }
                    case AssignedTaskEventTypesEnum.DELETE_TASK: {
                        const {projectId, assignedTaskId} = (event: DeleteAssignedTaskForProjectEvent);
                        await this._assignedTaskService.deleteAssignedTask(projectId, assignedTaskId);
                        this._outAssignedTaskStream.next(new DeleteTasksForProjectOutEvent(projectId, [assignedTaskId]));
                        break;
                    }
                    default: {
                        throw new Error("Unknown assignedTask event: " + event.type);
                    }
                }
            },
            error(err) {
                throw err
            },
        };
    };

    dispose() {
        this._outAssignedTaskStream.complete();
        this._eventController.complete();
    }
}


export const AssignedTaskOutEventTypesEnum = Object.freeze({
    FETCHED: Symbol("FETCH"),
    ADDED: Symbol("ADDED"),
    UPDATED: Symbol("UPDATED"),
    DELETED: Symbol("DELETED"),
});

export type AssignedTaskOutEventTypes = $Keys<typeof AssignedTaskOutEventTypesEnum>;

export class AssignedTaskOutEvent {
    type: AssignedTaskOutEventTypes;

    constructor(type: AssignedTaskOutEventTypes) {
        this.type = type;
    }
}

export class FetchTasksForProjectOutEvent extends AssignedTaskOutEvent {

    projectId: string;
    assignedTasks: Array<AssignedTask>;

    constructor(projectId: string, assignedTasks: Array<AssignedTask>) {
        super(AssignedTaskOutEventTypesEnum.FETCHED);
        this.assignedTasks = assignedTasks;
        this.projectId = projectId;
    }
}

export class AddTasksForProjectOutEvent extends AssignedTaskOutEvent {

    projectId: string;
    newTasks: Array<AssignedTask>;

    constructor(projectId: string, newTasks: Array<AssignedTask>) {
        super(AssignedTaskOutEventTypesEnum.ADDED);
        this.newTasks = newTasks;
        this.projectId = projectId;
    }
}

export class UpdateTasksForProjectOutEvent extends AssignedTaskOutEvent {

    projectId: string;
    updatedTasks: Array<AssignedTask>;

    constructor(projectId: string, updatedTasks: Array<AssignedTask>) {
        super(AssignedTaskOutEventTypesEnum.UPDATED);
        this.updatedTasks = updatedTasks;
        this.projectId = projectId;
    }
}


export class DeleteTasksForProjectOutEvent extends AssignedTaskOutEvent {

    projectId: string;
    deletedTasksIds: Array<string>;

    constructor(projectId: string, deletedTasksIds: Array<string>) {
        super(AssignedTaskOutEventTypesEnum.DELETED);
        this.deletedTasksIds = deletedTasksIds;
        this.projectId = projectId;
    }
}

const sortAssignedTasks = (assignedTaskA, assignedTaskB) => {
    return assignedTaskA.title.toLowerCase().localeCompare(assignedTaskB.title.toLowerCase());
};
