// @flow

import React, { useState,useEffect,useContext } from "react";
import styles from "./ManageTasksView.module.css";
import { Column, Table, TableLoadingOption, Cell } from "@blueprintjs/table";
import Task from "../../models/Task/Task";
import Project, { ProjectStates } from "../../models/Project/Project";
import TaskTable from "../../components/TaskTable/TaskTable";
import type TaskGroup from "../../models/TaskGroup/TaskGroup";
import DeleteConfirmationPopup from "../../components/ConfirmationAlert/ConfirmationAlert";
import Flexbox from "flexbox-react";
import {
  Button,
  Intent,
} from "@blueprintjs/core";
import ProjectGroup from "../../models/ProjectGroup/ProjectGroup";
import { apiAxios } from "../../config/AxiosConfig";
import BlocRegistry from "../../blocs/registry";

type Props = {
 // tasks: ?Array<Task>,
  project: ?Array<Project>,
  projectGroups: ?Array<ProjectGroup>,
  //taskGroups: ?Array<TaskGroup>,
  onAddTaskButtonClick: () => any,
  onTaskRemoveFromList: () => any,
  onGetTaskList: () => any
};

export default function ManageTasksView(props: Props) {
  const {   onTaskRemoveFromList, projectGroups,tasks,taskGroups,getTaskListName } = props;

  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [showNextbtnPopup, setNextbtnPopup ] = useState(false);

  const [taskId, setTaskId] = useState(null);
 

let addTaskDisable = tasks!=null?tasks[0]?.is_master:false

  let list = (
    <Table
      numRows={10}
      loadingOptions={[
        TableLoadingOption.CELLS,
        TableLoadingOption.ROW_HEADERS,
       
      ]}
    >
      <Column name="Title" />
      <Column name="Description" />
      <Column name="Unit" />
      <Column name="Price" />
    </Table>
  );
  if ((tasks && taskGroups)  ) {
    if (tasks.length === 0) {
      list = (
        <div
          className={`bp3-ui-text bp3-text-disabled ${styles.noProjectsText}`}
        >
          no tasks
        </div>
      );
    } else {
      list = (
        <TaskTable
          tasks={tasks}
          taskGroups={taskGroups}
          projectGroups={projectGroups}
          getTaskListName={getTaskListName}
          onListChange ={props.getLatestTaskList}
          currentUserRole = {props.currentUserRole}
          // onTaskRemoveFromList={onTaskRemoveFromList}
          loader={props.loader}
          setNextbtnPopup={setNextbtnPopup}
          showNextbtnPopup={showNextbtnPopup}
         // getTaskListName={getTaskListName}
          latestTaskListId={props.latestTaskListId}
          refeshTaskList={props.refeshTaskList}
          onTaskRemoveFromList={async taskId => {
            await setConfirmationPopup(true);
            await setTaskId(taskId);
          }}
        />
      );
    }
  }

  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      icon="trash"
      type={Intent.DANGER}
      onCancel={() => setConfirmationPopup(false)}
      onConfirm={async () => {
        await onTaskRemoveFromList(taskId);
        await setConfirmationPopup(false);
        setTimeout(() => {
          // onProjectDeleted(Intent.PRIMARY, "Task Deleted");
        }, 500);
      }}
      message="Are you sure you want to delete Task?"
    />
  );

  return (
    <div className={styles.manageTasksView} id="mainHeader">
      <h5 >Tasks & Pricing</h5>
      <Flexbox justifyContent={"flex-start"}>
        <Flexbox flexDirection={"row"}>
          <Button
            text={"Add Task"}
            icon={"plus"}
            disabled={addTaskDisable || showNextbtnPopup}
            loading={!taskGroups || props.loader }
            onClick={props.onAddTaskButtonClick}
            minimal
          />
         
        </Flexbox>

      </Flexbox>
      {list}
      {confirmPopup}
    </div>
  );
}
