//@flow

import React, {useState} from "react";
import {Button, Card, Divider, InputGroup, Intent, Overlay, Menu, Classes, MenuItem, Icon} from "@blueprintjs/core";
import {ErrorMessage, Field, Form, Formik, useField} from "formik";
import styles from "../CreateProjectRequestBuilder/CreateProjectRequestBuilder.module.css";
import {Cell, Column, Table} from "@blueprintjs/table";
import Flexbox from 'flexbox-react'
import * as Yup from "yup";
import TextSelect from "../TextSelect/TextSelect";

type Props = {
    inputName: string,
    disabled? :boolean,
    onAnnualIncomeChanged:() => any,
    onDeteleIncome:() => any,
}

export default function HouseholdMembersList(props: Props) {

    const{disabled,onAnnualIncomeChanged,onDeteleIncome} = props;

    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [frequencyError, setFrequencyError] = useState(false);
    const [selectedFrequency, setSelectedFrequency] = useState(null);
    const [itemField, itemMeta] = useField(props.inputName);
    const valueChangeHandler = itemField.onChange;
    const error = itemMeta.error && itemMeta.touched ?
        <div className={styles.errorText}>{itemMeta.error}</div> : <div/>;

    const values = itemField.value && Array.isArray(itemField.value) ? itemField.value : [];
    if(values){
        let totalIncome = 0;
        values.map((data,index) => {
            switch (data.payment_frequency) {
                case "Annually":
                    totalIncome = totalIncome + Number(data.monthly_income) * 1;
                   break; 
                
                case "Monthly":
                    totalIncome = totalIncome + Number(data.monthly_income) * 12;
                   break;
    
                case "Twice a Month":
                    totalIncome = totalIncome + Number(data.monthly_income) * 24;
                   break;    
                   
                case "Bi-Weekly":
                    totalIncome = totalIncome + Number(data.monthly_income) * 26;
                   break;
                   
                case "Weekly":
                    totalIncome = totalIncome + Number(data.monthly_income) * 52;
                   break;
                case "Hourly":
                    totalIncome = totalIncome + (Number(data.monthly_income) * data.hours_per_week) * 52;
                   break;            
                default:
                    return ''
                    
            }
        })

        onAnnualIncomeChanged(totalIncome);
    }

    const [activeIndex, setActiveIndex] = useState(values.length);

    const createInput = (inputName: string, inputLabel: string, options?: { fill?: boolean, type?: string }) => {

        let type = 'text';
        if (options && options.type) type = options.type;


        let fill = true;
        if (options && (options.fill === true || options.fill === false)) fill = options.fill;

        return (
            <Flexbox flex={1} flexDirection={'column'} width={'100%'} padding={'10px'} marginTop={'10px'}>
                <Flexbox flex={1}>
                    <label htmlFor={inputName}>{inputLabel}</label>
                </Flexbox>
                <Flexbox flex={1}>
                    <Field className={`bp3-large`} as={InputGroup} name={inputName} type={type} fill={fill}/>
                </Flexbox>
                <Flexbox className={styles.errorText} flex={1}>
                    <ErrorMessage name={inputName}/>
                </Flexbox>
            </Flexbox>
        )
    };

    const createSelectInput = (inputName: string, inputLabel: string, select) => {
        return (
          <Flexbox
            flex={1}
            flexDirection={"column"}
            width={"100%"}
            padding={"10px"}
            marginTop={"10px"}
          >
            <Flexbox flex={1}>
              <label htmlFor={inputName}>{inputLabel}</label>
            </Flexbox>
            <Flexbox flex={1}>{select}</Flexbox>
            <Flexbox className={styles.errorText} flex={1}>
             {frequencyError? <div style={{color: "#fe671f"}}>Required</div>: null}
            </Flexbox>
          </Flexbox>
        );
      };

    const calculateAnnualIncome = (inputValues) =>{

        let annualIncome;

        switch (inputValues.payment_frequency) {
            case "Annually":
                annualIncome = Number(inputValues.monthly_income) * 1;
                return annualIncome.toLocaleString();
            
            case "Monthly":
                annualIncome = Number(inputValues.monthly_income) * 12;
                return annualIncome.toLocaleString();

            case "Twice a Month":
                annualIncome = Number(inputValues.monthly_income) * 24;
                return annualIncome.toLocaleString();    
               
            case "Bi-Weekly":
                annualIncome = Number(inputValues.monthly_income) * 26;
                return annualIncome.toLocaleString();
               
            case "Weekly":
                annualIncome = Number(inputValues.monthly_income) * 52;
                return annualIncome.toLocaleString();
            case "Hourly":
                annualIncome = (Number(inputValues.monthly_income) * Number(inputValues.hours_per_week)) * 52;
                return annualIncome.toLocaleString(); 
            default:
                return ''
                
        }

    } 
    
    const deleteIncomeSource = (id:string) => {

        const newValues = Array.from(values);
                 let filteredArray =  newValues.filter((data,index) => {
                        return index !== id
                    })

                    const event = {
                        target: {
                            name: itemField.name,
                            value: filteredArray,
                        }
                    };
                    setActiveIndex(newValues.length-1);
                    valueChangeHandler(event);
                    onDeteleIncome()

       
    }

    const lastNameCellRenderer = (rowIndex: number) => {
        return values[rowIndex] ? <Cell>{values[rowIndex].last_name}</Cell> : <Cell/>
    };

    const firstNameCellRenderer = (rowIndex: number) => {
        return  values[rowIndex] ? <Cell>{values[rowIndex].first_name}</Cell> : <Cell/>
    };

    const ageCellRenderer = (rowIndex: number) => {
        return  values[rowIndex] ? <Cell>{values[rowIndex].age}</Cell>: <Cell/>
    };

    const paymentFrequencyRenderer = (rowIndex: number) => {
        return  values[rowIndex] ? <Cell>{values[rowIndex].payment_frequency}</Cell>: <Cell/>
    };

    const monthlyIncomeCellRenderer = (rowIndex: number) => {
        return  values[rowIndex] ? <Cell>{`$ ${(values[rowIndex].monthly_income).toLocaleString()}`}</Cell>: <Cell/>
    };

    const annualIncomeCellRenderer = (rowIndex: number) => {
        return  values[rowIndex] ? <Cell>{`$ ${calculateAnnualIncome(values[rowIndex])}`}</Cell>: <Cell/>
    };

    const sourceOfIncomeCellRenderer = (rowIndex: number) => {
        return  values[rowIndex] ? <Cell>{values[rowIndex].income_source}</Cell>: <Cell/>
    };

    const controlsRenderer = (rowIndex: number) => {
        return (
            values[rowIndex] ? <Cell>
          <Button
              style={{marginTop:'4%'}}
              intent={Intent.DANGER}
            //   text={"Delete"}
              icon={"trash"}
              onClick={() => {
                deleteIncomeSource(rowIndex)
              }}     
            /></Cell>: <Cell/>
        
        );
      };

      const onChangeSelect = (item) => {
        setSelectedFrequency(item)
        setFrequencyError(false)
      }

    return (<Flexbox flexDirection={'column'} justifyItems={'flex-end'}>
        <Flexbox flex={1}>
            <Flexbox flex={1}>
                <Button minimal disabled={disabled} text={"Add Income Source"} icon={'plus'} onClick={() => {
                    values.push({last_name: '', first_name: '', age: '', monthly_income: '', income_source: '', hours_per_week: ''});
                    const event = {
                        target: {
                            name: itemField.name,
                            value: values,
                        }
                    };
                    setActiveIndex(values.length-1);
                    valueChangeHandler(event);
                    setIsOverlayOpen(true)
                }}/>
                {/* {values.length > 0 && <Button minimal text={"Remove Last Income Source"} icon={'minus'} disabled={disabled} onClick={() => {
                    const newValues = Array.from(values);
                    newValues.pop();
                    const event = {
                        target: {
                            name: itemField.name,
                            value: newValues,
                        }
                    };
                    setActiveIndex(newValues.length-1);
                    valueChangeHandler(event);
                }}/>} */}
            </Flexbox>
            <Flexbox flex={1}/>
        </Flexbox>
        <Overlay isOpen={isOverlayOpen} onClose={() => {
            setIsOverlayOpen(false)
        }}>
            <Flexbox height={'100%'} width={'100%'} justifyContent={'center'} alignItems={'center'}
                     justifyItems={'center'}>
                <Card>
                    {itemField.value && itemField.value[activeIndex] &&
                    <Formik
                        key={'HouseholdMemberForm'}
                        initialValues={itemField.value[activeIndex]}
                        validationSchema={Yup.object({
                            first_name: Yup.string()
                                .required('Required'),
                            last_name: Yup.string()
                                .required('Required'),
                            last_name: Yup.string()
                                .required('Required'),
                            age: Yup.string()
                                .required('Required'),
                            monthly_income: Yup.string()
                            .required('Required'),
                            income_source: Yup.string()
                            .required('Required'),
                            hours_per_week: selectedFrequency == "Hourly" ? Yup.string()
                            .required('Required') : Yup.string()
                            .notRequired()
                        })}
                        onSubmit={async (newValues) => {
                            if(!newValues.payment_frequency) {return setFrequencyError(true)}
                            if (activeIndex === values.length) values.push(newValues);
                            else values[activeIndex] = newValues;
                            const event = {
                                target: {
                                    name: itemField.name,
                                    value: values,
                                }
                            };
                            valueChangeHandler(event);
                            setActiveIndex(values.length);
                            setIsOverlayOpen(false);
                        }}>
                        {Formik => {
                            return (<Form>
                                <Flexbox alignItems={'center'} flexDirection={'column'} className={styles.inputSection}>
                                    <Card elevation={1} className={styles.card}>
                                        <Flexbox flex={1} alignItems={'left'} width={'100%'}>
                                            <h3>Add Income Source</h3>
                                        </Flexbox>
                                        <Flexbox flex={1} width={'100%'}>
                                            <Divider className={styles.fullWidthInput}/>
                                        </Flexbox>

                                        {createInput('last_name', 'Last Name')}
                                        {createInput('first_name', 'First Name')}
                                        {createInput('age', 'Age')}
                                        {createSelectInput(
                                                    "payment_frequency",
                                                    "Frequency",
                                                    <TextSelect
                                                        items={["Annually","Monthly","Twice a Month","Bi-Weekly","Weekly","Hourly"]}
                                                        inputName={"payment_frequency"}
                                                        onChange={onChangeSelect}
                                                    />
                                        )}
                                        {createInput('monthly_income', Formik.values.payment_frequency === "Hourly" ? 'Income per hour' : 'Income',{type:'number'})}
                                        {Formik.values.payment_frequency === "Hourly" ? createInput('hours_per_week', 'Hours per week', {type:'number'}): null}
                                        {/* {createInput('annual_income', 'Annual Income')} */}
                                        {createInput('income_source', 'Income Source')}

                                        <Flexbox justifyContent={'flex-end'}>
                                        <Flexbox flex={1} marginTop={'15px'} justifyContent={'flex-end'}>
                                        <Button
                                                text={'Cancel'}
                                                icon={'cross'}
                                                onClick={() => {
                                                    
                                                    const newValues = Array.from(values);
                                                    newValues.pop();
                                                    const event = {
                                                        target: {
                                                            name: itemField.name,
                                                            value: newValues,
                                                        }
                                                    };
                                                    setActiveIndex(newValues.length-1);
                                                    valueChangeHandler(event);

                                                    setIsOverlayOpen(false);
                                                }}
                                                style={{marginRight:'2%'}}
                                                />
                                            <Button
                                                type="submit"
                                                text={'Add'}
                                                icon={'plus'}
                                                intent={Intent.PRIMARY}
                                                className={styles.saveChangesButton}
                                                />
                                        </Flexbox>
                                        </Flexbox>
                                    </Card>
                                </Flexbox>
                            </Form>)
                        }}
                    </Formik>}
                </Card>
            </Flexbox>
        </Overlay>
        <Table
            enableColumnResizing={true}
            enableRowResizing={false}
            defaultRowHeight={40}
            defaultColumnWidth={100}
            numRows={values.length}>
            <Column name={"Last Name"} cellRenderer={lastNameCellRenderer}/>
            <Column name={"First Name"} cellRenderer={firstNameCellRenderer}/>
            <Column name={"Age"} cellRenderer={ageCellRenderer}/>
            <Column name={"Frequency"} cellRenderer={paymentFrequencyRenderer}/>
            <Column name={"Income"} cellRenderer={monthlyIncomeCellRenderer}/>
            <Column name={"Annual Income"} cellRenderer={annualIncomeCellRenderer}/>
            <Column name={"Source of Income"} cellRenderer={sourceOfIncomeCellRenderer}/>
            <Column name={"Controls"} cellRenderer={controlsRenderer}/>    
        </Table>
    </Flexbox>)
}
