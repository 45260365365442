//@flow

import styles from './VendorDisplay.module.css';
import React from "react";
import Flexbox from "flexbox-react";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Vendor from "../../models/Vendor/Vendor";

type Props = {
    vendor: Vendor,
}

export default function VendorDisplay(props: Props) {

    const {vendor} = props;

    const address = vendor.address;

    let addressText = `${address.streetAddress}`;
    if (address.streetAddressTwo) addressText = `${addressText}, ${address.streetAddressTwo}`;

    let addressTextLineTwo = `${address.city}, ${address.state} ${address.zip}`;

    return (
        <Flexbox flexDirection={'column'} className={styles.addressTextContainer}>
            <Flexbox flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <h3 className={styles.addressText}>{`${vendor.contactType}`}</h3>
            </Flexbox>
            <Flexbox flexDirection={'column'}>
                <a className={styles.addressLink} onClick={()=>{window.open(`https://www.google.com/maps/search/?api=1&query=${addressText+', '+addressTextLineTwo}`)}}>
                    <h3 className={styles.addressText}>{addressText}</h3>
                    {addressTextLineTwo && <h3 className={styles.addressText}>{addressTextLineTwo}</h3>}
                </a>
            </Flexbox>
            <Flexbox className={'bp3-text-muted'}>
                <h3 className={styles.addressText}>{`${vendor.name}`}</h3>
            </Flexbox>
            <Flexbox className={'bp3-text-muted'} alignItems={'center'}>
                <FontAwesomeIcon icon={faPhone} color="#385988" className={styles.icon}/>
                <a className={styles.addressText} href={`tel:${vendor.phoneNumber}`}>{vendor.phoneNumber}</a>
            </Flexbox>
            {vendor.email &&
            <Flexbox className={'bp3-text-muted'} alignItems={'center'}>
                <FontAwesomeIcon icon={faEnvelope} color="#385988" className={styles.icon}/>
                <a className={styles.addressText} href={`mailto:${vendor.email}`}>{vendor.email}</a>
            </Flexbox>}
        </Flexbox>
    )
}
