// @flow

import React, { useState } from "react";
import { Intent, Menu, MenuItem, Classes } from "@blueprintjs/core";
import { Table, SelectionModes } from "@blueprintjs/table";
import Vendor from "../../models/Vendor/Vendor";
import { TextSortableColumn } from "../../components/Table/TextSortableColumn/TextSortableColumn";
import { Utils } from "@blueprintjs/table/lib/esnext/common/utils";
import history from "../../history";
import Flexbox from "flexbox-react";
import { Link } from "react-router-dom";

type Props = {
  vendors: Array<Vendor>,
  onVendorRemoveFromList: (vendorId: string) => any,
  onCertificateDownload: (request: Vendor) => any,
};

export default function VendorGroupsTable(props: Props) {
  const { vendors } = props;

  const [columns, setColumns] = useState([
    new TextSortableColumn("Controls", 0, "controls", () => {
      return 0;
    }),
    new TextSortableColumn("Name", 1, "name"),
    new TextSortableColumn("Address", 2, "address"),
    new TextSortableColumn("Email", 3, "email"),
    new TextSortableColumn("Phone", 4, "phoneNumber"),
    new TextSortableColumn("Contact Type", 5, "contactType"),
    new TextSortableColumn("Projects", 5, "projects")
  ]);

  const [sortedIndexMap, setSortedIndexMap] = useState([]);

  const onEditVendor = (vendorId: string) => {
    history.push(`/app/update-vendor/${vendorId}`);
  };

  const nameRenderer = (rowIndex: number) => {
    return vendors[rowIndex].name;
  };

  const addressRenderer = (rowIndex: number) => {
    const address = vendors[rowIndex].address;
    return `${address.streetAddress}${
      address.streetAddressTwo ? `, ${address.streetAddressTwo}` : ""
    }\n${address.city}, ${address.state}\n${address.zip}`;
  };

  const emailRenderer = (rowIndex: number) => {
    return vendors[rowIndex].email;
  };

  const phoneRenderer = (rowIndex: number) => {
    return vendors[rowIndex].phoneNumber;
  };

  const contactRenderer = (rowIndex: number) => {
    return vendors[rowIndex].contactType;
  };

  const projectRenderer = (rowIndex: number) => {
    if(props.associatedProjects[vendors[rowIndex].id]){
      let homeOwnerNames = props.associatedProjects[vendors[rowIndex].id].projects.map(project=>{
          let homeowner = project.home.homeowner;
        let name = `${homeowner.firstName} ${homeowner.lastName}`
        let id = project.id;
        return {name, id};
      })
      return <>
      {homeOwnerNames.map(homeOwner=>{
        return <div style={{cursor: "pointer"}} onClick={() => props.navigateToViewProject(homeOwner.id)}>{homeOwner.name}</div>
      })}
      </>
    }
    return "No Assigned Project";
  };

  const controlsRenderer = (rowIndex: number) => {
    return (
      <>
        <Menu className={Classes.ELEVATION_2}>
          <MenuItem
            text="Edit"
            icon={"edit"}
            intent={Intent.PRIMARY}
            onClick={() => onEditVendor(vendors[rowIndex].id)}
          />
          <MenuItem
            text="Download"
            icon={"download"}
            intent={Intent.SUCCESS}
            onClick={() => props.onCertificateDownload(vendors[rowIndex])}
          />
          <MenuItem
            text="Delete"
            icon={"trash"}
            intent={Intent.DANGER}
            onClick={() => props.onVendorRemoveFromList(vendors[rowIndex].id)}
          />
        </Menu>
      </>
    );
  };

  const cellRenderers = {
    name: nameRenderer,
    address: addressRenderer,
    email: emailRenderer,
    phoneNumber: phoneRenderer,
    contactType: contactRenderer,
    controls: controlsRenderer,
    projects: projectRenderer,
  };

  const getCellData = (rowIndex: number, columnIndex: number) => {
    const sortedRowIndex = sortedIndexMap[rowIndex];
    if (sortedRowIndex != null) {
      rowIndex = sortedRowIndex;
    }
    return cellRenderers[columns[columnIndex].modelField](rowIndex);
  };

  const sortColumn = (
    columnIndex: number,
    comparator: (a: any, b: any) => number
  ) => {
    const sortedIndexMap = Utils.times(vendors.length, (i: number) => i);
    sortedIndexMap.sort((a: number, b: number) => {
      let fieldA;
      let fieldB;

      switch (columns[columnIndex].modelField) {
        case "address": {
          fieldA = vendors[a].address.streetAddress;
          fieldB = vendors[b].address.streetAddress;
          break;
        }
        default: {
          fieldA = vendors[a][columns[columnIndex].modelField];
          fieldB = vendors[b][columns[columnIndex].modelField];
          break;
        }
      }

      return comparator(fieldA, fieldB, a, b);
    });
    setSortedIndexMap(sortedIndexMap);
  };

  const renderedColumns = columns.map((column) => {
    const field = column.modelField;
    return column.getColumn(getCellData, sortColumn);
  });

  return (
    <>
      <Table
        defaultRowHeight={110}
        selectionModes={SelectionModes.COLUMNS_AND_CELLS}
        numRows={vendors.length}
      >
        {renderedColumns}
      </Table>
    </>
  );
}
