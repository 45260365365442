// @flow

import React, {useState} from 'react';
import TaskGroup, {CreateTaskGroupRequest} from "../../models/TaskGroup/TaskGroup";
import {Button, InputGroup, Intent, Popover} from "@blueprintjs/core";
import Flexbox from "flexbox-react";

type Props = {
    taskGroups: ?Array<TaskGroup>, onNewTaskGroupCreate: (taskGroupToCreate: CreateTaskGroupRequest)=>any,
}

export default function CreateTaskGroupPopoverButton(props: Props) {

    const {taskGroups,latestTaskListId} = props;

    const [createGroupPopoverOpen, setCreateGroupPopoverOpen] = useState(false);
    const [newGroupName, setNewGroupName] = useState(null);

    let taskGroupTitles = [];
    let taskGroupRequest ={
        title: newGroupName,
        task_list_name_id : latestTaskListId
    }
    if (taskGroups) taskGroupTitles = taskGroups.map(taskGroup => taskGroup.title.toLowerCase());

    return (

            <Popover isOpen={createGroupPopoverOpen} onClose={() => {
                setCreateGroupPopoverOpen(false)
            }}>
                <Button icon={'add'} text={"Add New Group"} minimal onClick={() => {
                    setCreateGroupPopoverOpen(true)
                }}/>
                <div>
                    <InputGroup placeholder={"Group Name"} value={newGroupName} onChange={(event) => {
                        setNewGroupName(event.target.value);
                    }}/>
                    <Flexbox justifyContent={'center'}>
                        <Flexbox margin={'5px'}>
                            <Button icon={'cross'} text={"Cancel"} minimal
                                    onClick={() => {
                                        setCreateGroupPopoverOpen(false)
                                    }}
                                    intent={Intent.DANGER}/>
                        </Flexbox>
                        <Flexbox margin={'5px'}>
                            <Button icon={'confirm'} text={"Confirm"} minimal
                                    disabled={!newGroupName || taskGroupTitles.includes(newGroupName.toLowerCase())}
                                    onClick={() => {
                                        props.onNewTaskGroupCreate(new CreateTaskGroupRequest(newGroupName,latestTaskListId));
                                        setCreateGroupPopoverOpen(false)
                                    }}
                                    intent={Intent.PRIMARY}/>
                        </Flexbox>
                    </Flexbox>
                </div>
            </Popover>

    )
}

